import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { Select } from "antd";

export default function SchoolStatus(props: any) {
    const selectItems: any = [
        {
            value: 'complete',
            label: (
                <a>
                    <CheckCircleFilled />
                    <span>Complete</span>
                </a>
            ),
        },
        {
            value: 'inComplete',
            label: (
                <a>
                    <MinusCircleFilled />
                    <span>Incomplete</span>
                </a>
            ),
        },

    ];
    return (
        <>

            {
                props.option === "SCHOOL-Grades" ? <Select
                    defaultValue="inComplete"
                    value={props.item.defaultAnswer && props.item.defaultAnswer.length > 0 ? "complete" : "inComplete"}
                    options={selectItems}
                    className="approval-select"
                    open={false}
                    showArrow={false}
                /> : <Select
                    defaultValue="inComplete"
                    value={props.item.answer && props.item.answer.length > 0 ? "complete" : "inComplete"}
                    options={selectItems}
                    className="approval-select"
                    open={false}
                    showArrow={false}
                />
            }

        </>
    )
}
