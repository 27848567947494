import { useEffect, useState } from "react";
import { Table, Button, Input, Menu, Dropdown, Collapse, Checkbox, notification, Spin, message, Space } from "antd";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import EndpointService from "../../utilities/constants/endpointService";
import HTTPService from "../../service/HTTPService";
import { cypherService } from "../../utilities/cypher/cypherService";
import { checkNullToNumber, checkNullToString, checkTableNull, columnsFilterValues, handleStatus } from "./helper";
import { baseUrl } from "../../environments/environment";
import LeadershipIcon from "../../assets/images/icons/LeadershipIcon";
import CurriculumIcon from "../../assets/images/icons/CurriculumIcon";
import PlanningIcon from "../../assets/images/icons/PlanningIcon";
import PersonalIcon from "../../assets/images/icons/PersonalIcon";
import AssessmentIcon from "../../assets/images/icons/AssessmentIcon";
const { Panel } = Collapse;

function  ViewSchool() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [schoolList, setSchoolList] = useState<any>([]);
  const [start, setStart] = useState<number>(0);
  const [limit] = useState<number>(20);
  const [searchText, setSearchText] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>([]);
  const [filterQuestionaireOptionId, setFilterQuestionaireOptionId] = useState<any>([]);
  const [otherFilterText, setOtherFilterText] = useState<any>([]);
  const [tableFilters, setTableFilters] = useState<any>([])
  const [schoolFilterList, setSchoolFilterList] = useState<any>([])
  const [schoolTableList, setSchoolTableList] = useState([])


  useEffect(() => {
    cypherService.setSessionStorage("editObject", null);
    cypherService.setSessionStorage("isEdit", false);
    getSchoolData(start, limit, searchText, filterQuestionaireOptionId, otherFilterText);
    tableFilter()
  }, []);


  const tableFilter = () => {
    const mapUrl = EndpointService.filterMasterRecord;
    HTTPService.get(mapUrl, {})
      .then((response) => {
        setTableFilters(response.data)
      }).catch((err) => {
        setLoader(false);
        notification.error({
          message: "",
          // description: err.response.data.status.message,
          placement: "top",
        });
      });
  }


  const getSchoolData = (startValue: number, limitValue: number, searchTextData: string, filterId: any, otherText: any) => {
    setLoader(true);
    let urlParam: string = `?start=${startValue}&limit=${limitValue}`;
    if (searchTextData) {
      urlParam = urlParam + `&search=${searchTextData}`;
    }
    if (filterId.length > 0) {
      let filterIdData: any = "";
      filterId.forEach((item: any) => {
        filterIdData = filterIdData == "" ? filterIdData + item : filterIdData + "," + item;
      });
      urlParam = urlParam + `&questionaireOptionId=${filterIdData}`;
    }
    if (otherText.length > 0) {
      let otherData: any = "";
      otherText.forEach((item: any) => {
        otherData = otherData == "" ? otherData + item : otherData + "," + item;
      });
      urlParam = urlParam + `&otherText=${otherData}`;
    }
    const mapUrl = EndpointService.getSchoolData + urlParam;
    HTTPService.get(mapUrl, {})
      .then((response) => {
        const Res = response;
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          setLoader(false);
          setTotalData(Res.totalResults);
          let temoSchoolDataList: any = [];
          Res.data.forEach((element: any) => {
            let tempSchoolData: any = {};
            tempSchoolData["date"] = checkNullToString(element.registrationDate);
            tempSchoolData["schoolName"] = checkNullToString(element.schoolName);
            tempSchoolData["role"] = checkNullToString(element.role);
            tempSchoolData["emirate"] = checkNullToString(element.emirate);
            tempSchoolData["type"] = checkNullToString(element.type);
            tempSchoolData["leadershipManagement"] = checkNullToNumber(element.leadershipManagement);
            tempSchoolData["assessment"] = checkNullToNumber(element.assessment);
            tempSchoolData["curriculum"] = checkNullToNumber(element.curriculum);
            tempSchoolData["personalAndSocialDevelopment"] = checkNullToNumber(element.personalAndSocialDevelopment);
            tempSchoolData["planningAndTeaching"] = checkNullToNumber(element.planningAndTeaching);
            tempSchoolData["matched"] = checkNullToNumber(element.matched);
            tempSchoolData["recommended"] = checkNullToNumber(element.recommended);
            tempSchoolData["status"] = checkNullToString(element.status);
            tempSchoolData["customerId"] = checkNullToString(element.customerId);
            temoSchoolDataList.push(tempSchoolData);
          });

          setSchoolList(temoSchoolDataList);
          setSchoolTableList(temoSchoolDataList);
          setSchoolFilterList(temoSchoolDataList)
          // message.success(response.status.message)
        }
        if (getStatus === "fail") {
          setLoader(false);
          notification.error({
            message: "",
            description: Res.data.status.message,
            placement: "top",
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        notification.error({
          message: "",
          // description: err.response.data.status.message,
          placement: "top",
        });
      });
  };


  const locationSplit = (el: any) => {
    let splitArray = el && el.split(",")
    let arrayList: any = []
    if (typeof (splitArray) === "object") {
      splitArray && splitArray.forEach((list: any, index: number) => {
        let obj = {
          label: list,
          key: index,
        }
        arrayList.push(obj)
      })

    }


    if (arrayList.length > 1) {
      return <Dropdown className='tbl-location' menu={{ items: arrayList }} trigger={['click']}>
        <a onClick={e => e.preventDefault()}>
          <Space> Multi Location</Space>
        </a>
      </Dropdown>
    } else {
      return <span className='tbl-location'>{el}</span>
    }
  }




  const sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
  const columns: any = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (element: any) => <>{checkTableNull(element)}</>,
      width: 220,
      sorter: (a: any, b: any) => sorter(a.date, b.date),
    },
    {
      title: "School/ Organization",
      dataIndex: "schoolName",
      key: "schoolName",
      width: 270,
      render: (element: any, record: any) => <span className="hoverElement" onClick={() => { handleRedirect("isEdit", record) }}>{checkTableNull(element)}</span>,
      // sorter: (a: any, b: any) => a.schoolName.localeCompare(b.schoolName)
      sorter: (a: any, b: any) => sorter(a.schoolName, b.schoolName),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 270,
      render: (element: any) => <>{checkTableNull(element)}</>,
      filters: [...columnsFilterValues(tableFilters.roles)],
      onFilter: (value: string, record: any) => record.role.indexOf(value) === 0,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 120,
      render: (element: any) => <>{checkTableNull(element)}</>,
      sorter: (a: any, b: any) => sorter(a.type, b.type),
    },
    {
      title: <LeadershipIcon />,
      dataIndex: "leadershipManagement",
      key: "leadershipManagement",
      width: 90,
      render: (element: any) => <>{checkTableNull(element)}</>,
      sorter: (a: any, b: any) => sorter(a.leadershipManagement, b.leadershipManagement),
    },
    {
      title: <AssessmentIcon />,
      dataIndex: "assessment",
      key: "assessment",
      width: 90,
      render: (element: any) => <>{checkTableNull(element)}</>,
      sorter: (a: any, b: any) => sorter(a.assessment, b.assessment),
    },
    {
      title: <CurriculumIcon />,
      dataIndex: "curriculum",
      key: "curriculum",
      width: 90,
      render: (element: any) => <>{checkTableNull(element)}</>,
      sorter: (a: any, b: any) => sorter(a.curriculum, b.curriculum),
    },

    {
      title: <PersonalIcon />,
      width: 90,
      dataIndex: "personalAndSocialDevelopment",
      key: "personalAndSocialDevelopment",
      onFilter: (value: any, record: any) => record.type.indexOf(value) === 0,
      render: (element: any) => <>{checkTableNull(element)}</>,
      sorter: (a: any, b: any) => sorter(a.personalAndSocialDevelopment, b.personalAndSocialDevelopment),

    },
    {
      title: <PlanningIcon />,
      width: 90,
      dataIndex: "planningAndTeaching",
      key: "planningAndTeaching",
      render: (element: any) => <>{checkTableNull(element)}</>,
      sorter: (a: any, b: any) => sorter(a.planningAndTeaching, b.planningAndTeaching),
    },
    {
      title: "Matched",
      dataIndex: "matched",
      key: "matched",
      width: 130,
      render: (element: any) => <>{checkTableNull(element)}</>,
      sorter: (a: any, b: any) => sorter(a.matched, b.matched),
    },
    {
      title: "Recommended",
      dataIndex: "recommended",
      key: "recommended",
      width: 170,
      render: (element: any) => <>{checkTableNull(element)}</>,
      sorter: (a: any, b: any) => sorter(a.recommended, b.recommended),
    },
    {
      title: 'Location',
      dataIndex: 'emirate',
      key: 'emirate',
      ellipsis: true,
      filters: [...columnsFilterValues(tableFilters.locations)],
      onFilter: (value: string, record: any) => record.emirate.indexOf(value) === 0,
      render: (e: any) => (
        <>{locationSplit(e)} </>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // sorter: (a: any, b: any) => sorter(a.status, b.status),
      filters: [
        {
          text: 'Approved',
          value: 'Approved',
        },
        {
          text: 'Pending Approval',
          value: 'Pending',
        },
      ],
      render: (element: any) => <div className="school-status">{handleStatus(element)}</div>,
      onFilter: (value: string, record: any) => record.status.indexOf(value) === 0,
    },
  ];

  // const pageChange = (page: any) => {
  //   
  //   let startValue: number = page * 10 - 10;
  //   setStart(startValue);
  //   getSchoolData(startValue, limit, searchText, filterQuestionaireOptionId, otherFilterText);
  // };

  const searchTextChange = (event: any) => {
    let searchValue = event.target.value.toLowerCase()
    setSearchText(searchValue);
    // getSchoolData(0, limit, event.target.value, filterQuestionaireOptionId, otherFilterText);
    if (searchText) {
      let result =
        schoolFilterList &&
        schoolFilterList.filter((item: any, index: number) => {

          let schoolName = item && item.schoolName.toLowerCase();
          let status = item && item.status.toLowerCase();
          let emirate = item && item.emirate.toLowerCase();
          let role = item && item.role.toLowerCase();

          if (schoolName.includes(searchValue) || status.includes(searchValue) || emirate.includes(searchValue) || role.includes(searchValue)) {
            return item;
          }
        });
      setSchoolTableList(result);
    } else {
      setSchoolTableList(schoolFilterList);
    }
  };

  const handleRedirect = (type: string, item: any = []) => {
    if (type === "isEdit") {
      let schoolDetailsObj = {
        customerId: item.customerId,
        signupStatus: item.status
      };

      cypherService.setLocalStorage("schoolDetailsObj", schoolDetailsObj);
    }
    cypherService.setLocalStorage("schoolAction", type);
    navigate("/schoolDetails");
  };

  const downloadRequest = () => {
    let url = baseUrl + EndpointService.downloadSchoolUrl;
    window.open(url, "_blank");
  };
  return (
    <div className="view-table school-list">
      {loader ? <Spin /> : null}
      <div className="view-table-sorting">
        <div className="main view-table-button">
          <Button type="primary" onClick={downloadRequest}>
            <DownloadOutlined /> Download
          </Button>
        </div>
        <div className="main view-table-button">
          <Button
            type="primary"
            onClick={() => {
              handleRedirect("add");
            }}
          >
            <PlusOutlined /> Add School
          </Button>
        </div>
        <div className="main view-table-search">
          <Input placeholder="Search" value={searchText} onChange={(event) => searchTextChange(event)} />
        </div>
        {/* <div className="main view-table-dropdown">
          <Dropdown className="collapse-filter-dropdown" overlay={dropdownMenu()} placement="bottomRight" trigger={["click"]}>
            <div>Filter By</div>
          </Dropdown>
        </div> */}
      </div>
      {
        columns && columns.length > 0 && <Table
          className="viewSchool-table schoolTable"
          columns={columns}
          dataSource={schoolTableList}
          scroll={{ x: "calc(70vh - 80,px)", y: "calc(70vh - 80,px)" }}
          pagination={{ total: totalData, pageSize: 20, pageSizeOptions: ["20"] }}
        />
      }

    </div>
  );
}

export default ViewSchool;

