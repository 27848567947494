import { CloseOutlined } from "@ant-design/icons";
import { notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import HTTPService from "../../../service/HTTPService";
import EndpointService from "../../../utilities/constants/endpointService";
import imagePlaceholder from './../../../assets/images/imagePlaceholder.svg'
export default function ImagePreview(props: any) {
  const { item, questionList, setQuestionList, index, editInit } = props;
  const [loader, setLoader] = useState<boolean>(false);
  useEffect(() => {
    handleImagePreview(props.item);
  }, [props]);

  let tempQuestionList = questionList;
  let defaultAnswerPayload = tempQuestionList[index].answer;

  const handleRemoveSingle = () => {
    tempQuestionList[index].answer = [];
    setQuestionList([...tempQuestionList]);
  };

  const handleRemoveOne = (elIndex: number) => {
    // tempQuestionList[index].answer = []
    // setQuestionList([...tempQuestionList])
    // let result =
    //   defaultAnswerPayload &&
    //   defaultAnswerPayload.filter((elItem: any, i: any) => {
    //     if (elIndex !== i) return elItem;
    //   });
    // tempQuestionList[index].answer = [...result];
    // console.log(tempQuestionList);
    // setQuestionList([...tempQuestionList]);

    let apiCall: boolean = true;
    let result =
      defaultAnswerPayload &&
      defaultAnswerPayload.filter((elItem: any, i: any) => {
        if (elIndex !== i) {
          return elItem;
        } else {
          if (elItem && elItem.create && elItem.create) {
            apiCall = false;
          } else {
            deleteSingleFile(elItem);
          }
        }
      });
    if (!apiCall) {
      tempQuestionList[index].answer = [...result];
      console.log(tempQuestionList);
      setQuestionList([...tempQuestionList]);
    }
  };

  const deleteSingleFile = (item: any) => {
    setLoader(true);
    let mapUrl: string = EndpointService.deleteMultiPartFileUrl + item.editQuestionId;

    HTTPService.delete(mapUrl)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          setLoader(false);
          editInit();
          notification.success({
            message: "",
            description: response.status.message,
            placement: "top",
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: "",
          description: error,
          placement: "top",
        });
      });
  };
  const checkLogo = (el: any) => {
    let isActive = el && el.split("/").includes("null");
    return isActive ? imagePlaceholder : el;
  };

  const handleImagePreview = (item: any) => {
    if (item.componentType === "UploadImages") {
      return (
        item.answer &&
        item.answer.map((el: any, elIndex: number) => {
          return (
            <div className="uploadedImage">
              <img src={checkLogo(el.filePath)} />
              <span onClick={() => handleRemoveOne(elIndex)}>
                <CloseOutlined />
              </span>
            </div>
          );
        })
      );
    } else {
      return (
        <div className="uploadedImage">
          <img src={checkLogo(item.answer.filePath)} />
          <span>
            <CloseOutlined onClick={() => handleRemoveSingle()} />
          </span>
        </div>
      );
    }
  };
  return (
    <>
      {loader ? <Spin /> : null}

      {handleImagePreview(props.item)}
    </>
  );
}
