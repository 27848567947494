import React from "react";

export default function ApprovedStatus(props: any) {
  return (
    <div className="absolute top-3 right-4">
      {props.approvedStatus ? (
        <div className="flex items-center ml-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.168" height="25.276" viewBox="0 0 19.168 25.276" className="mr-1">
            <g id="badge-_2_" transform="translate(-80.15 487.776)">
              <path
                id="Path_8450"
                data-name="Path 8450"
                d="M86.676-487.685a10.913,10.913,0,0,0-.867,1.4c-.4.7-.736,1.281-.758,1.281-.174,0-2.916.6-2.981.659-.191.158-.2.24-.016,1.766l.169,1.5-.207.234c-.114.125-.561.616-1,1.09a10.162,10.162,0,0,0-.845.992.391.391,0,0,0,0,.272,10.162,10.162,0,0,0,.845.992c.436.474.883.965,1,1.09l.207.234-.169,1.5c-.18,1.526-.174,1.608.016,1.766.065.055,2.807.659,2.987.659.016,0,.36.578.752,1.281a10.913,10.913,0,0,0,.867,1.4.354.354,0,0,0,.278.087c.076-.016.73-.305,1.461-.638l1.324-.61,1.373.632c.758.349,1.417.632,1.461.632.2,0,.4-.278,1.079-1.488l.714-1.275,1.4-.283c.774-.153,1.461-.305,1.526-.332a.55.55,0,0,0,.207-.185c.082-.125.076-.234-.082-1.635l-.174-1.5.207-.234c.114-.125.561-.616,1-1.09a10.158,10.158,0,0,0,.845-.992.391.391,0,0,0,0-.272,10.158,10.158,0,0,0-.845-.992c-.436-.474-.883-.965-1-1.09l-.207-.234.174-1.5c.158-1.4.164-1.51.082-1.635a.551.551,0,0,0-.207-.185c-.065-.027-.752-.18-1.526-.332l-1.4-.283-.719-1.281c-.392-.7-.747-1.308-.79-1.352-.191-.191-.311-.158-1.728.5l-1.39.638-1.324-.61c-.73-.333-1.384-.621-1.461-.638A.354.354,0,0,0,86.676-487.685Zm4.011,3.03a6.143,6.143,0,0,1,5.052,4.905,9.576,9.576,0,0,1,0,2.235,6.219,6.219,0,0,1-4.889,4.889,9.581,9.581,0,0,1-2.235,0,6.157,6.157,0,0,1-4.267-3.161,6.022,6.022,0,0,1-.038-5.624A6.1,6.1,0,0,1,90.688-484.654Z"
                transform="translate(0 0)"
                fill="#fcb611"
              />
              <path
                id="Path_8451"
                data-name="Path 8451"
                d="M165.018-415.827a5.869,5.869,0,0,0-2.213.877,6.564,6.564,0,0,0-1.482,1.6,4.615,4.615,0,0,0-.725,2.7,4.782,4.782,0,0,0,.774,2.78,6.333,6.333,0,0,0,1.57,1.6,4.976,4.976,0,0,0,2.883.828,4.735,4.735,0,0,0,2.671-.73,5.939,5.939,0,0,0,1.777-1.777,4.664,4.664,0,0,0,.725-2.7,4.672,4.672,0,0,0-.725-2.7,6.094,6.094,0,0,0-1.744-1.76A5.337,5.337,0,0,0,165.018-415.827Zm4.131,2.692c.267.262.213.381-.594,1.27-3.177,3.5-3.81,4.186-3.924,4.229a.512.512,0,0,1-.256.016,13.081,13.081,0,0,1-1.313-1.226c-1.03-1.03-1.188-1.21-1.188-1.357a.491.491,0,0,1,.452-.452c.142,0,.311.142,1.166.992.545.545,1,.981,1.014.965.109-.131,3.908-4.311,4.022-4.425A.413.413,0,0,1,169.149-413.135Z"
                transform="translate(-76.063 -67.977)"
                fill="#fcb611"
              />
              <path
                id="Path_8452"
                data-name="Path 8452"
                d="M113.875-190.859c-.087.234-1.875,6.447-1.875,6.524a.441.441,0,0,0,.4.382,11.865,11.865,0,0,0,1.51-.523c.73-.289,1.422-.556,1.537-.6l.2-.071,1.085,1.624c.6.894,1.128,1.64,1.177,1.662a.439.439,0,0,0,.48-.093,16.07,16.07,0,0,0,.774-3.047c.36-1.613.665-2.932.681-2.932s.322,1.319.681,2.932a16.453,16.453,0,0,0,.774,3.052.448.448,0,0,0,.48.087c.049-.022.583-.774,1.177-1.668a12.9,12.9,0,0,1,1.155-1.608c1.521.616,3.041,1.183,3.177,1.183a.447.447,0,0,0,.4-.376c0-.12-1.853-6.507-1.908-6.562a1.473,1.473,0,0,0-.758.147c-.022.033-.322.55-.659,1.15a12.681,12.681,0,0,1-.758,1.243,1.169,1.169,0,0,1-.932.387c-.332,0-.42-.027-1.548-.556-.659-.305-1.232-.556-1.275-.556s-.621.251-1.286.556c-1.134.523-1.226.556-1.559.556a1.169,1.169,0,0,1-.932-.387,12.684,12.684,0,0,1-.758-1.243c-.338-.6-.638-1.117-.659-1.15a1.092,1.092,0,0,0-.36-.125C113.918-190.947,113.908-190.947,113.875-190.859Z"
                transform="translate(-30.114 -280.671)"
                fill="#fcb611"
              />
            </g>
          </svg>
          <p className="verifiedText valid">Verified</p>
        </div>
      ) : null}
    </div>
  );
}
