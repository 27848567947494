import { Table, Input, Spin, notification, Button, Tooltip, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { CheckOutlined, CheckSquareOutlined, CloseOutlined, CloseSquareOutlined, VideoCameraAddOutlined } from '@ant-design/icons'
import EndpointService from '../../utilities/constants/endpointService';
import HTTPService from '../../service/HTTPService';
import { cypherService } from '../../utilities/cypher/cypherService';
import moment from 'moment';
import { checkTableNull } from '../viewSchool/helper';
import ConfirmModal from './ConfirmModal';
import { Link } from 'react-router-dom';
import CancelConfirmModal from './CancelConfirmModal';



const UpcomingMeetings = (props: any) => {
    let userId = cypherService.getLocalStorage("userId");
    const rejectReasonList = [
        {
            value: 'Scheduled another meeting at the same time',
        },
        {
            value: 'Waiting for the tech team update',
        },
        {
            value: 'Attending high-priority case',
        },
        {
            value: 'Team on holiday',
        },
        {
            value: "Gathering resources on progress"
        },
        {
            value: 'Others',
        }
    ]
    const [upcomingMeetingList, setUpcomingMeetingList] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [start, setStart] = useState<any>(0);
    const [limit] = useState<any>(10);
    const [searchText, setSearchText] = useState<string>('');
    const [totalData, setTotalData] = useState<any>(0);
    const [checkedReason, setCheckedReason] = useState<any>("");
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [rejectReason, setRejectReason] = useState<string>('');
    const [rejectPayload, setRejectPayload] = useState<any>(null)
    const [isOpenCancelMeeting, setIsOpenCancelMeeting] = useState<boolean>(false)
    const [cancelMeetingObj, setCancelMeetingObj] = useState<any>(null)
    let { currentTab } = props;

    useEffect(() => {
        getUpcomingMeetingList(start, limit, searchText);
    }, [currentTab])

    const getUpcomingMeetingList = (startValue: any, limitValue: any, searchTextValue: string) => {
        if (currentTab == '1') {
            setLoader(true);
            let mapUrl: string = EndpointService.getMeetingListUrl + `?limit=${limitValue}&meetingRecordFlag=Upcoming&start=${startValue}`;
            if (searchText != '') {
                mapUrl = mapUrl + `&searchBy=${searchTextValue}`;
            }
            HTTPService.get(mapUrl, {}).then((response) => {
                if (response.status.success.toLowerCase() == 'success') {
                    setLoader(false);
                    let apiResponse: any = response.data ? response.data : []
                    let meetingData = apiResponse && apiResponse[0]
                    const { customerMeetingScheduleRecordsDtoList, edTechExpertMeetingScheduleDtoList } = meetingData && meetingData
                    let tempUpcomingMeetingList: any = [];
                    customerMeetingScheduleRecordsDtoList && customerMeetingScheduleRecordsDtoList.forEach((item: any) => {
                        let meetingObject = {
                            name: item.customerName,
                            nameList: [item.serviceProviderName, "SolvEd Admin"],
                            organization: item.organizationName,
                            dateTime: moment(item.scheduleDate).format('LLL'),
                            subject: item.scheduleNote,
                            meetingLink: item.meetingUrl,
                            customerMeetingScheduleId: item.customerMeetingScheduleId,
                            action: "",
                            status: item.status,
                            variant: "customerMeeting"
                        }
                        tempUpcomingMeetingList.push(meetingObject)
                    })
                    edTechExpertMeetingScheduleDtoList && edTechExpertMeetingScheduleDtoList.forEach((item: any) => {

                        let user: string = ""
                        let id: any = ""
                        let typeName: string = ""
                        let isActive: boolean = false
                        if (item.customer) {
                            user = item.customer.name
                            id = item.customer.customerId
                            typeName = "customer"
                            isActive = true
                        } else {
                            user = item.serviceProvider.name
                            id = item.serviceProvider.serviceProviderId
                            typeName = "serviceProvider"
                            isActive = false
                        }
                        let meetingObject = {
                            name: user,
                            organization: item.organizationName,
                            dateTime: moment(item.scheduleDate).format('LLL'),
                            nameList: [item.edTechExpert.name, "SolvEd Admin"],
                            subject: item.scheduleNote,
                            meetingLink: item.meetingUrl,
                            edTechExpertMeetingScheduleId: item.edTechExpertMeetingScheduleId,
                            action: id,
                            status: item.status,
                            type: typeName,
                            variant: "edTechExpertMeeting"
                        }
                        tempUpcomingMeetingList.push(meetingObject)
                    })

                    setUpcomingMeetingList([...tempUpcomingMeetingList]);
                    setTotalData(response.totalResults ? response.totalResults : 0)
                }
                else {
                    setLoader(false);
                    notification.error({
                        message: '',
                        description: response.status.message,
                        placement: 'top'
                    })
                }
            })
                .catch(error => {
                    setLoader(false);
                    debugger
                    notification.error({
                        message: '',
                        description: error.response.data.status.message,
                        placement: 'top'
                    })
                })
        }
    }

    const changeText = (event: any) => {
        setSearchText(event.target.value);
        getUpcomingMeetingList(start, limit, event.target.value);
    }

    const pageChange = (page: any) => {
        let startValue: number = (page * 10) - 10;
        setStart(startValue);
        getUpcomingMeetingList(startValue, limit, searchText);
    }
    const handleListCompanyName = (el: any) => {
        return <span className='companyName'>{el}</span>
    }
    const handleOpenModal = () => {
        setModalShow(true)
    }
    const cancelAction = () => {
        setModalShow(false)
        setIsOpenCancelMeeting(false)
    }


    const approvedRejectedApiRequest = (payloadUrl: any) => {
        let mapUrl: string = EndpointService.createMeetingApproved + payloadUrl

        setLoader(true);
        HTTPService.put(mapUrl, {}).then(response => {
            if (response.status.success.toLowerCase() === 'success') {
                notification.success({
                    message: '',
                    description: response.status.message,
                    placement: 'top'
                })
                cancelAction()
                setLoader(false);
                getUpcomingMeetingList(start, limit, searchText);
            }
            if (response.status.success.toLowerCase() === 'fail') {
                setLoader(false);
                cancelAction()
                notification.error({
                    message: '',
                    description: response.status.message,
                    placement: 'top'
                })
            }
        }).catch(err => {
            setLoader(false);
            cancelAction()
            // notification.error({
            //     message: '',
            //     description: err.response.data.status.message,
            //     placement: 'top'
            // })
        })

    }

    const addApprovedRequest = (item: any, record: any) => {

        let payloadUrl: any = null
        if (record.type !== "serviceProvider") {
            payloadUrl = `?customerId=${item}&notificationId=8&status=Approved&userId=${userId} `

        } else {
            payloadUrl = `?serviceProviderId=${item}&notificationId=8&status=Approved&userId=${userId} `
        }
        approvedRejectedApiRequest(payloadUrl)
        console.log(payloadUrl)
    }
    const rejectApprovedRequest = (item: any, record: any) => {
        setRejectPayload(record)
        handleOpenModal()
    }

    const onSubmitReject = () => {
        const record = rejectPayload
        let payloadUrl: any = null

        let rejectNote: string = ""
        if (checkedReason === "Others") {
            rejectNote = rejectReason
        } else {
            rejectNote = checkedReason
        }

        if (record.type !== "serviceProvider") {
            payloadUrl = `?customerId=${record.action}&notificationId=8&status=Rejected&userId=${userId}&rejectNote=${rejectNote}`

        } else {
            payloadUrl = `?serviceProviderId=${record.action}&notificationId=8&status=Rejected&userId=${userId}&rejectNote=${rejectNote}`
        }
        approvedRejectedApiRequest(payloadUrl)
    }


    const columns: any = [
        {
            title: 'Name',
            width: 280,
            dataIndex: 'name',
            key: 'name',
            render: (item: any, data: any) => {
                return (<>
                    <div className="td-name flex">
                        <div> {item}</div>
                        <div className="highlighted">
                            <Tooltip placement="top" title={data.nameList && data.nameList.toString()}>
                                and  2  Others
                            </Tooltip>

                            {/* {data.nameList && data.nameList.map ((el:any)=> (<>{el}</>))} */}

                        </div>
                    </div>
                </>)
            },
        },
        {
            title: 'Organization',
            width: 300,
            dataIndex: 'organization',
            key: 'organization',
            render: (item: any, data: any) => { return (<>{item && item.map((el: any) => (handleListCompanyName(el)))}</>) }
        },
        {
            title: 'Date & Time',
            width: 180,
            dataIndex: 'dateTime',
            key: 'dateTime',
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            width: 300,
        },

        {
            title: 'Link',
            dataIndex: 'meetingLink',
            key: 'meetingLink',
            width: 100,
            // return: (item: any) => { item && window.open(item, "_blank") }

            render: (item: any, data: any) => {
                return (
                    <a href={item} className="icon-btn-group float-left" target="_blank">
                        <Tooltip placement="top" title={`${item}`}>
                            <Button type="primary" className="table-btn-3"><VideoCameraAddOutlined /></Button>
                        </Tooltip>
                    </a>
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            render: (item: any, record: any) => {
                debugger
                return (
                    <> {
                                   
                    
                        record.status !== "Approved" ? (<div className='table-action actions flex' >
                            {
                                <>      <Tooltip placement="top" title="Accept">

                                <button className='icon' style={{cursor: record.organization.length==3  ? 'not-allowed' : 'pointer'}}  disabled={record.organization.length == 3} onClick={() => addApprovedRequest(item, record)}><CheckOutlined /></button>
                                </Tooltip>
                                <Tooltip placement="top" title="Reject">

                            <button className='icon' style={{cursor: record.organization.length==3  ? 'not-allowed' : 'pointer'}}  disabled={record.organization.length == 3} onClick={() => rejectApprovedRequest(item, record)}> <CloseOutlined  /></button>
                            </Tooltip>
                            </>
                            }
                        </div>) : <> 
                        
                        <Tooltip placement="top" title="Cancel Meeting">
                        <button className='icon table-btn-3 cancel-meeting' onClick={() => { handleCancelMeeting(record) }}><span className="material-symbols-outlined">
                            free_cancellation
                        </span></button>
                        </Tooltip></>

                    } </>

                )
            },
        },
    ];

    const handleCancelMeeting = (el: any) => {
        debugger
        setIsOpenCancelMeeting(true)
        setCancelMeetingObj(el)
    }

    return (
        <div className="view-table">
            {loader ? <Spin /> : null}

            {/* <pre className='text-white'>{JSON.stringify(upcomingMeetingList, null, 1)}</pre> */}
            <Table columns={columns} dataSource={upcomingMeetingList} scroll={{ y: 'calc(100vh - 281px)', x: 0 }} pagination={{ total: totalData, onChange: pageChange }} />

            <Modal
                centered
                visible={modalShow}
                onOk={cancelAction}
                onCancel={cancelAction}
                footer={null}
                className="product-summary-modal free-demo-modal reject-modal"
            >
                <ConfirmModal rejectReasonList={rejectReasonList} setCheckedReason={setCheckedReason} checkedReason={checkedReason} rejectReason={rejectReason} cancelAction={cancelAction} setRejectReason={setRejectReason} onSubmitReject={onSubmitReject} />
            </Modal>
            <Modal
                centered
                visible={isOpenCancelMeeting}
                onOk={cancelAction}
                onCancel={cancelAction}
                footer={null}
                className="product-summary-modal free-demo-modal reject-modal"
            >
                <CancelConfirmModal cancelAction={cancelAction} cancelMeetingObj={cancelMeetingObj} getUpcomingMeetingList={getUpcomingMeetingList} />
            </Modal>
        </div>
    );
}

export default UpcomingMeetings;
