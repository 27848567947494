import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, notification, Spin, Col, Row, Input, Form } from 'antd';
import { Formik, ErrorMessage } from 'formik';
import { cypherService } from '../../utilities/cypher/cypherService';
import { Schema, IForgot } from './loginSchema';
import HTTPService from '../../service/HTTPService';
import EndpointService from '../../utilities/constants/endpointService';
import logo from '../../assets/images/SolvEd.png'

const ForgotPwd = () => {
  const [bgImg, setBgImg] = useState('')
  const [initForgotPwdFormValue, setInitForgotPwdFormValue] = useState<IForgot>({
    email: '',
  });

  const [loader, setLoader] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {

    const partyDiscriminator = JSON.parse(cypherService.getSessionStorage('partyDiscriminator') || '{}')
    setBgImg(partyDiscriminator)
  }, [])

  const onSubmit = (values: any, actions: any) => {
    setLoader(true)
    let mapUrl = EndpointService.forgotPwdUrl
    HTTPService.post(mapUrl, values).then(response => {
      const forgotPwdRes = response.data;
      cypherService.setSessionStorage('forgotPasswordEmail', values.email);
      const getStatus = response.status.success.toLowerCase();
      console.log('getStatus', forgotPwdRes)
      if (getStatus == 'success') {
        setLoader(false)
        // notification.success({
        //   message: '',
        //   description: response.data.status.message,
        //   placement: 'topRight'
        // })
        navigate("/forgotpasswordotp");
      }
    }).catch(err => {
      // console.log(err)
      // throw err
      setLoader(false)
      notification.error({
        message: '',
        description: err.response.data.status.message,
        placement: 'topRight'
      })
    });


    console.log(values, actions)
  }

  return (
    <section className={bgImg == 'Customer' ? 'login-container customer' : 'login-container service-provider'}>
      <div className='login-logo'>
        <img src={logo} alt="logo" />
      </div>
      <Row gutter={32} justify="space-between" align="middle" className="login-row">
        <Col span={12} className="login-col forgot-col">
          <span className='welcome-text'>Forgot password? <br />Don't worry, help is at hand.</span>
        </Col>

        <Col span={12} className="login-col forgot-col">
          <div className="login-card">
            {/* Loader */}
            {loader ? <Spin /> : null}
            <h2>Reset Password</h2>
            <h6>We will send you a <span className="text-green">6-digit Security Code</span> to reset your password</h6>
            <Formik initialValues={initForgotPwdFormValue}
              validationSchema={Schema.forgotPwdSchema}
              onSubmit={(values, actions) => {
                onSubmit(values, actions);
              }}>
              {({ handleSubmit, setFieldValue, values, errors, getFieldProps, handleChange }) => {
                return (
                  <Form layout="vertical" className="login-form">
                    <Form.Item name="email" className="" label="Work Email">
                      <Input name="email" className="" onChange={handleChange} placeholder="Enter your work mail" />
                      <ErrorMessage name="email">{msg => <div className='error'>{msg}</div>}</ErrorMessage>
                    </Form.Item>
                    <div className="submit-btn"><Button block htmlType="submit" type="primary" onClick={() => handleSubmit()}>SEND SECURITY CODE</Button></div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    </section>
  );
};
export default ForgotPwd;