import { useState } from 'react'
import { Tabs } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons'
import PreviousMeetings from './PreviousMeetings';
import UpcomingMeetings from './UpcomingMeetings';
import Header from '../header/Header';

const { TabPane } = Tabs;

const Meetings = () => {

    const [currentTab, setCurrentTab] = useState<string>('1');

    const tabChange = (tabKey: any) => {
        setCurrentTab(tabKey);
    }


    return (
        <div className="dashboard viewData-main">
            <div className="viewData">
                <Tabs defaultActiveKey="1" onChange={(tabKey) => tabChange(tabKey)}>
                    <TabPane tab="Upcoming" key="1">
                        <UpcomingMeetings currentTab={currentTab} />
                    </TabPane>
                    <TabPane tab="Previous" key="2">
                        <PreviousMeetings currentTab={currentTab} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default Meetings

