import { CheckOutlined } from "@ant-design/icons";
export default function QuestionHeader(props: any) {
    const filterQuestion = (el: any) => {
        if (el === "Please attach your most recent logo") {
            return "Logo"
        }
        else if (el === "Add your product images") {
            return "Product images"
        }
        else if (el === "Add a promotional video") {
            return "Promotional videos"
        }
        return el
    }
    return (
        <>

            <h4>{filterQuestion(props.question)}</h4>
        </>
    )
} 
