import * as Yup from "yup";
const bussinessEmail = new RegExp("^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$");
const phoneRegExp = /^((\\+[1-9]{1,6}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,6})[ \\-]*)*?[0-9]{3,6}?[ \\-]*[0-9]{3,6}?$/


let FILE_SIZE = 1024 * 1024 // 1 Mb
const fileCheck = (el: any) => {

  return 0

}
export const edtechExpertValidationSchema = Yup.object({
  name: Yup.string().required("Please enter name").matches(/^([A-Za-z\s]*)$/gi, "Please enter a valid  name"),
  email: Yup.string().required("Please enter email id").email("Invalid Email").matches(bussinessEmail, "Please enter work email"),
  phoneNumber: Yup.string().required("Please enter  Phone Number").matches(phoneRegExp, 'Phone number is not valid').max(13, "Maximum digits allowed is 13"),
  yearOfExperience: Yup.number().required("Please enter  year of Experience").
    min(1, 'Please enter minimum 1').
    max(50, 'Maximum years of experience allowed is 50'),
  areaOfExperience: Yup.array().min(1, "Atleast one must be choose").required("Please choose the Area").nullable(),
  location: Yup.array().min(1, "Atleast one must be choose").required("Please choose the location").nullable(),
  professionalSummary: Yup.string().required("Please enter  professional summary"),
  setAvailability: Yup.string(),
  profile: Yup.mixed().nullable().required('A file is required')
});
