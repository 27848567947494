import { CloseOutlined } from "@ant-design/icons";
import { notification, Spin } from "antd";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import HTTPService from "../../../service/HTTPService";
import EndpointService from "../../../utilities/constants/endpointService";

export default function VideoPreview(props: any) {
  const { item, questionList, setQuestionList, index, editInit } = props;
  const [loader, setLoader] = useState<boolean>(false);
  let tempQuestionList = questionList;
  let defaultAnswerPayload = tempQuestionList[index].answer;

  const handleRemoveOne = (elIndex: number, el: any) => {
    let apiCall: boolean = true;
    let result =
      defaultAnswerPayload &&
      defaultAnswerPayload.filter((elItem: any, i: any) => {
        if (elIndex !== i) {
          return elItem;
        } else {
          if (elItem && elItem.create && elItem.create) {
            apiCall = false;
          } else {
            deleteVideo(elItem);
          }
        }
      });
    if (!apiCall) {
      tempQuestionList[index].answer = [...result];
      console.log(tempQuestionList);
      setQuestionList([...tempQuestionList]);
    }
  };

  const deleteVideo = (item: any) => {
    setLoader(true);
    let mapUrl: string = EndpointService.deleteMultiPartFileUrl + item.editQuestionId;

    HTTPService.delete(mapUrl)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          setLoader(false);
          editInit();
          notification.success({
            message: "",
            description: response.status.message,
            placement: "top",
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: "",
          description: error,
          placement: "top",
        });
      });
  };

  const videoPreview = (el: any) => {
    return (
      el.answer &&
      el.answer.map((video: any, elIndex: any) => {
        return (
          <div className="uploadedVideo">
            <ReactPlayer className="react-player" url={video.filePath} controls={true} loop={true} />
            <span className="react-player-close" onClick={() => handleRemoveOne(elIndex, el)}>
              <CloseOutlined />
            </span>
          </div>
        );
      })
    );
  };

  return (
    <>
      {loader ? <Spin /> : null}

      {videoPreview(item)}
    </>
  );
}
