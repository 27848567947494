
import SideBar from './layout/SideBar';
export default function MessageConversation() {

  return (
    <div className="new-dashboar">
      <div className=" notification-vendor">
        <div className="notifcation-sidebar">
          <SideBar />
        </div>
      </div>
    </div>
  );
}
