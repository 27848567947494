import React, { useEffect, useState } from "react";
import { Table, Button, Input, Menu, Dropdown, Collapse, Badge, Space, Checkbox, Spin, notification, TableProps } from "antd";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import HTTPService from "../../service/HTTPService";
import EndpointService from "../../utilities/constants/endpointService";
import { cypherService } from "../../utilities/cypher/cypherService";
import moment from 'moment';
import { checkTableNull, columnsFilterValues, handleStatus, viewServiceProviderStatus } from "../viewSchool/helper";
import type { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';
import { baseUrl } from "../../environments/environment";
import LeadershipIcon from "../../assets/images/icons/LeadershipIcon";
import AssessmentIcon from "../../assets/images/icons/AssessmentIcon";
import CurriculumIcon from "../../assets/images/icons/CurriculumIcon";
import PersonalIcon from "../../assets/images/icons/PersonalIcon";
import PlanningIcon from "../../assets/images/icons/PlanningIcon";
import starOutline from "../../assets/images/icons/star_outline.svg";
import starFilled from "../../assets/images/icons/star_filled.svg";
import countryList from "../serviceProviderDetails/CountryList";

const { Panel } = Collapse;

interface DataType {
  key: any;
  name: any;
  email: any;
  phoneNumber: any;
  signupStatus: any;
  status: any;
  partner: any;
  product: any;
  companyName: any;
  leadershipManagement: any;
  assessment: any;
  curriculum: any;
  personalAndSocialDevelopment: any;
  planningAndTeaching: any;
  registrationDate: any;
  matchedCount: any;
  serviceProviderProductId: any;
  serviceProviderId: any;
}
function ViewServiceProvider() {
  const navigate = useNavigate();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [serviceProviderList, setServiceProviderList] = useState<any>([]);
  const [serviceProviderTableFilterList, setServiceProviderFilterList] = useState<any>([]);
  const [tempdata, settempdata] = useState<any>([]);
  const [serviceProviderTableList, setServiceProviderTableList] = useState<any>([]);
  const [start, setStart] = useState<number>(0);
  const [limit] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [filterData, setFilterData] = useState<any>([]);
  const [filterQuestionaireOptionId, setFilterQuestionaireOptionId] = useState<any>([]);
  const [otherFilterText, setOtherFilterText] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [tableFilters, setTableFilters] = useState<any>([])
  const [tableFiltersLocation, setTableFiltersLocation] = useState<any>([])

  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [selectedFilterData, setSelectedFilterData] = useState<any>({
    location: [],
    channelpartner: [],
  });

  useEffect(() => {
    init()
  }, []);

  const init = () => {
    cypherService.setSessionStorage("editServiceProviderObject", null);
    getServiceProviderData(start, limit, searchText, filterQuestionaireOptionId, otherFilterText);
    getFilterData();
    tableFilter()
  }
  const tableFilter = () => {
    let arrayList: any = []
    countryList.countries.map((country: any) => {
      if (country) {
        arrayList.push(country.name)
      }
    })
    setTableFiltersLocation(arrayList)
    const mapUrl = EndpointService.filterMasterRecord;
    HTTPService.get(mapUrl, {})
      .then((response) => {
        setTableFilters(response.data)
        // console.log(response.data)
      }).catch((err) => {
        setLoader(false);
        notification.error({
          message: "",
          // description: err.response.data.status.message,
          placement: "top",
        });
      });
  }
  const checkNullToString = (el: any) => {
    return el ? el : "-"
  }


  let tempServiceProviderDataList:any=[]
  const responseData = (element:any, index:any) => {
    let inde=0
    const processProduct = (productElement:any) => {
      const tempServiceProviderData = {
        key: inde + 1,
        name: checkNullToString(element.name),
        email: checkNullToString(element.email),
        phoneNumber: checkNullToString(element.phoneNumber),
        signupStatus: element.signupStatus || false,
        status: element.status|| false,
        partner: checkNullToString(element.partner),
        companyName: checkNullToString(element.companyName),
        location: checkNullToString(element.location),
        serviceProviderId: checkNullToString(element.serviceProviderId),
        registrationDate: checkNullToString(element.registrationDate),
        product: checkNullToString(productElement.productName),
        serviceProviderProductId: checkNullToString(productElement.productId),
        leadershipManagement: checkNullToString(productElement.leadershipManagement),
        assessment: checkNullToString(productElement.assessment),
        curriculum: checkNullToString(productElement.curriculum),
        personalAndSocialDevelopment: checkNullToString(productElement.personalAndSocialDevelopment),
        planningAndTeaching: checkNullToString(productElement.planningAndTeaching),
        matchedCount: checkNullToString(productElement.matchedCount),
        serviceProviderProductSpotLight: productElement.serviceProviderProductSpotLight || false,
      };
      tempServiceProviderDataList.push(tempServiceProviderData);
    };
    if (element.product == null ) {
      processProduct(element);
    } else {
      element.product.forEach(processProduct);
    }

    const spotlightProducts = tempServiceProviderDataList.filter(
      (product:any) => product.serviceProviderProductSpotLight
    );

    spotlightProducts.sort((a:any, b:any) =>
    tempServiceProviderDataList.indexOf(a) - tempServiceProviderDataList.indexOf(b)
  );
  const data = spotlightProducts.concat(
    tempServiceProviderDataList.filter((product:any) => !product.serviceProviderProductSpotLight)
  );

    return data;
  };
  
  
  const getServiceProviderData = (startValue: number, limitValue: number, searchTextData: string, filterId: any, otherText: any) => {
    setLoader(true);
    let urlParam: string = `?start=${startValue}&limit=${limitValue}`;
    if (searchTextData) {
      urlParam = urlParam + `&search=${searchTextData}`;
    }
    if (filterId.length > 0) {
      let filterIdData: any = "";
      filterId.forEach((item: any) => {
        filterIdData = filterIdData == "" ? filterIdData + item : filterIdData + "," + item;
      });
      urlParam = urlParam + `&questionaireOptionId=${filterIdData}`;
    }
    if (otherText.length > 0) {
      let otherData: any = "";
      otherText.forEach((item: any) => {
        otherData = otherData == "" ? otherData + item : otherData + "," + item;
      });
      urlParam = urlParam + `&otherText=${otherData}`;
    }

    const monthyear = moment().format('YYYY-MM')
    const firstDay = moment(monthyear + "-01").format("YYYY-MM-DD");
    const dateTo = moment(firstDay).subtract('1', 'days').format("MM-YYYY");
    const mapUrl = EndpointService.getServiceProviderData + `?customerCountStartMonthAndYear=${dateTo}`;
    HTTPService.get(mapUrl, {})
      .then((response) => {
        const Res = response;
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          setLoader(false);
          setTotalData(Res.totalResults);
          let tempServiceProviderDataList: any = [];
          Res.data &&
            Res.data.forEach((element: any, index: string) => {
              tempServiceProviderDataList=  responseData (element, index)
            });
          setServiceProviderList(tempServiceProviderDataList);
          setServiceProviderTableList(tempServiceProviderDataList);
          setServiceProviderFilterList(tempServiceProviderDataList);
          settempdata(tempServiceProviderDataList);
          // console.log(tempServiceProviderDataList);
        }
        if (getStatus === "fail") {
          setLoader(false);
          notification.error({
            message: "",
            description: Res.data.status.message,
            placement: "topRight",
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        notification.error({
          message: "",
          description: err.response.data && err.response.data.status.message,
          placement: "topRight",
        });
      });
  };
  const getFilterData = () => {
    let mapUrl: string = EndpointService.getFilterParamUrl;
    HTTPService.get(mapUrl, {})
      .then((response) => {
        let tempFilterData: any = [];
        let filterObject = {
          filterGroupName: "Location",
          questionaireOptionId: Object.keys(response.data.locationList),
          values: Object.values(response.data.locationList),
        };
        tempFilterData.push(filterObject);
        filterObject = {
          filterGroupName: "Channel Partner",
          questionaireOptionId: Object.keys(response.data.channelPartnerList),
          values: Object.values(response.data.channelPartnerList),
        };
        tempFilterData.push(filterObject);
        setFilterData([...tempFilterData]);
      })
      .catch((err) => {
        setLoader(false);
        notification.error({
          message: "",
          description: err.response.data && err.response.data.status.message,
          placement: "top",
        });
      });
  };

  const searchTextChange = async (event: any) => {
    debugger;
    let searchValue = event.target.value.toLowerCase();
    setServiceProviderTableList([]);
    setSearchText(searchValue);
    // console.log(serviceProviderTableFilterList)
    if (searchText.length != 0) {
      let result =
        await serviceProviderTableFilterList &&
        serviceProviderTableFilterList.filter((item: any, index: number) => {
          let name = item && item.name.toLowerCase();
          let company = item && item.companyName.toLowerCase();
          let location = item && item.location.toLowerCase();
          let email = item && item.email.toLowerCase();
          let status = item && item.status.toLowerCase();
          let product = item && item.product.toLowerCase();

          if (name.includes(searchValue) ||product.includes(searchValue) || company.includes(searchValue) || email.includes(searchValue) || status.includes(searchValue) || location.includes(searchValue)) {
            return item;
          }
        });

      // console.log(result)

      setServiceProviderTableList(result);
      setTotalData(result.length);

    } else {
      setServiceProviderTableList(serviceProviderTableFilterList);
      setTotalData(serviceProviderTableFilterList.length);

      // console.log(serviceProviderTableFilterList)
    }
  };
  const locationSplit = (el: any) => {
    debugger;
    let splitArray = el && el.split(",")
    let arrayList: any = []
    if (typeof (splitArray) === "object") {
      splitArray && splitArray.forEach((list: any, index: number) => {
        let obj = {
          label: list,
          key: index,
        }
        arrayList.push(obj)
      })
    }
    if (arrayList.length > 1) {
      return <Dropdown className='tbl-location' menu={{ items: arrayList }} trigger={['click']}>
        <a onClick={e => e.preventDefault()}>
          <Space> Multi Location</Space>
        </a>
      </Dropdown>
    } else {
      return <span className='tbl-location'>{el}</span>
    }
  }

  const spotLight = (serviceProviderProductId: number) => {
    setLoader(true)
    let mapUrl = EndpointService.productSpotlight + serviceProviderProductId
    HTTPService.put(mapUrl, {}).then(response => {
      if (response.status.success.toLowerCase() === 'success') {
        notification.success({
          message: '',
          description: response.status.message,
          placement: 'top'
        })
        init()

      }
      if (response.status.success.toLowerCase() === 'fail') {
        notification.error({
          message: '',
          description: response.status.message,
          placement: 'top'
        })
        setLoader(false)
      }
    }).catch(err => {
      setLoader(false)
      notification.error({
        message: '',
        description: err.response.data.status.message,
        placement: 'top'
      })
    });

  }


  const handleSpotLight = (e: any, b: any) => {
    // console.log(e,b,"hghhchgc")
    let serviceProviderProductId = b.serviceProviderProductId
    let count = 0

    if (!e) {
      serviceProviderTableList && serviceProviderTableList.forEach((item: any) => {
        if (item.serviceProviderProductSpotLight) {
          count = count + 1
        }
      })
      if (count <= 2) {
        serviceProviderProductId && spotLight(serviceProviderProductId)
      } else {
        notification.error({
          message: '',
          description: "Please select no more than 3 Products",
          placement: 'top'
        })
      }
    } else {
      serviceProviderProductId && spotLight(serviceProviderProductId)
    }
  }
  const handleEditRedirect = (e: any, b: any) => {
    let serviceProviderId = b.serviceProviderId
    cypherService.setLocalStorage("serviceProviderId", serviceProviderId)
    cypherService.setLocalStorage("isServiceProviderEdit", true);
    cypherService.setLocalStorage("isProductsEdit", true);

    navigate("/serviceProviderDetails");
  }




  const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<DataType>);
  };
  const sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
  const columns: any = [
    {
      title: "d",
      width: 90,
      dataIndex: "serviceProviderProductSpotLight",
      key: "serviceProviderProductSpotLight",
      render: (e: any, b: any) => <div onClick={() => { handleSpotLight(e, b) }}><img src={e ? starFilled : starOutline} /></div>,
    },
    {
      width: 200,
      title: "Date",
      dataIndex: "registrationDate",
      key: "registrationDate",
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.registrationDate);
        const dateB = new Date(b.registrationDate);

        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      }
    },
    {
      width: 260,
      title: "Product",
      dataIndex: "product",
      key: "product",
      ellipsis: true,
      sorter: (a: any, b: any) => sorter(a.product, b.product),
      render: (e: any, b: any) => <span className="hoverElement newHoverElement oneLine" onClick={(e) => { editServiceProvider(b) }}  style={{
        pointerEvents: e !="-" ? 'auto' : 'none',
      }}>{e}</span>,
    },
    {
      width: 180,
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a: any, b: any) => sorter(a.companyName, b.companyName),
      render: (e: any, b: any) => <span className="hoverElement" onClick={(e) => { handleEditRedirect(e, b) }}>{e}</span>,
    },
    {
      width: 370,
      title: "Mail Id",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => sorter(a.email, b.email),
      render: (element: any) => <><div className="td-email oneLine">{element}</div></>,
    },

    {
      title: <LeadershipIcon />,
      dataIndex: "leadershipManagement",
      key: "leadershipManagement",
      render: (element: any) => <>{checkTableNull(element)}</>,
      width: 100,
      sorter: (a: any, b: any) => sorter(a.leadershipManagement, b.leadershipManagement),
    },
    {
      title: <AssessmentIcon />,
      dataIndex: "assessment",
      key: "assessment",
      render: (element: any) => <>{checkTableNull(element)}</>,
      width: 100,
      sorter: (a: any, b: any) => sorter(a.assessment, b.assessment),
    },
    {
      title: <CurriculumIcon />,
      dataIndex: "curriculum",
      key: "curriculum",
      render: (element: any) => <>{checkTableNull(element)}</>,
      width: 100,
      sorter: (a: any, b: any) => sorter(a.curriculum, b.curriculum),
    },

    {
      title: <PersonalIcon />,
      dataIndex: "personalAndSocialDevelopment",
      key: "personalAndSocialDevelopment",
      onFilter: (value: any, record: any) => record.type.indexOf(value) === 0,
      render: (element: any) => <>{checkTableNull(element)}</>,
      width: 100,
      sorter: (a: any, b: any) => sorter(a.personalAndSocialDevelopment, b.personalAndSocialDevelopment),

    },
    {
      title: <PlanningIcon />,
      dataIndex: "planningAndTeaching",
      key: "planningAndTeaching",
      render: (element: any) => <>{checkTableNull(element)}</>,
      width: 100,
      sorter: (a: any, b: any) => sorter(a.planningAndTeaching, b.planningAndTeaching),
    },
    {
      title: "Matched",
      dataIndex: "matchedCount",
      key: "matchedCount",
      render: (element: any) => <>{checkTableNull(element)}</>,
      width: 180,
      sorter: (a: any, b: any) => sorter(a.matchedCount, b.matchedCount),
    },
    {
      title: "GCC",
      dataIndex: "partner",
      render: (element: any) => <>{checkTableNull(element)}</>,
      filters: [...columnsFilterValues(tableFilters.gcc)],
      onFilter: (value: string, record: any) => record.partner.indexOf(value) === 0,
      width: 180,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      width: 300,
      filters: [...columnsFilterValues(tableFiltersLocation)],
      onFilter: (value: string, record: any) => record.location.indexOf(value) === 0,
      render: (e: any) => (
        <>{locationSplit(e)} </>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // sorter: (a: any, b: any) => sorter(a.status, b.status),
      width: 150,
      filters: [
        {
          text: 'Approved',
          value: 'Approved',
        },
        {
          text: 'Pending Approval',
          value: 'Pending',
        },
      ],
      onFilter: (value: string, record: any) => record.status.indexOf(value) === 0,
      render: (element: any) => <>{viewServiceProviderStatus(element)}</>,
    },
  ];
  const addServiceProvider = () => {
    cypherService.setLocalStorage("isServiceProviderEdit", false);
    cypherService.setLocalStorage("isProductsEdit", false);
    navigate("/serviceProviderDetails");
  };
  // Nested table start

  const handleAddNewProducts = (item: any) => {
    let serviceProviderId = item.serviceProviderObject && item.serviceProviderObject.serviceProviderId;
    serviceProviderId && cypherService.setLocalStorage("serviceProviderId", serviceProviderId);
    cypherService.setLocalStorage("isProductsEdit", false);
    navigate("/productDetails");
  };

  const editServiceProvider = (item: any, type: string = "") => {
    cypherService.setLocalStorage("singleServiceProviderDetails", item);
    let serviceProviderId = item.serviceProviderId;
    let serviceProviderProductId = item.serviceProviderProductId;
    serviceProviderId && cypherService.setLocalStorage("serviceProviderId", serviceProviderId);
    serviceProviderProductId && cypherService.setLocalStorage("serviceProviderProductId", serviceProviderProductId);

    let productsDetails = {
      name: item.name,
      mailId: item.email,
      productId: item.serviceProviderProductId,
    };
    cypherService.setLocalStorage("isProductsEdit", true);
    productsDetails && cypherService.setLocalStorage("productsDetails", productsDetails);
    navigate("/productDetails");
  };

  const onChange = (key: string | string[]) => {
    // console.log(key);
  };

  const filterChange = (event: any, index: number, groupName: any) => {
    let tempChecked: any = filterQuestionaireOptionId;
    let selectedData: any = selectedFilterData;
    let tempOtherText: any = otherFilterText;

    if (event.target.checked) {
      filterData.forEach((item: any) => {
        if (item.filterGroupName == groupName) {
          if (groupName == "Channel Partner") {
            tempChecked.push(item.questionaireOptionId[index]);
          } else {
            tempOtherText.push(event.target.value);
          }
          selectedData[groupName.replace(" ", "").toLowerCase()].push(event.target.value);
        }
      });
      setOtherFilterText([...tempOtherText]);
      setFilterQuestionaireOptionId([...tempChecked]);
      setSelectedFilterData({ ...selectedData });

      let result: any = [];
      serviceProviderList &&
        serviceProviderList.filter((item: any) => {
          return (
            selectedData &&
            selectedData.location &&
            selectedData.location.forEach((el: any) => {
              if (el === item.location) {
                result.push(item);
              }
            })
          );
        });

      let partnerList: any = [];
      let resultClear: boolean = false;
      if (result.length === 0) {
        partnerList = serviceProviderList;
      } else {
        partnerList = result;
      }

      tempChecked &&
        partnerList &&
        partnerList.filter((item: any) => {
          let statusNo = item.partnerStatus === "No" ? "25" : "24";
          return (
            tempChecked &&
            tempChecked.forEach((el: any) => {
              if (el === statusNo) {
                if (result.length !== 0 && !resultClear) {
                  resultClear = true;
                  result = [];
                }
                result.push(item);
              }
            })
          );
        });

      setServiceProviderFilterList(result);
      setServiceProviderTableList(result);
      // console.log(result);
    } else {
      filterData.forEach((item: any) => {
        if (item.filterGroupName == groupName) {
          let groupRemoveDataIndex: any = selectedData[groupName.replace(" ", "").toLowerCase()].indexOf(event.target.value);
          selectedData[groupName.replace(" ", "").toLowerCase()].splice(groupRemoveDataIndex, 1);
          if (groupName == "Channel Partner") {
            let questionaireIdElement: any = item.questionaireOptionId[index];
            let removeIndex: number = tempChecked.indexOf(questionaireIdElement);
            tempChecked.splice(removeIndex, 1);
          } else {
            let removeIndex: number = tempOtherText.indexOf(event.target.value);
            tempOtherText.splice(removeIndex, 1);
          }
        }
      });
      setOtherFilterText([...tempOtherText]);
      setFilterQuestionaireOptionId([...tempChecked]);
      setSelectedFilterData({ ...selectedData });

      let result: any = [];

      if (selectedData && selectedData.location && selectedData.location.length > 0) {
        serviceProviderList &&
          serviceProviderList.filter((item: any) => {
            return (
              selectedData &&
              selectedData.location &&
              selectedData.location.forEach((el: any) => {
                if (el === item.location) {
                  result.push(item);
                }
              })
            );
          });
        setServiceProviderFilterList(result);
        setServiceProviderTableList(result);
      } else {
        setServiceProviderFilterList(serviceProviderList);
        setServiceProviderTableList(serviceProviderList);
      }
    }
  };

  const submitSelectedFilterData = () => {
    getServiceProviderData(start, limit, searchText, filterQuestionaireOptionId, otherFilterText);
  };

  const onClearFilterData = () => {
    setOtherFilterText([]);
    setFilterQuestionaireOptionId([]);
    setSelectedFilterData({
      location: [],
      channelpartner: [],
    });
    getServiceProviderData(start, limit, searchText, [], []);
  };

  const dropdownMenu = () => {
    if (filterData) {
      return (
        <Menu className="collapse-filter">
          <Menu.Item>
            <div onClick={(e) => e.stopPropagation()}>
              <Collapse defaultActiveKey={["1"]} onChange={onChange} accordion={true}>
                {filterData.map((item: any, index: number) => (
                  <Panel header={item.filterGroupName} key={index}>
                    <Checkbox.Group value={selectedFilterData[item.filterGroupName.replace(" ", "").toLowerCase()]}>
                      {item.values.map((data: any, inx: number) => (
                        <div>
                          <Checkbox value={data} onChange={(e: any) => filterChange(e, inx, item.filterGroupName)}>
                            {data}
                          </Checkbox>
                        </div>
                      ))}
                    </Checkbox.Group>
                  </Panel>
                ))}
              </Collapse>
              <div>
                <Button type="link" onClick={() => onClearFilterData()}>
                  Clear
                </Button>
                <Button type="link" onClick={() => submitSelectedFilterData()}>
                  Apply
                </Button>
              </div>
            </div>
          </Menu.Item>
        </Menu>
      );
    } else {
      return <></>;
    }
  };


  const downloadRequest = () => {
    const monthyear = moment().format('YYYY-MM')
    const firstDay = moment(monthyear + "-01").format("YYYY-MM-DD");
    const dateTo = moment(firstDay).subtract('1', 'days').format("MM-YYYY");

    let url = baseUrl + EndpointService.downloadServiceProviderUrl + `?customerCountStartMonthAndYear=${dateTo}`;
    window.open(url, "_blank");
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const handlePageChange =(page:any) => {
    debugger
    setCurrentPage(page);
    const paginatedData = tempdata.slice(
      ((currentPage - 1) * pageSize),
      currentPage * pageSize
      );
      // console.log(paginatedData)
      setServiceProviderTableList(paginatedData);
      setTotalData(serviceProviderList.length);

  };


  return (
    <div className="view-table school-list">
      {loader ? <Spin /> : null}
      <div className="view-table-sorting">
        <div className="main view-table-button">
          <Button type="primary" onClick={downloadRequest}>
            <DownloadOutlined /> Download
          </Button>
          
          {/* <pre>{JSON.stringify(serviceProviderTableList, null, 2)}</pre> */}
        </div>

        <div className="main view-table-button">
          <Button
            type="primary"
            onClick={() => {
              addServiceProvider();
            }}
          >
            <PlusOutlined /> Add EdTech Provider
          </Button>
        </div>
        <div className="main view-table-search">
          <Input placeholder="Search" value={searchText} onChange={(event) => searchTextChange(event)} />
        </div>

      </div>
      {/* <pre className="text-white text-xs">{JSON.stringify(serviceProviderTableList, null, 1)}</pre> */}

      <Table
        className="viewSchool-table product-table"
        columns={columns}
        dataSource={serviceProviderTableList}
        scroll={{ y: "calc(70vh - 80,px)" }}
        pagination={{ total: totalData, pageSize: 20, pageSizeOptions: ["20"] }}
        onChange={handleChange}
      
      />
 
      
    </div>
  );
}

export default ViewServiceProvider;