import React, { useEffect, useState } from "react";
import moment from 'moment';
import { useNavigate, useParams } from "react-router-dom";
import { cypherService } from "../../../utilities/cypher/cypherService";
import { RootStateOrAny, useSelector } from "react-redux";
import EndpointService from "../../../utilities/constants/endpointService";
import HTTPService from "../../../service/HTTPService";
import { Spin, message } from "antd";
import MessageHeader from "./MessageHeader";

export default function ExpertConversationsLists() {
  const partyDiscriminator = cypherService.getSessionStorage("partyDiscriminator")
  const expertDetails = cypherService.getSessionStorage("expertDetails")

  const navigate = useNavigate();
  const { messageMasterId } = useParams();
  const [loader, setLoader] = useState(false);
  const [rangeList, setRangeList] = useState<any>([]);
  useEffect(() => {
    debugger
    getConversationRange();
  }, [messageMasterId]);

  const getConversationRange = () => {
    setLoader(true);

    let mapUrl: string = "";
    let urlMessageMasterId = messageMasterId?.split("-")[1]
    if (expertDetails) {
      mapUrl = EndpointService.getConversationsRange + `messagingRequiresToDisplayFor=${partyDiscriminator}&messageMasterId=${urlMessageMasterId}&innerMessagingRequiresToDisplayFor=${expertDetails}`
    } else {
      mapUrl = EndpointService.getConversationsRange + `messagingRequiresToDisplayFor=${partyDiscriminator}&messageMasterId=${urlMessageMasterId}`
    }

    HTTPService.get(mapUrl)
      .then((response) => {
        setLoader(false);
        if (response.status.success.toLowerCase() == 'success') {
          message.success(response.status.message);
          setRangeList(response.data);
        }
      })
      .catch((errorResponse) => {
        setLoader(false);
        console.log(errorResponse);
        throw errorResponse;
      });
  };

  const handleGetSingleConversationList = (el: any) => {
    debugger
    navigate(`/message-conversation/${messageMasterId}/${el.messageNotificationId}`);
    let name = partyDiscriminator === 'Customer' ? el.customer.name : el.serviceProvider.companyName;
    cypherService.setSessionStorage('messageServiceProviderName', name);
  };


  // message
  return <>
    <>
      {loader ? <Spin /> : null}
      <MessageHeader rangeList={rangeList} />
      {rangeList &&
        rangeList.map((item: any) => (
          <div
            key={item.messageNotificationId}
            className={item.isViewed ? "notification-list read" : "notification-list"}
            onClick={() => {
              handleGetSingleConversationList(item);
            }}
          >
            <div className="meeting-description text-style">
              <div className="school-name">
                <p>
                  {partyDiscriminator === 'Customer' ? <span> {item && item.customer && item.customer.name} - </span> : <span> {item.serviceProvider && item.serviceProvider.companyName} - </span>}
                  {item.content}
                </p>
              </div>
            </div>
            <div className="meeting-time text-style">
              <p>on {moment(item.createdOn).format('llll')}</p>
            </div>
          </div>
        ))}
    </>
  </>;
}
