export default class UaeEmiratesList {
    static emirates = [
        {
            name: " Abu Dhabi",
        },
        {
            name: "Ajman",
        },
        {
            name: " Dubai",
        },
        {
            name: "Fujairah",
        },
        {
            name: "Ras Al Khaimah",
        },
        {
            name: " Sharjah",
        },
        {
            name: "Umm Al Quwain",
        }
    ];
}
