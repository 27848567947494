import { notification } from 'antd';
import axios from 'axios';
import environment from '../environments/environment';
import { cypherService } from '../utilities/cypher/cypherService';

var instance = axios.create({
    baseURL: environment.baseUrl,
    //baseURL: process.env.REACT_APP_API_PATH,
});

// request block
instance.interceptors.request.use((config: any) => {
    // token setup config.headers['token'] = config.headers['token'] || token;
    const _token = cypherService.getSessionStorage('appToken');
    if (_token) {
        config.headers.AUTHORIZATION = `Bearer ${_token}`;
        config.headers.Domain = 'User';
    }
    else {
        config.headers.AUTHORIZATION = `token`;
        config.headers.Domain = 'User';
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// response block
instance.interceptors.response.use((response) => {
    if (response.status === 200) {
        if (response.data.status && response.data.status.message === "Access Denied") {
            sessionStorage.clear();
            localStorage.clear();
            if (window.location.hash !== '#/') {
                window.location.href = window.location.origin;
            }
        }
    }
    return response;
}, (error) => {
    if (error.response && error.response.status === 401) {
        notification.error({
            message: '',
            description: error.response.data.status.message,
            placement: 'topRight'
        })
    }
    else if (error.response && error.response.data && error.response.data.error &&
        (error.response.data.session === false || error.response.data.session === "false")) {
        sessionStorage.clear();
        localStorage.clear();
        if (window.location.hash !== '#/') {
            window.location.href = window.location.origin;
        }
    }
    else {
        return Promise.reject(error);
    }
});

export default instance;