import { forEach } from "lodash";
import environment from "../../environments/environment";
import { cypherService } from "../../utilities/cypher/cypherService";

let imageBaseUrl = environment.imageBaseUrl;
const findStatus = (answerObject: any, item: any) => {
  let returnStatus = null;
  answerObject.serviceProviderProductApprovalStatus &&
    answerObject.serviceProviderProductApprovalStatus.map((data: any) => {
      if (item.questionaireId === data.questionaireId) {
        returnStatus = data.status;
      }
    });
  return returnStatus;
};

export const setEditAnswerObject = (questionObject: any, answerObject: any, priorityList: any, othersInitialValues: any, moment: any, setOthersInitialValues: any, isEdit: any) => {
  let tempQuestionObject: any = questionObject;
  let tempInitialValues: any = othersInitialValues;
  let defaultCurriculaValue: any = [];

  questionObject.forEach((item: any, index: number) => {
    if (tempQuestionObject[index].code === "name") {
      tempQuestionObject[index].answer = answerObject.name;
      tempInitialValues.name = answerObject.name;

      item["status"] = findStatus(answerObject, item);
    } else if (tempQuestionObject[index].code === "shortDescription") {
      tempQuestionObject[index].answer = answerObject.shortDescription;
      tempInitialValues.shortDescription = answerObject.shortDescription;
      item["status"] = findStatus(answerObject, item);
    } else if (tempQuestionObject[index].code === "fullDescription") {
      tempQuestionObject[index].answer = answerObject.description;
      tempInitialValues.fullDescription = answerObject.description;
      item["status"] = findStatus(answerObject, item);
    } else if (tempQuestionObject[index].code == "priorities") {
      let saveArrayList: any = [];
      let arr = priorityList;
      arr &&
        arr.forEach((elItem: any) => {
          elItem.subcategories &&
            elItem.subcategories.forEach((el: any) => {
              let isValid = saveArrayList.includes(el.subProductCategoryId);
              !isValid && saveArrayList.push(el.subProductCategoryId);
            });
        });

      cypherService.setLocalStorage("saveArrayListPriorities", saveArrayList);

      tempQuestionObject[index].answer = priorityList;
      tempInitialValues.priorities = priorityList;
      item["status"] = findStatus(answerObject, item);
    } else if (tempQuestionObject[index].code == "curricula") {
      let questionaireOption = tempQuestionObject[index].questionaireOption;
      let answerQuestionaireOptionId: any = [];
      //Find default answer
      answerObject.serviceProviderProductAgeGroupList &&
        answerObject.serviceProviderProductAgeGroupList.map((innerItem: any) => {
          questionaireOption &&
            questionaireOption.map((obj: any) => {
              if (obj.value === innerItem.curriculumId) {
                let valid = defaultCurriculaValue && defaultCurriculaValue.some((unique: any) => unique === obj.option);

                if (!valid) {
                  defaultCurriculaValue.push(obj.option);
                }
                if (isEdit) {
                  let ans = {
                    questionaireOptionId: innerItem.ageGroup.ageGroupId,
                    answerValue: obj.option,
                    editQuestionId: innerItem.serviceProviderProductAgeGroupId,
                  };
                  answerQuestionaireOptionId.push(ans);
                }
              }
            });
        });

      // Answer Mapping

      tempInitialValues.curricula = defaultCurriculaValue;
      tempQuestionObject[index].answer = defaultCurriculaValue;

      tempQuestionObject[index]["answerPayload"] = answerQuestionaireOptionId;

      if (defaultCurriculaValue) {
        tempQuestionObject[index]["answerPayload"] = [...answerQuestionaireOptionId];
      }

      item["status"] = findStatus(answerObject, item);
    } else if (tempQuestionObject[index].questionGroup == "INFO-Subject") {
      let questionaireOption = tempQuestionObject[index].questionaireOption;
      let answerQuestionaireOptionId: any = [];
      let allSubjects = false;
      questionaireOption &&
        questionaireOption.forEach((el: any) => {
          answerObject.serviceProviderProductDetailList.map((item: any) => {
            if (item.questionaireOption && item.questionaireOption.questionaireOptionId == el.questionaireOptionId) {
              if (item.questionaireOption.option === "Other") {
                let splitText = item.otherText.split(",");

                splitText &&
                  splitText.forEach((el: any) => {
                    let obj = {
                      value: el,
                      label: el,
                      optionValue: item.questionaireOptionId,
                      othersField: true,
                      editQuestionId: item.serviceProviderProductDetailId,
                    };
                    answerQuestionaireOptionId.push(obj);
                  });
              } else {
                let obj = {
                  value: item.otherText,
                  label: item.otherText,
                  optionValue: item.questionaireOptionId,
                  othersField: false,
                  editQuestionId: item.serviceProviderProductDetailId,
                };
                answerQuestionaireOptionId.push(obj);
              }

              if (item.otherText === "All subjects") {
                allSubjects = true;
              }

              tempQuestionObject[index]["answerPayload"] = answerQuestionaireOptionId;
              tempQuestionObject[index]["answer"] = answerQuestionaireOptionId;

              if (allSubjects) {
                tempInitialValues.subject = "All subjects";
              } else {
                tempInitialValues.otherSubject = answerQuestionaireOptionId;
                tempInitialValues.subject = "Selected subjects";
              }
            }
          });
        });
    } else if (tempQuestionObject[index].code == "ageGroups") {
      let editQuestionId: any = [];
      let defaultAgeGroupedValue: any = [];
      let defaultAnswerPayload = tempQuestionObject[index].answerPayload;
      let questionaireOption = tempQuestionObject[index].questionaireOption;
      let answerQuestionaireOptionId: any = [];
      //Find default answer
      answerObject.serviceProviderProductAgeGroupList &&
        answerObject.serviceProviderProductAgeGroupList.map((innerItem: any) => {
          innerItem.serviceProviderProductAgeGroupId && editQuestionId.push(innerItem.serviceProviderProductAgeGroupId);

          questionaireOption &&
            questionaireOption.map((obj: any) => {
              if (obj.ageGroupId === innerItem.ageGroup.ageGroupId) {
                let valid = defaultAgeGroupedValue && defaultAgeGroupedValue.some((unique: any) => unique === obj.option);
                if (!valid) {
                  defaultAgeGroupedValue.push(obj.option);
                }
                if (isEdit) {
                  let ans = {
                    questionaireOptionId: innerItem.ageGroup.ageGroupId,
                    answerValue: obj.option,
                    editQuestionId: innerItem.serviceProviderProductAgeGroupId,
                  };
                  answerQuestionaireOptionId.push(ans);
                }
              }
            });
        });

      // Answer Mapping
      tempInitialValues.ageGroups = defaultAgeGroupedValue;

      tempQuestionObject[index].answer = defaultAgeGroupedValue;
      tempQuestionObject[index]["answerPayload"] = answerQuestionaireOptionId;

      if (defaultAnswerPayload) {
        tempQuestionObject[index]["answerPayload"] = [...defaultAnswerPayload, answerQuestionaireOptionId];
      } else {
        tempQuestionObject[index]["answerPayload"] = [...answerQuestionaireOptionId];
      }

      item["status"] = findStatus(answerObject, item);
    } else if (tempQuestionObject[index].code == "languages") {
      let answer: any = [];
      let editQuestionId: any = [];
      let defaultAnswerPayload = tempQuestionObject[index].answerPayload;
      let answerQuestionaireOptionId: any = [];
      let isActiveOther = false;
      answerObject.serviceProviderProductDetailList.forEach((innerItem: any) => {
        let optionGroup = innerItem.questionaireOption && innerItem.questionaireOption.optionGroup;
        if (optionGroup === "otherLanguages" || optionGroup === "languages") {
          innerItem.serviceProviderProductDetailId && editQuestionId.push(innerItem.serviceProviderProductDetailId);
          let answerOption = innerItem.questionaireOption.option;
          answer.push(answerOption);

          if (answerOption.toLowerCase() === "other") {
            isActiveOther = true;
          }
          if (isEdit) {
            let ans = {
              questionaireOptionId: innerItem.questionaireOptionId,
              answerValue: innerItem.questionaireOption.option,
              editQuestionId: innerItem.serviceProviderProductDetailId,
              otherText: innerItem.otherText,
              isActiveOther: isActiveOther,
            };
            answerQuestionaireOptionId.push(ans);
          }
        }
        if (defaultAnswerPayload) {
          tempQuestionObject[index]["answerPayload"] = [...defaultAnswerPayload, answerQuestionaireOptionId];
        } else {
          tempQuestionObject[index]["answerPayload"] = [...answerQuestionaireOptionId];
        }
      });
      tempInitialValues.languages = answer;
      tempQuestionObject[index].answer = answer;

      item["status"] = findStatus(answerObject, item);
    } else if (tempQuestionObject[index].code == "yearLaunched") {
      answerObject.serviceProviderProductDetailList.forEach((innerItem: any) => {
        let optionGroup = innerItem.questionaireOption && innerItem.questionaireOption.optionGroup;
        if (optionGroup === "Year") {
          let answer = innerItem;
          tempQuestionObject[index].answer = {
            year: answer.otherText,
            yearMoment: moment(answer.otherText),
          };
          tempQuestionObject[index]["editQuestionId"] = innerItem.serviceProviderProductDetailId;
          tempInitialValues.yearLaunched = answer.otherText ? moment(answer.otherText) : null;
          item["status"] = findStatus(answerObject, item);
        }
      });
    } else if (tempQuestionObject[index].code == "userBase") {
      let editQuestionId: any = [];
      answerObject.serviceProviderProductDetailList.forEach((innerItem: any) => {
        let optionGroup = innerItem.questionaireOption && innerItem.questionaireOption.optionGroup;
        if (optionGroup === "UserCount") {
          tempQuestionObject[index].answer = innerItem.otherText;
          tempInitialValues.userBase = innerItem.otherText;
          tempQuestionObject[index]["editQuestionId"] = innerItem.serviceProviderProductDetailId;
          item["status"] = findStatus(answerObject, item);
        }
      });
    } else if (tempQuestionObject[index].code == "supportedPlatform") {
      let editQuestionId: any = [];
      let answer: any = [];
      let defaultAnswerPayload = tempQuestionObject[index].answerPayload;
      let answerQuestionaireOptionId: any = [];
      answerObject.serviceProviderProductDetailList.forEach((innerItem: any) => {
        let optionGroup = innerItem.questionaireOption && innerItem.questionaireOption.optionGroup;

        if (optionGroup === "Desktop" || optionGroup === "Mobile" || optionGroup === "Others") {
          answer.push(innerItem.questionaireOption.option);
          innerItem.serviceProviderProductDetailId && editQuestionId.push(innerItem.serviceProviderProductDetailId);
          if (isEdit) {
            let ans = {
              questionaireOptionId: innerItem.questionaireOptionId,
              answerValue: innerItem.questionaireOption.option,
              editQuestionId: innerItem.serviceProviderProductDetailId,
            };
            answerQuestionaireOptionId.push(ans);
          }
        }
      });
      if (defaultAnswerPayload) {
        tempQuestionObject[index]["answerPayload"] = [...defaultAnswerPayload, answerQuestionaireOptionId];
      } else {
        tempQuestionObject[index]["answerPayload"] = [...answerQuestionaireOptionId];
      }
      tempQuestionObject[index].answer = answer;
      tempInitialValues.supportedPlatform = answer;
      let defaultEditQuestionId = tempQuestionObject[index].editQuestionId;
      if (defaultEditQuestionId) {
        tempQuestionObject[index]["editQuestionId"] = [...defaultEditQuestionId, editQuestionId];
      } else {
        tempQuestionObject[index]["editQuestionId"] = [...editQuestionId];
      }

      if (editQuestionId) return (tempQuestionObject[index]["editQuestionId"] = editQuestionId);

      item["status"] = findStatus(answerObject, item);
    } else if (tempQuestionObject[index].code == "images") {
      answerObject.serviceProviderProductDetailList.forEach((innerItem: any) => {
        let optionGroup = innerItem.otherText;
        if (optionGroup === "productImage") {
          let obj: any = {
            filePath: imageBaseUrl + innerItem.filePath,
            ...(innerItem.serviceProviderProductDetailId && { editQuestionId: innerItem.serviceProviderProductDetailId }),
          };
          tempQuestionObject[index]["editQuestionId"] = innerItem.serviceProviderProductDetailId;
          tempQuestionObject[index].answer = tempQuestionObject[index].answer ? [...tempQuestionObject[index].answer, obj] : [obj];
          tempInitialValues.images = innerItem.filePath;
          item["status"] = findStatus(answerObject, item);
        }
      });
    } else if (tempQuestionObject[index].code == "video") {
      answerObject.serviceProviderProductDetailList.forEach((innerItem: any) => {
        let optionGroup = innerItem.otherText;
        if (optionGroup === "promotionalVideo") {
          let obj: any = {
            filePath: imageBaseUrl + innerItem.filePath,
            ...(innerItem.serviceProviderProductDetailId && { editQuestionId: innerItem.serviceProviderProductDetailId }),
          };
          tempQuestionObject[index]["editQuestionId"] = innerItem.serviceProviderProductDetailId;
          tempQuestionObject[index].answer = tempQuestionObject[index].answer ? [...tempQuestionObject[index].answer, obj] : [obj];
          tempInitialValues.video = innerItem.filePath;
          item["status"] = findStatus(answerObject, item);
        }
      });
    } else if (tempQuestionObject[index].code == "demoVideo") {
      answerObject.serviceProviderProductDetailList.forEach((innerItem: any) => {
        let optionGroup = innerItem.otherText;
        if (optionGroup === "demoVideo") {
          let obj: any = {
            filePath: imageBaseUrl + innerItem.filePath,
            ...(innerItem.serviceProviderProductDetailId && { editQuestionId: innerItem.serviceProviderProductDetailId }),
          };
          tempQuestionObject[index]["editQuestionId"] = innerItem.serviceProviderProductDetailId;
          tempQuestionObject[index].answer = tempQuestionObject[index].answer ? [...tempQuestionObject[index].answer, obj] : [obj];
          tempInitialValues.video = innerItem.filePath;
          item["status"] = findStatus(answerObject, item);
        }
      });
    } else if (tempQuestionObject[index].code == "logo") {
      tempQuestionObject[index].answer = {
        filePath: imageBaseUrl + answerObject.productLogoPath,
      };
      tempInitialValues.logo = answerObject.productLogoPath;
      item["status"] = findStatus(answerObject, item);
    } else if (tempQuestionObject[index].code == "caseStudy") {
      let editQuestionId: any = null;
      answerObject.serviceProviderProductDetailList.forEach((innerItem: any) => {
        let optionGroup = innerItem.otherText;
        if (optionGroup === "caseStudy") {
          let url = innerItem.filePath;
          let filename = url.substring(url.lastIndexOf("/") + 1);
          tempQuestionObject[index].answer = {
            filePath: innerItem.filePath,
            name: filename,
          };
          tempInitialValues.caseStudy = innerItem.filePath;
          tempQuestionObject[index]["editQuestionId"] = innerItem.serviceProviderProductDetailId;
          item["status"] = findStatus(answerObject, item);
        }
      });
    } else if (tempQuestionObject[index].code == "productCertificate") {
      let editQuestionId: any = null;
      answerObject.serviceProviderProductDetailList.forEach((innerItem: any) => {
        let optionGroup = innerItem.otherText;
        if (optionGroup === "productCertificate") {
          let url = innerItem.filePath;
          let filename = url.substring(url.lastIndexOf("/") + 1);
          tempQuestionObject[index].answer = {
            filePath: innerItem.filePath,
            name: filename,
          };
          tempInitialValues.productCertificate = innerItem.filePath;
          tempQuestionObject[index]["editQuestionId"] = innerItem.serviceProviderProductDetailId;
          item["status"] = findStatus(answerObject, item);
        }
      });
    } else if (tempQuestionObject[index].code == "testimonials") {
      let arrObj = answerObject.serviceProviderProductTestimonial;
      let arrObjLength = arrObj && arrObj.length;
      let method: string = arrObjLength === 0 ? "true" : "false";
      cypherService.setSessionStorage("testimonialMethod", method);
      let defaultAns = tempQuestionObject[index].answer;
      let answer: any = [];
      arrObj &&
        arrObj.forEach((innerItem: any) => {
          let obj = {
            name: innerItem.name,
            designation: innerItem.designation,
            institutionName: innerItem.institutionName,
            uploadContent: innerItem.otherText && innerItem.otherText,
            type: fileType(innerItem),
            email: innerItem.email,
            approved: innerItem.approved,
            files: null,
            filePath: createFilePath(innerItem),
            fileName: "",
            currentStatus: "update",
            serviceProviderProductTestimonialId: innerItem.serviceProviderProductTestimonialId,
          };

          answer.push(obj);
          item["status"] = findStatus(answerObject, item);
        });

      if (defaultAns) {
        tempQuestionObject[index].answer = [...defaultAns, ...answer];
      } else {
        tempQuestionObject[index].answer = [...answer];
      }
    }
  });
  setOthersInitialValues(tempInitialValues);
  return tempQuestionObject;
};

const createFilePath = (pathLink: any) => {
  let currentFileType = fileType(pathLink);
  if (currentFileType === "video") {
    let VideoFilepath: string = "";
    if (pathLink.filePath || pathLink.videoLink) {
      VideoFilepath = pathLink.filePath ? imageBaseUrl + pathLink.filePath : pathLink.videoLink;
    }
    return VideoFilepath;
  } else {
    return imageBaseUrl + pathLink.filePath;
  }
};

const fileType = (innerItem: any) => {
  let filePath = innerItem.filePath && innerItem.filePath.split(".");
  if (innerItem.otherText) {
    return "text";
  } else if (filePath && filePath.includes("pdf")) {
    return "pdf";
  } else {
    return "video";
  }
};

export const getPlaceHolder = (data: any) => {
  if (data.code == "companyName") {
    return "Enter company name";
  } else if (data.code == "companyLocation") {
    return "Select location";
  } else if (data.code == "emailId") {
    return "Enter email id";
  } else if (data.code == "personName") {
    return "Enter name";
  } else if (data.code == "emirate") {
    return "Select emirate";
  }
};

export const productsPlaceHolder = (data: any) => {
  if (data == "languages") {
    return "Select languages";
  } else if (data == "supportedPlatform") {
    return "Select your product effectively";
  } else if (data == "ageGroups") {
    return "Select age";
  } else if (data == "curricula") {
    return "Select other curricula";
  } else if (data == "subject") {
    return "Select Subject";
  }
};
