import { Table, Button, Input, notification, Spin, Tooltip, Modal, Checkbox } from 'antd';
import { CloseOutlined, CheckOutlined, EyeOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react';
import EndpointService from '../../utilities/constants/endpointService';
import HTTPService from '../../service/HTTPService';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';


const CampaignsTable = () => {

    const [customerList, setCustomerList] = useState<any>([]);
    const [checkedReason, setCheckedReason] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [start, setStart] = useState<number>(0);
    const [limit] = useState<number>(10);
    const [searchText, setSearchText] = useState<string>('');
    const [campaignList, setCampaignList] = useState<any>([]);
    const [totalData, setTotalData] = useState<number>(0);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalLoader, setModalLoader] = useState<boolean>(false);
    const [audienceLoader, setAudienceLoader] = useState<boolean>(false);
    const [rejectReason, setRejectReason] = useState<string>('');
    const [fieldError, setFieldError] = useState<string>('');
    const [selectedRejectItem, setSelectedRejectItem] = useState<any>(null);
    const [rejectReasonList] = useState<any>([
        {
            value: 'This campaign contains Inappropriate content',
        },
        {
            value: 'This campaign is irrelevant to your product',
        },
        {
            value: 'This campaign violates our terms & conditions',
        },
        {
            value: 'The content of this campaign is vague',
        }
    ])

    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        getCampaignList(start, limit, searchText);
    }, [])

    const getCustomerList = (data: any) => {
        setAudienceLoader(true);
        let mapUrl: string = EndpointService.getCampaignCustomerListUrl + `${data.serviceProviderEmailCampaignId}/customer-list`;
        HTTPService.get(mapUrl, {}).then(response => {
            const getStatus = response.status.success.toLowerCase();
            if (getStatus === 'success') {
                let tempCustomerList: any = [];
                response.data.forEach((item: any) => {
                    let tempData: any = {};
                    tempData['name'] = item.name;
                    tempData['emailAddress'] = item.email;
                    tempCustomerList.push(tempData);
                })
                setCustomerList([...tempCustomerList]);
                setAudienceLoader(false);
            }
            if (getStatus === 'fail') {
                setAudienceLoader(false);
                notification.error({
                    message: '',
                    description: response.data.status.message,
                    placement: 'top'
                })
            }
        }).catch(err => {
            setAudienceLoader(false);
            notification.error({
                message: '',
                description: err.response.data.status.message,
                placement: 'top'
            })
        });
    }

    const getCampaignType = (campaignType: any) => {
        if (campaignType == 'WarmLead') {
            return "Warm Leads"
        }
        else if (campaignType == 'HotLead') {
            return "Hot Leads"
        }
        else {
            return "New School"
        }
    }

    const getCampaignList = (startValue: any, limitValue: any, searchTextValue: string) => {
        let mapUrl: string = EndpointService.getEmailCampaignListUrl + `?limit=${limitValue}&start=${startValue}`;
        if (searchText != '') {
            mapUrl = mapUrl + `&search=${searchTextValue}`;
        }
        setLoader(true)
        HTTPService.get(mapUrl, {}).then(response => {
            const Res = response;
            const getStatus = response.status.success.toLowerCase();
            if (getStatus === 'success') {
                let tempCampaignList: any = [];
                response.data.forEach((item: any) => {
                    let tempData: any = {};
                    tempData['campaignName'] = item.emailCampaignName;
                    tempData['sender'] = item.serviceProviderName;
                    tempData['emailSubject'] = item.emailSubject;
                    tempData['serviceProviderEmailCampaignId'] = item.serviceProviderEmailCampaignId;
                    tempData['serviceProviderProductId'] = item.serviceProviderProductId;
                    tempData['emailBody'] = "https://storage.googleapis.com/" + item.emailBody;
                    let lastIndex = item.emailBody.lastIndexOf('/')
                    tempData['audienceName'] = `${item.serviceProviderProductName}-${getCampaignType(item.campaignType)}`;
                    tempData['emailDesign'] = item.emailBody.substr(lastIndex + 1);
                    // tempData['requestDate'] = moment(item.emailDate).format("DD/MM/YYYY");
                    tempData['endDate'] = moment(item.emailDate).format("DD/MM/YYYY");
                    tempData['status'] = item.status;
                    tempData['startDate'] = moment(item.emailDate).format("DD/MM/YYYY");
                    tempData['campaignType'] = item.campaignType ? item.campaignType : 'NewSchool';
                    tempCampaignList.push(tempData);
                })
                setTotalData(response.totalResults)
                setCampaignList([...tempCampaignList]);
                setLoader(false);
            }
            if (getStatus === 'fail') {
                setLoader(false);
                notification.error({
                    message: '',
                    description: Res.data.status.message,
                    placement: 'top'
                })
            }
        }).catch(err => {
            setLoader(false)
            notification.error({
                message: '',
                description: err.response.data.status.message,
                placement: 'top'
            })
        });
    }

    const changeText = (event: any) => {
        setSearchText(event.target.value);
        getCampaignList(start, limit, event.target.value);
    }

    const pageChange = (page: any) => {
        let startValue: number = (page * 10) - 10;
        setStart(startValue);
        getCampaignList(startValue, limit, searchText);
    }

    const submitRejectReason = () => {
        if ((rejectReason && fieldError != '') || checkedReason.length != 0) {
            setModalLoader(true);
            statusChange(selectedRejectItem, 'Rejected')
        }
        else {
            setFieldError('Please enter your reason for rejection');
        }
    }

    const showModal = (data: any) => {
        setIsModalVisible(true);
        setAudienceLoader(true);
        getCustomerList(data);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const statusChange = (item: any, status: string) => {
        debugger;
        setLoader(true);
        let payload: any = {
            "status": status,
        }
        if (status == 'Rejected') {
            if (checkedReason.length != 0) {
                payload["rejectedComments"] = rejectReasonList[checkedReason].value;
            }
            else {
                payload["rejectedComments"] = rejectReason;
            }
            setFieldError('');
            setModalShow(false);
            setRejectReason('');
            let temp: any = []
            setCheckedReason([...temp])
        }
        let mapUrl: string = EndpointService.emailCamignStatusChangeUrl + `${item.serviceProviderEmailCampaignId}?serviceProviderEmailCampaignDto=${encodeURIComponent(JSON.stringify(payload))}`
        HTTPService.put(mapUrl, {}).then(response => {
            if (response.status.success.toLowerCase() === 'success') {
                setSelectedRejectItem(null);
                getCampaignList(start, limit, searchText);
                notification.success({
                    message: '',
                    description: response.status.message,
                    placement: 'top'
                })
            }
            if (response.status.success.toLowerCase() === 'fail') {
                setSelectedRejectItem(null);
                setLoader(false);
                notification.error({
                    message: '',
                    description: response.status.message,
                    placement: 'top'
                })
            }
        }).catch(err => {
            setLoader(false)
            setSelectedRejectItem(null);
            notification.error({
                message: '',
                description: err.response.data.status.message,
                placement: 'top'
            })
        });
    }

    const statusChangeToReject = (item: any) => {
        setModalShow(true);
        setSelectedRejectItem(item)
    }

    const getTemplatePreviewData = (item: any) => {
        let mapUrl: string = EndpointService.getCampignTemplatePreviewUrl + item.serviceProviderEmailCampaignId;
        setLoader(true);
        HTTPService.get(mapUrl, {}).then(response => {
            const getStatus = response.status.success.toLowerCase();
            if (getStatus === 'success') {
                setLoader(false);
                const win: any = window.open('about:blank', '_blank');
                win.document.write(response.data.html);
                win.focus();
            }
            if (getStatus === 'fail') {
                notification.error({
                    message: '',
                    description: response.data.status.message,
                    placement: 'top'
                })
            }
        }).catch(err => {
            notification.error({
                message: '',
                description: err.response.data.status.message,
                placement: 'top'
            })
        });
    }

    const onRejectModalClose = () => {
        setModalShow(false);
        setSelectedRejectItem(null)
    }


    const handleStatus = (element: any) => {
        debugger;
        let status = element !== "SentForApproval" ? true : false;

        return status ? (
            <div className="complete">
                <span>Approved </span>
            </div>
        ) : (
            <div className="in-complete">
                <span>Pending Approval </span>
            </div>
        );
    }
//   const sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    const columns: any = [
        {
            title: 'Sender',
            width: 200,
            dataIndex: 'sender',
            key: 'sender',
            onFilter: (value: any, record: any) => record.sender.indexOf(value) === 0,
            sorter: (a: any, b: any) => (a.sender && b.sender) && a.sender.localeCompare(b.sender),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Campaign Name',
            width: 200,
            dataIndex: 'campaignName',
            key: 'campaignName',
            onFilter: (value: any, record: any) => record.campaignName.indexOf(value) === 0,
            sorter: (a: any, b: any) => (a.campaignName && b.campaignName) && a.campaignName.localeCompare(b.campaignName),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Email Subject',
            dataIndex: 'emailSubject',
            key: 'emailSubject',
            width: 300,
            onFilter: (value: any, record: any) => record.emailSubject.indexOf(value) === 0,
            sorter: (a: any, b: any) => (a.emailSubject && b.emailSubject) && a.emailSubject.localeCompare(b.emailSubject),
            defaultSortOrder: 'descend',
        },
        // {
        //     title: 'Audience',
        //     dataIndex: 'audienceName',
        //     key: 'audienceName',
        //     width: 200,
        //     onFilter: (value: any, record: any) => record.audienceName.indexOf(value) === 0,
        //     sorter: (a: any, b: any) => (a.audienceName && b.audienceName) && a.audienceName.localeCompare(b.audienceName),
        //     defaultSortOrder: 'descend',
        //     render: (item: any, data: any) => (
        //         <Tooltip placement="top" title="View Audience">
        //             <p onClick={() => showModal(data)} className="cursor-pointer text-green-300 underline decoration-1">{item}</p>
        //         </Tooltip>
        //     )
        // },
        // {
        //     title: 'Request Date',
        //     dataIndex: 'requestDate',
        //     key: 'requestDate',
        //     width: 200,
        //     onFilter: (value: any, record: any) => record.requestDate.indexOf(value) === 0,
        //     sorter: (a: any, b: any) => (a.requestDate && b.requestDate) && a.requestDate.localeCompare(b.requestDate),
        //     defaultSortOrder: 'descend',
        // },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 150,
            onFilter: (value: any, record: any) => record.startDate.indexOf(value) === 0,
            sorter: (a: any, b: any) => (a.startDate && b.startDate) && a.startDate.localeCompare(b.startDate),
            defaultSortOrder: 'descend',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 150,
            onFilter: (value: any, record: any) => record.endDate.indexOf(value) === 0,
            sorter: (a: any, b: any) => (a.endDate && b.endDate) && a.endDate.localeCompare(b.endDate),
            defaultSortOrder: 'descend',
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 200,
            render: (element: any) => <div className="school-status">{handleStatus(element)}</div>,
            filters: [
                {
                    text: 'Approved',
                    value: 'Approved',
                },
                {
                    text: 'Pending Approval',
                    value: 'Pending Approval',
                },
            ],
            onFilter: (value: string, record: any) => record.status.indexOf(value) === 0,
        },
        {
            title: 'Action',
            key: 'operation',
            width: 150,
            render: (item: any, data: any) => (
                <div className="icon-btn-group flex">
                    <Tooltip placement="top" title="View">
                        <Button type="primary" className="table-btn-3" style={{cursor: item.status=="Approved" ? 'not-allowed' : 'pointer'}}  onClick={() => getTemplatePreviewData(item)}><EyeOutlined /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Accept">
                        <Button type="primary" className="table-btn-3" style={{cursor: item.status=="Approved" ? 'not-allowed' : 'pointer'}}  onClick={() => statusChange(item, 'Approved')}><CheckOutlined /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Reject">
                        <Button type="primary" className="table-btn-3" style={{cursor: item.status=="Approved" ? 'not-allowed' : 'pointer'}}  onClick={() => statusChangeToReject(item)}><CloseOutlined /></Button>
                    </Tooltip>
                </div>
            )
        },
    ];

    const customercolumns: any = [
        {
            title: 'Name',
            width: 170,
            dataIndex: 'name',
            key: 'name',
            onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
            sorter: (a: any, b: any) => (a.name && b.name) && a.name.localeCompare(b.name),
            defaultSortOrder: 'descend',
        },
        // {
        //     title: 'School',
        //     width: 200,
        //     dataIndex: 'School',
        //     key: 'School',
        //     onFilter: (value: any, record: any) => record.School.indexOf(value) === 0,
        //     sorter: (a: any, b: any) => (a.School && b.School) && a.School.localeCompare(b.School),
        //     defaultSortOrder: 'descend',
        // },
        {
            title: 'Email ID',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            width: 200,
            onFilter: (value: any, record: any) => record.emailAddress.indexOf(value) === 0,
            sorter: (a: any, b: any) => (a.emailAddress && b.emailAddress) && a.emailAddress.localeCompare(b.emailAddress),
            defaultSortOrder: 'descend',
        },
    ];

    const cancelAction = () => {
        setFieldError('');
        setModalShow(false);
        setRejectReason('');
        // selectedRejectItem(null);
        let temp: any = [];
        setCheckedReason([...temp]);
    }

    const onTextFieldChange = (event: any) => {
        setRejectReason(event);
        if (event) {
            setFieldError('');
        }
        else {
            setFieldError('Please enter your reason for rejection');
        }
    }

    const selectChange = (event: any) => {
        let tempData: any = [];
        tempData.push(event.target.value)
        setCheckedReason([...tempData])
    }

    return (
        <div className="view-table">
            {loader ? <Spin /> : null}
            {/* <div className="view-table-sorting">
                <div className="main view-table-search">
                    <Input placeholder="Search" value={searchText} onChange={(e) => changeText(e)} />
                </div>
            </div> */}
            <Table className='campaign-table' columns={columns} dataSource={campaignList} pagination={{ total: totalData, onChange: pageChange }} />
            <Modal
                centered
                visible={modalShow}
                onOk={() => setModalShow(false)}
                onCancel={() => setModalShow(false)}
                footer={null}
                className="product-summary-modal free-demo-modal reject-modal"
            >
                <>
                    {modalLoader ? <Spin /> : null}
                    <p className='!text-center'>Please select a reason from below</p>
                    <div className="description">
                        <Checkbox.Group className="checkbox-group" value={checkedReason} style={{ width: '100%' }}>
                            {
                                rejectReasonList.map((item: any, index: number) => (
                                    <Checkbox value={index} onChange={(e) => selectChange(e)}>{item.value}</Checkbox>
                                ))
                            }
                        </Checkbox.Group>
                        <TextArea name="description" rows={3} value={rejectReason} onChange={(e) => onTextFieldChange(e.target.value)}
                            placeholder="Enter your message here if you can't find an appropriate message in the above options" maxLength={1200} />
                        {fieldError ? <div className='error'>{fieldError}</div> : null}
                    </div>
                    <div className="modal-footer">
                        <div className="action-btns">
                            <Button className="btn-secondary fixed-width" onClick={() => cancelAction()}>Cancel</Button>
                            <Button type='primary' className="fixed-width" loading={false}
                                name="Submit" disabled={false} onClick={() => submitRejectReason()}>Submit</Button>
                        </div>
                    </div>
                </>
            </Modal>
            <Modal
                centered
                visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                footer={null}
                className="product-summary-modal audience-modal"
            >
                <>
                    {audienceLoader ? <Spin /> : null}
                    <div>
                        <Table columns={customercolumns} dataSource={customerList} scroll={{ y: 'calc(100vh - 280px)', x: 0 }} pagination={false} />
                    </div>
                    <div className="modal-footer !justify-end">
                        <Button type='primary' className="ok-btn" loading={false}
                            name="Submit" disabled={false} onClick={() => setIsModalVisible(false)}>OK</Button>
                    </div>
                </>
            </Modal>
        </div>
    );
}

export default CampaignsTable;
