import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { Button, Form, Input, Spin, notification, Row, Col } from 'antd';

import { Schema, IForgotOtpObj } from './loginSchema';
import EndpointService from '../../utilities/constants/endpointService';
import HTTPService from '../../service/HTTPService';
import { useNavigate } from 'react-router-dom';
import { cypherService } from '../../utilities/cypher/cypherService';
import logo from '../../assets/images/SolvEd.png'


const ForgotPwdOtp = () => {
  const [otpNumber, setOtpNumber] = useState('');
  const [resendOtpObj, setResendOtpObj] = useState('');
  const [loader, setLoader] = useState(false)
  const [bgImg, setBgImg] = useState('')
  const [otpObj, setOtpObj] = useState<IForgotOtpObj>({
    email: "",
    secretKey: "688340"
  });

  const handleChange = (otp: any) => {
    setOtpNumber(otp)
    setOtpObj({ ...otpObj, "secretKey": otp })
  };

  const navigate = useNavigate();

  useEffect(() => {
    const partyDiscriminator = JSON.parse(cypherService.getSessionStorage('partyDiscriminator') || '{}')
    setBgImg(partyDiscriminator)
    const email = cypherService.getSessionStorage('forgotPasswordEmail') || '';
    setResendOtpObj(email)
    setOtpObj({ ...otpObj, email })

  }, [])

  const onSubmit = () => {
    setLoader(true)
    setOtpObj({ ...otpObj, "secretKey": otpNumber })
    let mapUrl = EndpointService.forgotPwdValidateUrl
    HTTPService.post(mapUrl, otpObj).then(response => {
      const otpResponse = response.data;
      // localStorage.setItem('loginRes', JSON.stringify(setOtpObj));
      // const retriveLocal = localStorage.getItem('loginRes')
      // console.log(JSON.parse(retriveLocal || '{}'));
      // console.log('otpObj', otpResponse)
      const getStatus = response.status.success.toLowerCase();
      // console.log('getStatus', getStatus)
      if (getStatus == 'success') {
        setLoader(false)
        navigate("/passwordcreation");
      }
    }).catch(err => {
      // console.log(err)
      // throw err
      setLoader(false)
      notification.error({
        message: '',
        description: err.response.data.status.message,
        placement: 'topRight'
      })
    });
    // console.log(otpNumber);
    // console.log(otpObj)
  }

  const resendOtp = () => {
    setLoader(true)

    let payLoad = {
      email: resendOtpObj
    }

    let mapUrl = EndpointService.resendOtpUrl

    HTTPService.post(mapUrl, payLoad).then(response => {
      // console.log(response)
      const getStatus = response.status.success.toLowerCase();
      console.log('getStatus', getStatus)
      if (getStatus === 'success') {
        setLoader(false)
        navigate("/forgotpasswordotp");
      }
    }).catch(err => {
      // console.log(err)
      setLoader(false)
    })
  }





  return (

    <section className={bgImg == 'Customer' ? 'login-container customer' : 'login-container service-provider'}>
      <div className='login-logo'>
        <img src={logo} alt="logo" />
      </div>
      <Row gutter={32} justify="space-between" align="middle" className="login-row">
        <Col span={12} className="login-col forgot-col">
          <span className='welcome-text'>Forgot password? <br />Don't worry, help is at hand.</span>
        </Col>
        <Col span={12} className="forgot-col">
          <div className="login-card">
            {/* Loader */}
            {loader ? <Spin /> : null}
            <h2>Check your<br /> inbox to continue</h2>
            <h6>Please enter the <span className="text-green">6-digit security code</span> sent to</h6>
            <p className="text-skyBlue">{otpObj.email}</p>
            <h6 className="security">Security Code</h6>
            <OtpInput
              value={otpNumber}
              onChange={handleChange}
              numInputs={6}
              separator={<span className="m-2"></span>}
              className="otp-input font-sans font-bold"
            />
            <p className="security">Didn't receive a security code?<span onClick={() => resendOtp()} className="ml-2 text-skyBlue" >Send again</span></p>
            <div className="submit-btn"><Button block type="primary" onClick={() => onSubmit()}>NEXT</Button></div>
          </div>
        </Col>
      </Row>
    </section>

  );
};
export default ForgotPwdOtp;