import { Card, Slider } from "antd";
import { ErrorMessage } from "formik";
import { InputNumber } from "formik-antd";
import React from "react";
import QuestionHeader from "../../../schoolDetails/layout/QuestionHeader";

export default function FormControlRangeWithText(props: any) {
  const { item, questionList, setQuestionList, index, setFieldValue } = props;
  const handleOnChange = (event: any, questionList: any, setQuestionList: any, index: number, setFieldValue: any, name: string) => {
    let tempQuestionList = questionList;
    if (name === "userBase") {
      setFieldValue(name, event);
    }
    tempQuestionList[index].answer = event;
    setQuestionList([...tempQuestionList]);
  };
  return (
    <>
      <Card
        className="mb-5"
        title={
          <>
            <QuestionHeader answer={item.answer} question={item.question} />
          </>
        }
        bordered={false}

      >
        {/* Answer */}
        <div className="answer-field py-5 pb-9">
          <div className="ant-row block md:flex">
            <div className="w-3/4">
              <Slider className="range" min={500} step={500} max={1000000} value={item.answer} onChange={(value) => handleOnChange(value, questionList, setQuestionList, index, setFieldValue, item.code)} />
            </div>
            <div className="w-1/4">
              <InputNumber min={500} className="RangeTextInput ml-8" name={"userBase"} value={item.answer} onChange={(value) => handleOnChange(value, questionList, setQuestionList, index, setFieldValue, item.code)} />
            </div>
          </div>
          <div className="error">
            <ErrorMessage name={item.code} />
          </div>
        </div>
      </Card>
    </>
  );
}
