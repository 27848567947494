export default class EmiratesList {
  static emirates = [
    {
      name: " Abu Dhabi",
    },
    {
      name: " Dubai",
    },
    {
      name: " Sharjah",
    },
    {
      name: "Ajman",
    },
    {
      name: "Umm Al Quwain",
    },
    {
      name: "Ras Al Khaimah",
    },
    {
      name: "Fujairah",
    },
    {
      name: "Saudi Arabia",
    },
    {
      name: "Qatar",
    },
    {
      name: "Oman",
    },
    {
      name: "Kuwait",
    },
    {
      name: "Bahrain",
    },
  ];
}
