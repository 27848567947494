import React from 'react'
import { Button } from 'antd';
import Header from '../header/Header';
import { useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { GiGraduateCap } from 'react-icons/gi';
import { RiSuitcase3Fill } from 'react-icons/ri';

function Dashboard() {

  const navigate = useNavigate();

  const addServiceProvider = () => { navigate('/addserviceprovider') }
  return (

    <div className="dashboard">
      <div className="dashboard-main">
        <div className="dashboard-inner">
          <div>
            <Button type="primary" className="btn-colored page"><GiGraduateCap className="mr-1" />Add School</Button>
          </div>
          <div className="mt-8">
            <button className="btn-school" onClick={() => navigate('/viewdata')}>View Schools <BsArrowRight /></button>
          </div>
        </div>
        <div className="dashboard-inner">
          <Button type="primary" onClick={() => { addServiceProvider() }} className="btn-colored link"><RiSuitcase3Fill className="mr-1" />Add Service Provider</Button>
          <div className="mt-8">
            <button className="btn-service" onClick={() => navigate('/viewdata')}>View Service Providers <BsArrowRight /></button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Dashboard