import React, { useEffect, useState } from "react";
import { Button, Breadcrumb, Modal, Input, message, Spin, Calendar, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";


export default function NotificationsWigets() {
  let reduxStore = useSelector((state: RootStateOrAny) => state);
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    let messageData = reduxStore.notifications && reduxStore.notifications.data && reduxStore.notifications.data;
    setData(messageData)

  }, [reduxStore])

  return <div>
    <div className='dashboard-card notification-card'>
      {loader ? <Spin /> : null}
      <div className='card-header notice-head'>
        <p>Notifications</p>
        <p className="all-notification" onClick={() => navigate('/notifications/all')}>View all </p>
      </div>
      {data && data.map((item: any) => (
        <div className="notification-content">
          <div className="status">
            <p> <span></span></p>
          </div>
          <div className="notification-msg">
            <p>{item.description}</p>
            <span>Today at 9:42 AM</span>
          </div>
        </div>
      ))}

    </div>
  </div>;
}
