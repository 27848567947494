import { CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import Card from "antd/lib/card/Card";
import { ErrorMessage } from "formik";
import React from "react";
import QuestionHeader from "../../../schoolDetails/layout/QuestionHeader";
import ImagePreview from "../ImagePreview";
import VideoPreview from "../VideoPreview";
import { handleMediaChange } from "./helper";

export default function FormControlVideo(props: any) {
  const { item, questionList, setQuestionList, index, setFieldValue, setErrors, errors, editInit } = props;
  const limitRange = () => {
    return item.code === "demoVideo" ? 1 : 2;
  };
  return (
    <>
      <div className="card-inner mb-4">
        <Card
          className="mb-5"
          title={
            <>
              <QuestionHeader answer={item.answer} question={item.question} />
              {/* <div className="h-60 overflow-hidden overflow-y-auto">
                <pre className="text-xs">{JSON.stringify(item, null, 1)}</pre>
              </div> */}
            </>
          }
          bordered={false}
          style={{ width: "100%" }}
        >
          {/* Answer */}
          <div className="answer-field">
            <div className="dropFile">
              {item.answer && item.answer.length > 0 && (
                <>
                  <VideoPreview item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} editInit={editInit} />
                </>
              )}

              {item.answer && item.answer.length < limitRange() ? (
                <label>
                  <div className="imageUpload-section">
                    <div className="uploadedImage-preview">
                      <div className="imageUpload-div">
                        <PlusOutlined className="text-green" />
                      </div>
                    </div>
                    <input
                      className="FileUpload1"
                      id={item.code}
                      type="file"
                      onChange={(value) => {
                        handleMediaChange(value, questionList, setQuestionList, index, setFieldValue, item.code, item, setErrors, errors);
                      }}
                    />
                  </div>
                </label>
              ) : null}
            </div>
            {errors.video && item.code === "video" && <div className="error">{errors.video} </div>}
            {errors.demoVideo && item.code === "demoVideo" && <div className="error">{errors.demoVideo} </div>}
          </div>
        </Card>
      </div>
    </>
  );
}
