import { useEffect, useState } from "react";
import { Button, Col, Row, Spin, Input, Form, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import { ILogin, Schema } from "./loginSchema";
import HTTPService from "../../service/HTTPService";
import { cypherService } from "../../utilities/cypher/cypherService";
import { notification } from "antd";
import EndpointService from "../../utilities/constants/endpointService";

import logo from "../../assets/images/SolvEd.png";

const Login = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [bgImg, setBgImg] = useState("");
  const [keepMeSignedIn, setKeepMeSignedIn] = useState<any>(false);
  const [initLoginFormValue, setInitLoginFormValue] = useState<ILogin>({
    email: "",
    password: "",
  });

  const onSubmit = (values: any, loginType: string) => {
    setLoader(true);
    const mapUrl = EndpointService.loginUrl;
    let payLoad = values;
    HTTPService.post(mapUrl, payLoad)
      .then((response) => {
        const loginRes = response;
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success" && loginRes.data !== null) {
          setLoader(false);
          cypherService.setSessionStorage("appToken", loginRes.token);
          cypherService.setLocalStorage("userId", loginRes.data.userId);
          cypherService.setLocalStorage("customerId", loginRes.data.userId);
          cypherService.setLocalStorage("email", loginRes.data.email);
          cypherService.setLocalStorage("userName", loginRes.data.name);
          let tempUserName: any = loginRes.data.name.split(" ");
          let tempAvatar: any = "";
          tempUserName.forEach((item: any) => {
            tempAvatar = tempAvatar + item[0].toUpperCase();
          });
          cypherService.setLocalStorage("userNameAvatar", tempAvatar);

          if (keepMeSignedIn) {
            cypherService.setLocalStorage("appToken", loginRes.token);
            cypherService.setLocalStorage("isLoggedIn", true);
          }
          navigate("/viewdata");
        }
        if (getStatus === "fail" || (loginRes.data == null && getStatus === "success")) {
          setLoader(false);
          notification.error({
            message: "",
            description: loginRes.status.message,
            placement: "topRight",
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        notification.error({
          message: "",
          description: err.response.data.status.message,
          placement: "topRight",
        });
        // throw err
      });
  };

  const handleKeepMeSignedIn = (e: any) => {
    setKeepMeSignedIn(e.target.checked);
  };

  return (
    <section className="login-container">
      <div className="login-logo">
        <img src={logo} alt="logo" />
      </div>
      <Row gutter={32} justify="space-between" align="middle" className="login-row">
        <Col span={12} className="login-col">
          <span className="welcome-text">The SolvEd platform offers unmatched insights into the needs of educational institutions</span>
        </Col>
        <Col span={12} className="login-col">
          <div className="login-card">
            {/* Loader */}
            {loader ? <Spin /> : null}
            <h2>Welcome, Admin!</h2>
            <h6>Sign in to continue</h6>
            <Formik
              initialValues={initLoginFormValue}
              validationSchema={Schema.loginSchema}
              onSubmit={(values, actions) => {
                onSubmit(values, "manualLogin");
              }}
            >
              {({ handleSubmit, setFieldValue, values, errors, getFieldProps, handleChange }) => {
                return (
                  <Form layout="vertical" autoComplete="off" className="login-form">
                    <Form.Item name="email" className="" label="Work Email">
                      <Input name="email" autoComplete="off" className="inputs rounded-lg h-12" onChange={handleChange} placeholder="Enter your work email" />
                      <ErrorMessage name="email">{(msg) => <div className="error">{msg}</div>}</ErrorMessage>
                    </Form.Item>
                    <Form.Item name="password" className="password" label="Password">
                      <Input.Password name="password" autoComplete="off" className="inputs rounded-lg h-12" onChange={handleChange} placeholder="Enter your password" />
                      <ErrorMessage name="password">{(msg) => <div className="error">{msg}</div>}</ErrorMessage>
                    </Form.Item>

                    <div className="flex flex-wrap align-center justify-between mb-4">
                      <div className="keep-me-sign-in">
                        <Checkbox checked={keepMeSignedIn} onChange={(e) => handleKeepMeSignedIn(e)}>
                          Keep me signed in
                        </Checkbox>
                      </div>

                      <div className="forgot-pwd-link pb-2 !-top-0">
                        <Button
                          type="link"
                          onClick={() => {
                            navigate("../forgotpassword");
                          }}
                        >
                          Forgot password?
                        </Button>
                      </div>
                    </div>

                    <div className="submit-btn pb-5">
                      <Button block htmlType="submit" type="primary" onClick={() => handleSubmit()}>
                        SIGN IN
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    </section>
  );
};
export default Login;
