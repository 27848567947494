import React, { useEffect, useState } from "react";
import { Button, Checkbox, Modal, Tabs } from "antd";
import { Formik } from "formik";
import { SubmitButton, Form } from "formik-antd";
import TabSupportImage from "./TabSupportImage";
import HTTPService from "../../../service/HTTPService";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import EndpointService from "../../../utilities/constants/endpointService";
import { cypherService } from "../../../utilities/cypher/cypherService";
import { debug } from "util";

const ProductsMyPriorities = (props: any) => {
  const { defaultList, handleChange, isEdit, questionList, setQuestionList, globalIndex, handlePriorities } = props;
  const TabImages: any = {
    0: TabSupportImage.TAB1,
    1: TabSupportImage.TAB2,
    2: TabSupportImage.TAB3,
    3: TabSupportImage.TAB4,
    4: TabSupportImage.TAB5,
  };

  const { TabPane } = Tabs;
  const [tab, setTab] = useState("0");
  const [initialValues, setInitailValues] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [priorityObject, setPriorityObject] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);


  useEffect(() => {
    cypherService.setLocalStorage("removePriorityArray", []);
    getTabWithMultiSelectQuestionDetails();
  }, [props.defaultList]);

  const getTabWithMultiSelectQuestionDetails = (): any => {
    setLoader(true);
    let mapUrl = EndpointService.getProductCategoryOneOfTheAssessmentQuestionUrl;
    HTTPService.get(mapUrl).then((response: any) => {
      let tabWithMultiSelectQuestionData = response.data;
      let tempInitialValues = initialValues;
      tabWithMultiSelectQuestionData.map((item: any, index: number) => {
        let objectName: string = item.parentProductCategoryName;
        tempInitialValues[objectName] = [];
      });

      tabWithMultiSelectQuestionData.forEach((element: any, index: number) => {
        let tempCategory = element.subcategories;
        let createAnswers: any = [];
        let tempSubCategory = tempCategory.map((item: any) => {
          defaultList &&
            defaultList.forEach((ele: any) => {
              ele.subcategories &&
                ele.subcategories.forEach((sub: any) => {
                  if (sub.subProductCategoryId === item.subProductCategoryId) {
                    let obj = {
                      ...ele,
                      productCategoryId: item.subProductCategoryId,
                      label: item.subProductCategoryName,
                      value: item.subProductCategoryId,
                    };

                    createAnswers = [...createAnswers, obj];
                  }
                });
            });

          return {
            ...item,
            label: item.subProductCategoryName,
            value: item.subProductCategoryId,
          };
        });

        element.subcategories = tempSubCategory;
        element.answer = [...createAnswers];
      });
      setInitailValues(tempInitialValues);
      setPriorityObject(tabWithMultiSelectQuestionData);
    });
  };



  const handleCount = (item: any) => {
    cypherService.setLocalStorage("isActivePriorities", true)
    let JSONPrioritiesCount = cypherService.getLocalStorage("prioritiesCount")
    let selectedPriorities: any = cypherService.getLocalStorage("selectedPriorities")
    let getPrioritiesCount = JSONPrioritiesCount
    if (item.parentProductCategoryName === "Leadership & Management") {

      if (getPrioritiesCount) {
        let priorityObjectAnswerList: any = item.answer
        let objectValue = { ...getPrioritiesCount, leadershipManagement: priorityObjectAnswerList.length }
        cypherService.setLocalStorage("prioritiesCount", objectValue)
        let selectedLabel: any = []
        priorityObjectAnswerList && priorityObjectAnswerList.forEach((el: any) => {
          selectedLabel.push(el.label)
        })
        cypherService.setLocalStorage("selectedPriorities", { ...selectedPriorities, leadershipManagement: [...selectedLabel] })
      }

    } else if (item.parentProductCategoryName === "Assessment") {
      if (getPrioritiesCount) {
        let priorityObjectAnswerList: any = item.answer
        let objectValue = { ...getPrioritiesCount, assessment: priorityObjectAnswerList.length }
        cypherService.setLocalStorage("prioritiesCount", objectValue)
        let selectedLabel: any = []
        priorityObjectAnswerList && priorityObjectAnswerList.forEach((el: any) => {
          selectedLabel.push(el.label)
        })
        cypherService.setLocalStorage("selectedPriorities", { ...selectedPriorities, assessment: [...selectedLabel] })
      }
    }
    else if (item.parentProductCategoryName === "Curriculum") {
      if (getPrioritiesCount) {
        let priorityObjectAnswerList: any = item.answer
        let objectValue = { ...getPrioritiesCount, curriculum: priorityObjectAnswerList.length }
        cypherService.setLocalStorage("prioritiesCount", objectValue)
        let selectedLabel: any = []
        priorityObjectAnswerList && priorityObjectAnswerList.forEach((el: any) => {
          selectedLabel.push(el.label)
        })
        cypherService.setLocalStorage("selectedPriorities", { ...selectedPriorities, curriculum: [...selectedLabel] })
      }
    }
    else if (item.parentProductCategoryName === "Personal & Social Development") {
      if (getPrioritiesCount) {
        let priorityObjectAnswerList: any = item.answer
        let objectValue = { ...getPrioritiesCount, personalAndSocialDevelopment: priorityObjectAnswerList.length }
        cypherService.setLocalStorage("prioritiesCount", objectValue)
        let selectedLabel: any = []
        priorityObjectAnswerList && priorityObjectAnswerList.forEach((el: any) => {
          selectedLabel.push(el.label)
        })
        cypherService.setLocalStorage("selectedPriorities", { ...selectedPriorities, personalAndSocialDevelopment: [...selectedLabel] })
      }
    }
    else if (item.parentProductCategoryName === "Planning & Teaching") {
      if (getPrioritiesCount) {
        let priorityObjectAnswerList: any = item.answer
        let objectValue = { ...getPrioritiesCount, planningAndTeaching: priorityObjectAnswerList.length }
        cypherService.setLocalStorage("prioritiesCount", objectValue)
        let selectedLabel: any = []
        priorityObjectAnswerList && priorityObjectAnswerList.forEach((el: any) => {
          selectedLabel.push(el.label)
        })
        cypherService.setLocalStorage("selectedPriorities", { ...selectedPriorities, planningAndTeaching: [...selectedLabel] })
      }
    }
  }



  const totalNumberSelection = (item: any) => {
    handleCount(item)
    let priorityObjectAnswerList: any = item.answer;
    return priorityObjectAnswerList.length;
  };

  const setTabValues = (event: any, item: any, answer: any, index: number) => {
    cypherService.setLocalStorage("isChecked", true)
    let tempQuestionList = questionList;
    let allPriorityObject = priorityObject;
    let priorityObjectList: any = allPriorityObject[index];
    let priorityObjectAnswerList: any = priorityObjectList.answer;

    let obj = {
      productCategoryId: item.subProductCategoryId,
      label: item.subProductCategoryName,
      value: item.subProductCategoryId,
    };
    if (priorityObjectAnswerList.length > 0) {
      let isValid = priorityObjectAnswerList.some((e: any) => e.value === item.value);
      if (isValid) {
        let filterData = priorityObjectAnswerList.filter((ans: any) => {
          if (ans.value !== item.value) {
            return ans;
          }
        });
        priorityObjectList.answer = [...filterData];
        if (filterData.length === 0) {
          handlePriorities(true);
        }
      } else {
        priorityObjectList.answer = [...priorityObjectAnswerList, obj];
      }
    } else {
      handlePriorities(false);
      priorityObjectList.answer = [...priorityObjectAnswerList, obj];
    }

    let currentAnswer = priorityObjectList.answer;
    priorityObject &&
      priorityObject.map((item: any, InnerIndex: number) => {
        if (allPriorityObject[index]) {
          allPriorityObject[index].answer = [...currentAnswer];
        }
      });

    // tempQuestionList[globalIndex].answer = [...currentAnswer];
    // setQuestionList([...tempQuestionList]);

    let setValue = [];
    allPriorityObject &&
      allPriorityObject.forEach((item: any) => {
        if (item.answer.length > 0) {
          setValue.push(item.parentProductCategoryId);
        }
      });

    setPriorityObject([...allPriorityObject]);

    cypherService.setLocalStorage("priorityObject", allPriorityObject);

    let removeArrayList = cypherService.getLocalStorage("removePriorityArray");
    let removeArray: any = removeArrayList && removeArrayList ? removeArrayList : [];
    if (!event.target.checked) {
      let isPresent = removeArray.includes(item.subProductCategoryId);
      if (!isPresent) {
        removeArray.push(item.subProductCategoryId);
        cypherService.setLocalStorage("removePriorityArray", removeArray);
      }
    }
  };

  const checkedValue = (option: any, subcategories: any) => {
    let result = false;
    subcategories &&
      subcategories.map((item: any) => {
        if (item.value === option.value) {
          result = true;
        }
      });
    return result;
  };

  return (
    <>
      <div className="Tab-with-MultiSelect-main w-full pb-7" data-swiper-parallax-y="-150" data-swiper-parallax-duration="1000">
        {/* <pre>{JSON.stringify(priorityObject, null, 1)}</pre> */}
        <Tabs className="Tab-with-MultiSelect" activeKey={tab} onTabClick={(e) => setTab(e)}>
          <TabPane tab="" key="10">
            <div className="TabContentSection">
              <div>
                <h3>Help us to understand your specific priorities better.</h3>
                <p>Select an area on the left to see and choose more specific priorities.</p>
              </div>
            </div>
          </TabPane>

          {priorityObject &&
            priorityObject.map((item: any, index: number) => {
              return (
                <TabPane
                  tab={
                    <div className="tab-header-main">
                      <p className="tab-header">
                        {TabImages[index]}
                        {item.parentProductCategoryName}
                      </p>
                      <p className="tab-discription">{item.description}</p>
                      {totalNumberSelection(item) !== 0 ? (
                        <>
                          {" "}
                          <span className="batch hidden"></span>
                          <span className="batch">{totalNumberSelection(item)}</span>
                        </>
                      ) : null}
                    </div>
                  }
                  key={index}
                >
                  <div className="MultiCheckbox">
                    {/* <Checkbox.Group name={item.parentProductCategoryName}>
                                            <Checkbox value="A">{item.subcategories}</Checkbox>
                                        </Checkbox.Group> handleChange */}

                    {item.subcategories &&
                      item.subcategories.map((option: any) => (
                        <Checkbox
                          checked={checkedValue(option, item.answer)}
                          name="priority"
                          id="priority"
                          onChange={(e) => {
                            setTabValues(e, option, item, index);
                            handleChange(e);
                          }}
                          value={option.subProductCategoryId}
                        >
                          {option.subProductCategoryName}
                        </Checkbox>
                      ))}
                  </div>
                </TabPane>
              );
            })}
        </Tabs>
        {errorMessage ? <div className="error">{errorMessage}</div> : null}
      </div>
    </>
  );
};

export default ProductsMyPriorities;
