import { Card } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ErrorMessage } from "formik";
import React from "react";
import { cypherService } from "../../../../utilities/cypher/cypherService";
import { getPlaceHolder, handleCompanyDescription } from "./Formhelper";

export default function FromTextArea(props: any) {
  let serviceProviderId = cypherService.getLocalStorage("serviceProviderId");

  const { item, questionList, setQuestionList, index, isEditValue, QuestionHeader, handleChange } = props;

  const handleChangeValue = (event: any, questionList: any, setQuestionList: any, index: number) => {
    let tempQuestionList = questionList;
    let element = tempQuestionList[index];
    let tempAnswer = event;
    element.answer = tempAnswer;
    let payloadAnswerList: any = isEditValue && element.payloadAnswers;
    element.questionaireOption &&
      element.questionaireOption.forEach((el: any) => {
        let payloadAnswers = {
          ...(payloadAnswerList && { ...payloadAnswerList }),
          otherText: tempAnswer,
          questionaireOptionId: el.questionaireOptionId,
          serviceProviderId: serviceProviderId,
        };
        element["payloadAnswers"] = payloadAnswers;
      });

    setQuestionList([...tempQuestionList]);
  };

  return (
    <>
      <Card className="mb-5" title={<>{QuestionHeader(item)}</>} bordered={false} style={{ width: "100%" }}>
        <div className="answer-field">
          <TextArea
            placeholder={getPlaceHolder(item)}
            id={item.code}
            name={item.code}
            onChange={(e: any) => {
              handleChangeValue(e.target.value, questionList, setQuestionList, index);
              handleChange(e);
            }}
            value={item.answer}
            autoSize={{ minRows: 4 }}
          />
          <div className="error">
            <ErrorMessage name={item.code} />
          </div>
        </div>
      </Card>
    </>
  );
}
