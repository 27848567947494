
import SideBar from "./layout/SideBar";
import NotificationsLists from "./layout/NotificationLists";

export default function Notifications() {
  return (
    <div className="new-dashboar">
      <div className=" notification-vendor">
        <div className="notifcation-sidebar">
          <SideBar />
        </div>
        <div className="notification-menu ">
          <NotificationsLists></NotificationsLists>
        </div>
      </div>
    </div>
  );
}
