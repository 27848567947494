import * as Yup from 'yup';

let bussinessEmail = new RegExp("^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$")

export const AddServiceProviderValidationSchema = Yup.object({
    "personName": Yup.string().required('Please enter person name').matches(/^([A-Za-z\s]*)$/gi, 'Please enter a valid person name'),
    "emailId": Yup.string().required('Please enter email Id').email('Invalid email').matches(bussinessEmail, 'Please enter work email'),
    "companyName": Yup.string().required('Please enter company name'),
    "companyLocation": Yup.string().required('Please choose company location'),
    'uaeEmirate': Yup.string().when('companyLocation', {
        is: 'United Arab Emirates',
        then: Yup.string().required('Please select emirate')
    }),
    "channelPartner": Yup.string().required('Please choose gcc'),
    'findingPartner': Yup.string().when('channelPartner', {
        is: 'No',
        then: Yup.string().required('Please select an option')
    }),
    'partnerInfo': Yup.array().when('channelPartner', {
        is: 'Yes',
        then: Yup.array().of(
            Yup.object({
                "Location": Yup.string().required('Please select emirate'),
                "Partner": Yup.string().required("Please enter partner's name")
            })
        )
    })
});

export interface AddServiceProviderInitialValues {
    'personName': string,
    'emailId': string,
    'companyName': string,
    'companyLocation': string,
    'uaeEmirate': string,
    'channelPartner': string,
    'findingPartner': string,
    'partnerInfo': { "Location": '', "Partner": '' }[]
}