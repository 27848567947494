import { Card } from 'antd';
import { ErrorMessage } from 'formik';
import { Cascader } from 'formik-antd';
import React from 'react'
import QuestionHeader from '../layout/QuestionHeader';

export default function SchoolDetailsCascader(props: any) {
    const { item, questionList, setQuestionList, index, selectedgradList } = props
    const changeGradeValue = (event: any, questionList: any, setQuestionList: any, index: number, selectedgradList: any) => {
        let tempQuestionList = questionList;
        let selectedGradeKeys: any = [];
        selectedgradList.forEach((data: any) => {
            selectedGradeKeys.push(data.label);
        });
        tempQuestionList[index].answer = {};
        tempQuestionList[index].defaultAnswer = event;
        selectedGradeKeys.forEach((data: any) => {
            if (selectedGradeKeys.includes(data)) {
                tempQuestionList[index].answer[data] = [];
            }
        });
        event &&
            event.forEach((data: any) => {
                if (selectedGradeKeys.includes(data[0])) {
                    if (data.length == 1) {
                        tempQuestionList[index].answer[data[0]] = "All";
                    } else {
                        let tempData: any = tempQuestionList[index].answer[data[0]];
                        tempData.push(data[1]);
                        tempQuestionList[index].answer[data[0]] = tempData;
                    }
                }
            });
        setQuestionList([...tempQuestionList]);
    };
    return (
        <>
            <Card
                className="mb-5"
                title={
                    <>
                        <QuestionHeader answer={item.defaultAnswer} question={item.question} />
                    </>
                }
                bordered={false}
                style={{ width: "100%" }}
            >
                {/* Answer */}
                <div className="answer-field">
                    <Cascader
                        id={item.code}
                        name={item.code}
                        style={{ width: "100%" }}
                        options={selectedgradList}
                        placeholder="Select grades"
                        onChange={(e: any) => {
                            changeGradeValue(e, questionList, setQuestionList, index, selectedgradList);
                        }}
                        multiple
                        maxTagCount="responsive"
                        value={item.defaultAnswer}
                    />
                    <div className="error">
                        <ErrorMessage name={item.code} />
                    </div>
                </div>
            </Card>
        </>
    )
}
