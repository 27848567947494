export const handleMediaChange = (event: any, questionList: any, setQuestionList: any, index: any, setFieldValue: any, name: string, item: any, setErrors: any = null, errors: any, setUploadPreview: any = null, uploadPreview: any = null, selectedType: string = "", formErrors: any = null, setFormErrors: any = null) => {
  let errorMsg = "";
  let errorFlag: boolean = false;
  let filesObj = event.target.files[0];
  let format = "image";

  if (filesObj) {
    if (item.code === "video" || selectedType === "video" || item.code === "demoVideo") {
      if (filesObj.type == "video/mp4" || filesObj.type == "video/ogg" || filesObj.type == "video/webm" || filesObj.type == "audio/ogg") {
        errorFlag = false;
      } else {
        errorFlag = true;
        errorMsg = "Please upload a  of mp4/ogg/webm video format";
      }
      format = "video";
    } else if (item.code === "logo" || item.code === "images") {
      if (filesObj.type !== "image/png" && filesObj.type !== "image/jpg" && filesObj.type !== "image/jpeg") {
        errorFlag = true;
        errorMsg = "Please upload a image in the specified format (png, jpg, jpeg)";
      }
      format = "image";
    } else if (item.code === "caseStudy" || item.code === "companyCertificate" || selectedType === "pdf") {
      if (filesObj.type !== "application/pdf") {
        errorFlag = true;
        errorMsg = "Please upload a pdf in the specified format (pdf)";
      }
      format = "pdf";
    }
    let mb: any = filesObj.size / 1000000;
    let validSize = item.code === "video" || item.code === "demoVideo" || selectedType === "video" ? 20 : 3;
    let fixedMb: any = validSize;
    if (parseFloat(mb) > parseFloat(fixedMb)) {
      if (!errorFlag) {
        errorFlag = true;
        errorMsg = `Please upload a ${format} of size less than ${validSize}MB`;
      }
    }
  }

  if (!errorFlag) {
    let tempQuestionList = questionList;
    let defaultAnswerPayload = tempQuestionList[index].answer;
    if (item.code === "images" || (item.code === "video" && defaultAnswerPayload) || (item.code === "demoVideo" && defaultAnswerPayload)) {
      if (defaultAnswerPayload) {
        let obj = {
          files: filesObj,
          filePath: URL.createObjectURL(filesObj),
          create: true,
        };

        tempQuestionList[index].answer = [...defaultAnswerPayload, obj];
      }
    } else if (item.code === "testimonials") {
      setUploadPreview({
        ...uploadPreview,
        files: filesObj,
        fileName: filesObj.name,
        filePath: URL.createObjectURL(filesObj),
      });

      let validationSchema: any = formErrors;
      validationSchema &&
        validationSchema.map((item: any, index: number) => {
          if (item.name === selectedType) {
            validationSchema[index].message = ``;
            validationSchema[index].validation = false;
          }
        });

      setFormErrors([...validationSchema]);
    } else {
      tempQuestionList[index].answer = {
        files: filesObj,
        filePath: URL.createObjectURL(filesObj),
        create: true,
      };
    }

    if (item.code !== "testimonials") {
      setQuestionList([...tempQuestionList]);
      // setFieldValue(item.item, filesObj)
    }

    if (errors) {
      setErrors({
        ...errors,
        [item.code]: "",
      });
    }
  } else {
    setErrors({
      ...errors,
      [item.code]: errorMsg,
    });
  }
};
