import { useEffect, useState } from 'react';
import { Button, Spin, message, Row, Col, Input, Form } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ErrorMessage, Formik } from 'formik';
import { Schema, Ipassword } from './loginSchema';
import EndpointService from '../../utilities/constants/endpointService';
import HTTPService from '../../service/HTTPService';
import { useNavigate } from 'react-router-dom';
import { cypherService } from '../../utilities/cypher/cypherService';
import logo from '../../assets/images/SolvEd.png'

const PasswordCreation = () => {
  const [loader, setLoader] = useState(false)
  const [bgImg, setBgImg] = useState('')

  const [initPasswordFormValue, setInitPasswordFormValue] = useState<Ipassword>({
    password: '',
    confirmPassword: '',
    email: ''
  });

  useEffect(() => {
    const partyDiscriminator = JSON.parse(cypherService.getSessionStorage('partyDiscriminator') || '{}')
    setBgImg(partyDiscriminator)
  }, [])

  const navigate = useNavigate();

  const onSubmit = (values: any, actions: any) => {
    setLoader(true)
    // const setEmail = localStorage.setItem('loginRes', JSON.stringify({ "email": 'puzhukandi.m@cloudnowtech.com' }))
    const getLocal = localStorage.getItem('loginRes')
    console.log(getLocal)
    const getLocalObj = JSON.parse(getLocal || '{}')
    const { email } = getLocalObj;
    const { password } = values;
    console.log(email)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get('secretkey')
    const payLoad = { password, "secretKey": product }
    let mapUrl = EndpointService.createPasswordUrl
    HTTPService.post(mapUrl, payLoad).then(response => {
      const passwordCreationRes = response.data;
      const retriveLocal = localStorage.getItem('loginRes')
      const getStatus = response.status.success.toLowerCase();
      if (getStatus == 'success') {
        message.success(response.status.message)
          .then(() => {
            setLoader(false)
            navigate("/");
          })
      }
    }).catch(err => {
      // console.log(err)
      // throw err
      setLoader(false)
      message.error(err.response.data.status.message)
    });
  }

  return (
    <section className={bgImg == 'Customer' ? 'login-container customer' : 'login-container service-provider'}>
      <div className='login-logo'>
        <img src={logo} alt="logo" />
      </div>
      <Row gutter={32} justify="space-between" align="middle" className="login-row">
        <Col span={12} className="login-col forgot-col">
          <span className='welcome-text'>The SolvEd platform offers unmatched insights into the needs of educational institutions</span>
        </Col>
        <Col span={12} className="login-col forgot-col">
          <div className="login-card pwd-creation-card">
            {/* Loader */}
            {loader ? <Spin /> : null}
            <h2>Set Password</h2>
            <h6>Set a new password for your account.</h6>
            <Formik initialValues={initPasswordFormValue}
              validationSchema={Schema.passwordSchema}
              onSubmit={(values, actions) => {
                onSubmit(values, actions);
              }}>
              {({ handleSubmit, setFieldValue, values, errors, getFieldProps, handleChange }) => {
                return (
                  <Form layout="vertical" className="login-form">
                    <Form.Item name="password" label="Create password">
                      <Input.Password onChange={handleChange} name="password" placeholder="Enter password"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                      <ErrorMessage name="password">{msg => <div className='error'>{msg}</div>}</ErrorMessage>
                    </Form.Item>
                    <Form.Item name="confirmPassword" label="Re-enter Password" >
                      <Input.Password onChange={handleChange} name="confirmPassword" placeholder="Enter password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                      <ErrorMessage name="confirmPassword">{msg => <div className='error'>{msg}</div>}</ErrorMessage>
                    </Form.Item>
                    <div className="submit-btn"><Button block htmlType="submit" type="primary" onClick={() => handleSubmit()}>SIGN UP</Button></div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    </section>
  );
};
export default PasswordCreation;