import { useState } from 'react'
import Header from '../header/Header';
import { Input, Steps, Select, Button, message, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
    Form
} from "formik-antd";
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import HTTPService from '../../service/HTTPService';
import { notification } from 'antd';
import EndpointService from '../../utilities/constants/endpointService';


const { Step } = Steps;
const { Option } = Select;
interface IAddadmin {
    email: string;
    userName: string;
};
const AddAdmin = () => {
    let bussinessEmail = new RegExp("^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$")
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    const dashBoard = () => { navigate('/dashboard') }
    const viewData = () => { navigate('/viewdata') }
    const [initLoginFormValue, setInitAddadminFormValue] = useState<IAddadmin>({
        email: '',
        userName: ''
    });
    const AddadminSchema = Yup.object({
        email: Yup.string().required('Admin email address is required')
            .email('Invalid email address')
            .matches(bussinessEmail, "Please enter work email"),
        userName: Yup.string().required('Admin name Required'),

    })

    const onSubmit = (values: any) => {
        setLoader(true)
        const mapUrl = EndpointService.addAdmin
        let payLoad = values
        HTTPService.post(mapUrl, payLoad).then(response => {
            const Res = response;
            const getStatus = response.status.success.toLowerCase();
            console.log(Res);
            if (getStatus === 'success') {
                setLoader(false);
                message.success(response.status.message)
                window.location.reload();
            }
            if (getStatus === 'fail') {

                notification.error({
                    message: '',
                    description: Res.data.status.message,
                    placement: 'topRight'
                })
            }
        }).catch(err => {
            setLoader(false)
            notification.error({
                message: '',
                description: err.response.data.status.message,
                placement: 'topRight'
            })
            // throw err
        });
    }

    return (
        <div className="dashboard">
            <div className="add-new">
                <div className="add-new-school">
                    <div className="back-dashboard" onClick={() => { viewData() }} ><ArrowLeftOutlined /></div>
                    <h1>Add Admin</h1>
                    {loader ? <Spin /> : null}
                    <Formik initialValues={initLoginFormValue}
                        validationSchema={AddadminSchema}
                        onSubmit={(values, actions) => {
                            onSubmit(values);
                        }}>
                        {({ handleSubmit, setFieldValue, values, errors, getFieldProps, handleChange }) => {
                            return (
                                <Form>
                                    <Steps direction="vertical" current={1}>
                                        <Step title="Admin Name" description={
                                            <>
                                                <div>
                                                    <Input placeholder="Enter name" name='userName' onChange={handleChange} />
                                                </div>
                                                <div className="error">
                                                    <ErrorMessage name='userName' />
                                                </div>
                                            </>
                                        } />
                                        <Step title="Email ID" description={
                                            <>
                                                <div>
                                                    <Input placeholder="Enter e-mail address" name='email' onChange={handleChange} />
                                                </div>
                                                <div className="error">
                                                    <ErrorMessage name='email' />
                                                </div>
                                            </>
                                        } />

                                    </Steps>
                                    <div className="btn-group pb-12">
                                        <Button className="btn-colored" htmlType='submit'>Send Mail</Button>
                                    </div>
                                </Form>
                            )

                        }}
                    </Formik>

                </div>
            </div>
        </div>

    )
}

export default AddAdmin