import { Card, Checkbox } from 'antd';
import { ErrorMessage } from 'formik';
import { Input } from 'formik-antd';
import { useState } from 'react'


import QuestionHeader from '../layout/QuestionHeader';

export default function SchoolDetailsNumberFields(props: any) {
    const { item, inputDisabled, getPlaceHolder, questionList, setQuestionList, index, setFieldValue, setUserCountError } = props
    let isActiveMoreThan10000: any = false
    if (item.validation) {
        isActiveMoreThan10000 = item.moreThan10000
    }

    const [isMoreValueActive, setIsMoreValueActive] = useState<any>(isActiveMoreThan10000)

    const changeTextSetFieldValue = (event: any, questionList: any, setQuestionList: any, index: number, setFieldValue: any) => {
        questionList[index].answer = event;
        setQuestionList(questionList);
        setFieldValue(questionList[index].code, event);
        if (event <= 10000) {
            setIsMoreValueActive(false)
            questionList[index]["moreThan10000"] = false;
        } else {
            setIsMoreValueActive(true)
            questionList[index]["moreThan10000"] = true;
        }
    };
    const handleMoreThank10000 = (event: any, questionList: any, setQuestionList: any, index: number, setFieldValue: any) => {

        if (event.target.checked) {
            setIsMoreValueActive(true)
            questionList[index]["moreThan10000"] = true;
            questionList[index].answer = 10001;
            setQuestionList(questionList);
            setFieldValue(questionList[index].code, 10001);
        } else {
            setIsMoreValueActive(false)
            questionList[index]["moreThan10000"] = false;
            questionList[index].answer = 100;
            setQuestionList(questionList);
            setFieldValue(questionList[index].code, 100);
        }

    }
    return (
        <>

            <Card
                className="mb-5"
                title={
                    <>
                        <QuestionHeader answer={item.answer} question={item.question} />
                    </>
                }
                bordered={false}
                style={{ width: "100%" }}
            >
                {/* Answer */}
                <div className="answer-field pb-6">
                    <Input
                        disabled={inputDisabled(item.code)}
                        placeholder={getPlaceHolder(item)}
                        id={item.code}
                        name={item.code}
                        value={item.answer}
                        onKeyPress={(e) => !/[\d]/.test(e.key) && e.preventDefault()}
                        onChange={(e) => {
                            changeTextSetFieldValue(e.target.value, questionList, setQuestionList, index, setFieldValue);
                        }}
                    />
                    <div className="keep-me-sign-in">
                        <Checkbox checked={isMoreValueActive} onClick={(e) => handleMoreThank10000(e, questionList, setQuestionList, index, setFieldValue)}>
                            More than 10,000
                        </Checkbox>
                    </div>
                    <div className="error">
                        <ErrorMessage name={item.code} />
                    </div>
                </div>
            </Card>
        </>
    )
}
