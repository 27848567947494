export const changeTextValue = (event: any, questionList: any, setQuestionList: any, index: number) => {
  questionList[index].answer = event;
  setQuestionList(questionList);
};

export const changeTextSetFieldValue = (event: any, questionList: any, setQuestionList: any, index: number, setFieldValue: any, setUserCountError: any) => {
  if (questionList[index].code === "studentCount") {
    if (event <= 10000) {
      questionList[index].answer = event;
      setQuestionList(questionList);

      setFieldValue(questionList[index].code, event);
      setUserCountError("");
    } else {
      setUserCountError("Maximum value cannot exceed more than 10000");
    }
  } else {
    setUserCountError("");
    questionList[index].answer = event;
    setQuestionList(questionList);

    setFieldValue(questionList[index].code, event);
  }
};

export const changeTextSchoolName = (event: any, questionList: any, setQuestionList: any, index: number) => {
  questionList[index]["schoolNameText"] = event;
  setQuestionList(questionList);
};
export const changeTextAreaValue = (event: any, questionList: any, setQuestionList: any, index: number) => {
  questionList[index].answer = event;
  questionList[index].status = true;
  setQuestionList(questionList);
};

export const clearCountry = (questionList: any, setQuestionList: any, index: number) => {
  let tempQuestionList = questionList;
  tempQuestionList[index].answer = "";
  tempQuestionList[index].isUae = false;
  tempQuestionList[index].isUaeAnswer = "";
  setQuestionList([...tempQuestionList]);
};

export const changeUaeCountry = (event: any, questionList: any, setQuestionList: any, index: number) => {
  let tempQuestionList = questionList;
  let tempAnswer = event;
  tempQuestionList[index].isUaeAnswer = tempAnswer;
  setQuestionList([...tempQuestionList]);
};

export const clearUaeCountry = (questionList: any, setQuestionList: any, index: number) => {
  let tempQuestionList = questionList;
  tempQuestionList[index].isUaeAnswer = "";
  setQuestionList([...tempQuestionList]);
};

export const changeCountry = (event: any, questionList: any, setQuestionList: any, index: number) => {
  let tempQuestionList = questionList;
  let tempAnswer = event;
  tempQuestionList[index].answer = tempAnswer;
  if (event == "United Arab Emirates") {
    tempQuestionList[index].isUae = true;
  } else {
    tempQuestionList[index].isUae = false;
    tempQuestionList[index].isUaeAnswer = "";
  }
  setQuestionList([...tempQuestionList]);
};

export const radioChange = (event: any, questionList: any, setQuestionList: any, index: number, gccToggle: boolean | null, setGccToggle: any) => {
  if (event === "Yes") {
    setGccToggle(true);
  } else {
    setGccToggle(false);
  }
  let tempQuestionList = questionList;
  let tempAnswer = event;
  tempQuestionList[index].answer = tempAnswer;
  setQuestionList([...tempQuestionList]);
};

export const gccNoOptionQuestionChange = (event: any, questionList: any, setQuestionList: any, index: number, gccToggle: boolean | null, setGccToggle: any) => {
  let tempQuestionList = questionList;
  let tempAnswer = event;
  tempQuestionList[index].selectNoAnswer = tempAnswer;
  setQuestionList([...tempQuestionList]);
};

export const partnerNameChange = (event: any, index: number, partnerField: any, setPartnerField: any, name: any) => {
  let tempPartnerFields = partnerField;
  tempPartnerFields[index][name] = event;
  setPartnerField([...tempPartnerFields]);
};

export const partnerLocationChange = (event: any, index: number, partnerField: any, setPartnerField: any, name: any) => {
  let tempPartnerFields = partnerField;
  tempPartnerFields[index][name] = event;
  setPartnerField([...tempPartnerFields]);
};

export const partnerLocationClear = (index: number, partnerField: any, setPartnerField: any, name: any) => {
  let tempPartnerFields = partnerField;
  tempPartnerFields[index][name] = "";
  setPartnerField([...tempPartnerFields]);
};

export const deletePartnerRow = (rowIndex: number, partnerField: any, setPartnerField: any, arrayHelpers: any) => {
  let tempPartnerFields = partnerField;
  tempPartnerFields.splice(rowIndex, 1);
  setPartnerField([...partnerField]);
  arrayHelpers.remove(rowIndex);
};
