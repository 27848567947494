let TAB1 = <svg id="Group_9734" data-name="Group 9734" xmlns="http://www.w3.org/2000/svg" width="31.208" height="36.009" viewBox="0 0 31.208 36.009">
  <path id="Path_5312" data-name="Path 5312" d="M37.008,9.6h-6V6a1.2,1.2,0,0,0-.352-.849L28.516,3.014a2.352,2.352,0,0,0,.09-.613,2.4,2.4,0,1,0-2.4,2.4,2.352,2.352,0,0,0,.613-.09L28.606,6.5V9.6H8.2A1.2,1.2,0,0,0,7,10.8v4.8a1.2,1.2,0,0,0,1.2,1.2H9.4v18a1.2,1.2,0,0,0,1.2,1.2H34.607a1.2,1.2,0,0,0,1.2-1.2v-18h1.2a1.2,1.2,0,0,0,1.2-1.2V10.8a1.2,1.2,0,0,0-1.2-1.2m-3.6,24.006H11.8V16.8H33.407Zm2.4-19.2H9.4V12H35.807Z" transform="translate(-7)" fill="#b495ff" />
  <path id="Path_5313" data-name="Path 5313" d="M17.145,21.508a1.142,1.142,0,0,0,.81-.335l1.718-1.718a1.145,1.145,0,0,0-1.619-1.619l-1.718,1.718a1.145,1.145,0,0,0,.81,1.955" transform="translate(-5.12 3.633)" fill="#b495ff" />
  <path id="Path_5314" data-name="Path 5314" d="M19.336,25.607a1.144,1.144,0,0,0,1.619,0l5.153-5.153a1.145,1.145,0,0,0-1.619-1.619l-5.153,5.153a1.144,1.144,0,0,0,0,1.619" transform="translate(-4.375 6.495)" fill="#b495ff" />
</svg>
  ;

const TAB2 = <svg xmlns="http://www.w3.org/2000/svg" width="35.515" height="28.411" viewBox="0 0 35.515 28.411">
  <path id="Path_5448" data-name="Path 5448" d="M38.729,63.988,22.155,58.069a1.191,1.191,0,0,0-.8,0L4.786,63.988a1.184,1.184,0,0,0,0,2.23l6.351,2.268a1.008,1.008,0,0,0-.034.168v8.287a1.184,1.184,0,0,0,.626,1.044c.173.092.368.187.558.281v1.261A3.547,3.547,0,0,0,9.919,82.86v2.368A1.184,1.184,0,0,0,11.1,86.412h4.735a1.184,1.184,0,0,0,1.184-1.184V82.86a3.547,3.547,0,0,0-2.368-3.334V79.27a21.28,21.28,0,0,0,17.131-1.284,1.184,1.184,0,0,0,.626-1.044V68.654a1.007,1.007,0,0,0-.034-.168l6.351-2.268a1.184,1.184,0,0,0,0-2.23M14.654,84.044H12.287V82.86a1.184,1.184,0,1,1,2.368,0Zm15.39-7.827a18.9,18.9,0,0,1-15.39.518V69.742l6.705,2.395a1.186,1.186,0,0,0,.8,0l7.889-2.817Zm-8.287-6.452-5.337-1.906,5.662-1.618a1.183,1.183,0,1,0-.651-2.275l-8.287,2.368a1.174,1.174,0,0,0-.4.213L8.7,65.1l13.054-4.662L34.811,65.1Z" transform="translate(-4 -58)" fill="#ffdb82" />
</svg>
  ;

const TAB3 = <svg id="Group_9693" data-name="Group 9693" xmlns="http://www.w3.org/2000/svg" width="25.666" height="34.999" viewBox="0 0 25.666 34.999">
  <path id="Path_5299" data-name="Path 5299" d="M31.5,108.5H26.833v-1.167a1.167,1.167,0,0,0-1.167-1.167H21a1.167,1.167,0,1,0-2.333,0H14a1.167,1.167,0,0,0-1.167,1.167V108.5H8.167A1.167,1.167,0,0,0,7,109.667v29.166A1.167,1.167,0,0,0,8.167,140H31.5a1.167,1.167,0,0,0,1.167-1.167V109.667A1.167,1.167,0,0,0,31.5,108.5m-16.333,0H24.5v2.333H15.166Zm15.166,29.166h-21V110.833h3.5V112A1.167,1.167,0,0,0,14,113.166H25.666A1.167,1.167,0,0,0,26.833,112v-1.167h3.5Z" transform="translate(-7 -105)" fill="#8de2ff" />
  <path id="Path_5300" data-name="Path 5300" d="M28.349,115H13.486c-.82,0-1.486.351-1.486.783s.666.783,1.486.783H28.349c.82,0,1.486-.351,1.486-.783S29.169,115,28.349,115" transform="translate(-8.084 -103.06)" fill="#8de2ff" />
  <path id="Path_5301" data-name="Path 5301" d="M28.349,120H13.486c-.82,0-1.486.351-1.486.783s.666.783,1.486.783H28.349c.82,0,1.486-.351,1.486-.783S29.169,120,28.349,120" transform="translate(-8.084 -102.089)" fill="#8de2ff" />
  <path id="Path_5302" data-name="Path 5302" d="M18.265,125H12.783a.783.783,0,0,0,0,1.566h5.482a.783.783,0,0,0,0-1.566" transform="translate(-4.839 -101.119)" fill="#8de2ff" />
</svg>
  ;

const TAB4 = <svg id="Group_9733" data-name="Group 9733" xmlns="http://www.w3.org/2000/svg" width="33.357" height="33.357" viewBox="0 0 33.357 33.357">
  <path id="Path_5315" data-name="Path 5315" d="M9.3,167.3a1.136,1.136,0,0,1,.438.449A.728.728,0,1,0,11,167.019a2.6,2.6,0,0,0-1.019-1.009,3.342,3.342,0,0,0-3.755.846.728.728,0,0,0,1,1.055A1.954,1.954,0,0,1,9.3,167.3" transform="translate(0.143 -148.66)" fill="#cbff94" />
  <path id="Path_5316" data-name="Path 5316" d="M10.843,171.094a.734.734,0,0,0-.3.983.846.846,0,0,1,.009.747.764.764,0,0,1-.749.366.728.728,0,0,0-.152,1.448,2.228,2.228,0,0,0,2.165-1.09,2.29,2.29,0,0,0,.005-2.172.723.723,0,0,0-.978-.283" transform="translate(0.402 -147.224)" fill="#cbff94" />
  <path id="Path_5317" data-name="Path 5317" d="M11.877,157.408a1.8,1.8,0,0,0-.013-1.216A2.081,2.081,0,0,0,9.658,155a.728.728,0,0,0,.141,1.45.693.693,0,0,1,.707.266.406.406,0,0,1-.012.24.728.728,0,1,0,1.384.452" transform="translate(0.379 -152.083)" fill="#cbff94" />
  <path id="Path_5318" data-name="Path 5318" d="M34.357,164.343a6.653,6.653,0,0,0-4.468-6.288c.008-.128.02-.255.02-.384a6.668,6.668,0,0,0-12.231-3.679,6.668,6.668,0,0,0-12.231,3.679c0,.129.012.256.019.384a6.634,6.634,0,0,0-3.56,9.617A6.648,6.648,0,0,0,5.468,177.3c-.007.128-.02.255-.02.383a6.668,6.668,0,0,0,12.231,3.68,6.668,6.668,0,0,0,12.231-3.68c0-.128-.013-.255-.021-.383a6.646,6.646,0,0,0,3.561-9.631,6.6,6.6,0,0,0,.907-3.329M12.119,182.133a4.448,4.448,0,0,1-2.948-7.779A1.112,1.112,0,0,0,7.7,172.691a6.677,6.677,0,0,0-1.725,2.424,4.442,4.442,0,0,1,1.7-8.548,1.112,1.112,0,1,0,0-2.224,6.639,6.639,0,0,0-4.189,1.485,4.424,4.424,0,0,1,2.482-5.583,6.681,6.681,0,0,0,6.154,4.1,1.112,1.112,0,1,0,0-2.224,4.448,4.448,0,1,1,4.448-4.448v20.014a4.452,4.452,0,0,1-4.448,4.448m17.267-7.018a6.667,6.667,0,0,0-1.726-2.424,1.111,1.111,0,0,0-1.474,1.663,4.448,4.448,0,1,1-7.4,3.331V157.671a4.448,4.448,0,1,1,4.448,4.448,1.112,1.112,0,1,0,0,2.224,6.68,6.68,0,0,0,6.153-4.1,4.423,4.423,0,0,1,2.483,5.583,6.639,6.639,0,0,0-4.189-1.485,1.112,1.112,0,0,0,0,2.224,4.442,4.442,0,0,1,1.7,8.548" transform="translate(-1 -151)" fill="#cbff94" />
  <path id="Path_5319" data-name="Path 5319" d="M20.116,166.011a2.587,2.587,0,0,0-1.018,1.008.728.728,0,1,0,1.261.728,1.123,1.123,0,0,1,.438-.448,1.952,1.952,0,0,1,2.071.611.728.728,0,1,0,1-1.055,3.339,3.339,0,0,0-3.755-.844" transform="translate(3.116 -148.66)" fill="#cbff94" />
  <path id="Path_5320" data-name="Path 5320" d="M21.04,173.189a.761.761,0,0,1-.749-.366.844.844,0,0,1,.005-.741.728.728,0,0,0-1.274-.706,2.293,2.293,0,0,0,.005,2.172,2.213,2.213,0,0,0,1.927,1.1,2.184,2.184,0,0,0,.237-.013.728.728,0,1,0-.151-1.448" transform="translate(2.111 -147.223)" fill="#cbff94" />
  <path id="Path_5321" data-name="Path 5321" d="M19.7,157.91a.729.729,0,0,0,.692-.955.407.407,0,0,1-.012-.24.688.688,0,0,1,.706-.265A.728.728,0,1,0,21.232,155a2.079,2.079,0,0,0-2.207,1.191,1.8,1.8,0,0,0-.012,1.217.729.729,0,0,0,.692.5" transform="translate(2.088 -152.083)" fill="#cbff94" />
</svg>
  ;

const TAB5 = <svg xmlns="http://www.w3.org/2000/svg" width="32.435" height="27.03" viewBox="0 0 32.435 27.03">
  <g id="Group_9732" data-name="Group 9732" transform="translate(0 0)">
    <path id="Path_5304" data-name="Path 5304" d="M31.354,229.456H1.081A1.094,1.094,0,0,1,0,228.349V205.107A1.094,1.094,0,0,1,1.081,204H31.354a1.094,1.094,0,0,1,1.081,1.107v23.242a1.094,1.094,0,0,1-1.081,1.107M2.162,227.242H30.273V206.214H2.162Z" transform="translate(0 -202.426)" fill="#ff8e8e" />
    <path id="Path_5305" data-name="Path 5305" d="M6.787,206.722A.787.787,0,0,1,6,205.935v-3.148a.787.787,0,1,1,1.574,0v3.148a.787.787,0,0,1-.787.787" transform="translate(0.613 -202)" fill="#ff8e8e" />
    <path id="Path_5306" data-name="Path 5306" d="M14.787,206.722a.787.787,0,0,1-.787-.787v-3.148a.787.787,0,1,1,1.574,0v3.148a.787.787,0,0,1-.787.787" transform="translate(1.431 -202)" fill="#ff8e8e" />
    <path id="Path_5307" data-name="Path 5307" d="M22.787,206.722a.787.787,0,0,1-.787-.787v-3.148a.787.787,0,1,1,1.574,0v3.148a.787.787,0,0,1-.787.787" transform="translate(2.248 -202)" fill="#ff8e8e" />
    <path id="Path_5308" data-name="Path 5308" d="M6.574,213.935a.785.785,0,0,1-.556-.231l-.787-.787A.787.787,0,0,1,6.343,211.8l.231.231,1.8-1.8a.787.787,0,0,1,1.113,1.113L7.13,213.7a.785.785,0,0,1-.556.231" transform="translate(0.774 -200.762)" fill="#ff8e8e" />
    <path id="Path_5309" data-name="Path 5309" d="M6.574,220.935a.785.785,0,0,1-.556-.231l-.787-.787A.787.787,0,1,1,6.343,218.8l.231.231,1.8-1.8a.787.787,0,0,1,1.113,1.113L7.13,220.7a.785.785,0,0,1-.556.231" transform="translate(0.774 -199.679)" fill="#ff8e8e" />
    <path id="Path_5310" data-name="Path 5310" d="M21.082,213.574h-6.3a.787.787,0,1,1,0-1.574h6.3a.787.787,0,0,1,0,1.574" transform="translate(3.196 -200.932)" fill="#ff8e8e" />
    <path id="Path_5311" data-name="Path 5311" d="M21.082,220.574h-6.3a.787.787,0,1,1,0-1.574h6.3a.787.787,0,0,1,0,1.574" transform="translate(3.196 -200.185)" fill="#ff8e8e" />
  </g>
</svg>
  ;
export default class TabSupportImage {
  public static TAB1 = TAB1;
  public static TAB2 = TAB2;
  public static TAB3 = TAB3;
  public static TAB4 = TAB4;
  public static TAB5 = TAB5;
}