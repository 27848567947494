import { string } from "yup";
import { cypherService } from "../../utilities/cypher/cypherService";

export const getPlaceHolder = (data: any) => {
  if (data.code == "personName") {
    return "Enter name";
  } else if (data.code == "institutionName") {
    return "Enter institution name";
  } else if (data.code == "emailId") {
    return "Enter email id";
  } else if (data.code == "studentCount") {
    return "Enter student count";
  } else if (data.code == "emirate") {
    return "Select emirate";
  } else if (data.code == "schoolCategory") {
    return "Select school category";
  } else if (data.code == "roles") {
    return "Select role";
  } else if (data.code == "curricula") {
    return "Select curricula";
  }
};

const findPhoneNumber = (element: any) => {
  if (element.phoneNumber) {
    return element.phoneNumber;
  }
};

const checkCustomerQuestionaireId = (element: any, answer: any) => {
  let customerQuestionaireId = null;

  if (element.payloadAnswer) {
    element.payloadAnswer.forEach((el: any) => {
      if (el.otherText === answer) {
        customerQuestionaireId = el.customerQuestionaireId;
      }
    });
  }
  return customerQuestionaireId;
};

export const createPayload = (payloadAssets: any, allAnswerLists: any) => {
  let {
    editAgeGroupAnswerObject,
    questionList,
    editCustomerAnswerObject,
    gradeList,
    questionaireOptionId,
    schoolDetailsObj,
    approvedStatus,
    priorityAnswerLists,
    isEdit,
    currentBalance,
  } = payloadAssets;

  let customerPrioritiesList: any = JSON.parse(
    cypherService.getSessionStorage("priorityObject") || "[]"
  );

  // setLoader(true);
  let customerCurriculumAgeCategoryMapDto: any = [];
  let customerQuestionaireDto: any = [];
  let name: string = "";
  let email: string = "";
  let description: string = "";
  let phoneNumber: string = allAnswerLists.phoneNumber;
  let keyclockUserId: any = allAnswerLists.keyclockUserId;
  let isLoggedIn: string = allAnswerLists.isLoggedIn;
  // let isActive: string = allAnswerLists.isActive;
  // let currentBalance: number = allAnswerLists.currentBalance;
  let signupStatus: string = allAnswerLists.signupStatus;
  let descriptionStatus: any = false;
  let editCustomerAnswerObjectKeys: any = editCustomerAnswerObject
    ? Object.keys(editCustomerAnswerObject)
    : [];

  questionList.forEach((element: any) => {
    if (element.question == "Contact person name") {
      name = element.answer;
    } else if (element.question == "Email ID") {
      email = element.answer;
    } else if (element.code == "priorityDescription") {
      descriptionStatus = element.status;
      if (element.status) {
        description = element.answer;
      }
    } else if (element.questionGroup == "SCHOOL-Grades") {
      let selectedGradeCurriculum = Object.keys(element.answer);
      gradeList.forEach((item: any) => {
        if (selectedGradeCurriculum.includes(item.name)) {
          item.ageCategories.forEach((data: any) => {
            if (
              element.answer[item.name].includes(data.name) ||
              element.answer[item.name] == "All"
            ) {
              let temp: any = {
                curriculumAgeGroupAgeCategoryMapId:
                  data.curriculumAgeGroupAgeCategoryMapId,
              };
              customerCurriculumAgeCategoryMapDto.push(temp);
            }
          });
        }
      });
    } else if (element.code == "roles") {
      element.questionaireOption.forEach((data: any) => {
        if (element.answer.includes(data.option)) {
          let temp: any = {
            otherText: data.option,
            questionaireOptionId: data.questionaireOptionId,
            ...(element.customerQuestionaireId && {
              customerQuestionaireId: element.customerQuestionaireId,
            }),
          };
          if (
            editCustomerAnswerObjectKeys.includes(
              data.questionaireOptionId.toString()
            )
          ) {
            temp["customerQuestionaireId"] =
              editCustomerAnswerObject[data.questionaireOptionId.toString()];
          }
          customerQuestionaireDto.push(temp);
        }
      });
    } else if (element.questionGroup == "SCHOOL-Curricula") {
      let temp: any = {
        otherText: element.answer.toString(),
        questionaireOptionId:
          element.previousQuestionaireOption[0].questionaireOptionId,
        ...(element.customerQuestionaireId && {
          customerQuestionaireId: element.customerQuestionaireId,
        }),
      };
      if (
        editCustomerAnswerObjectKeys.includes(
          element.previousQuestionaireOption[0].questionaireOptionId.toString()
        )
      ) {
        temp["customerQuestionaireId"] =
          editCustomerAnswerObject[
            element.previousQuestionaireOption[0].questionaireOptionId.toString()
          ];
      }
      customerQuestionaireDto.push(temp);
    } else if (element.questionGroup == "SCHOOL-Location") {
      element.questionaireOption &&
        element.questionaireOption.forEach((el: any) => {
          element.answer.forEach((list: any) => {
            if (el.option === list) {
              let customerQuestionaireId: any = checkCustomerQuestionaireId(
                element,
                list
              );
              let tempValue: any = {
                otherText: list,
                questionaireOptionId: el.questionaireOptionId,
                ...(customerQuestionaireId && {
                  customerQuestionaireId: customerQuestionaireId,
                }),
              };
              customerQuestionaireDto.push(tempValue);
            }
          });
        });
    } else if (
      element.questionGroup === "SCHOOL-Public or private" ||
      element.questionGroup === "PRIVACY-School details"
    ) {
      let questionId = questionaireOptionId(
        element.answer,
        element.questionaireOption
      );

      let tempValue: any = {
        otherText: element.answer,
        questionaireOptionId: questionId,
        ...(element.customerQuestionaireId && {
          customerQuestionaireId: element.customerQuestionaireId,
        }),
      };
      if (
        editCustomerAnswerObjectKeys.includes(
          element.questionaireOption[0].questionaireOptionId.toString()
        )
      ) {
        tempValue["customerQuestionaireId"] =
          editCustomerAnswerObject[
            element.questionaireOption[0].questionaireOptionId.toString()
          ];
      }
      customerQuestionaireDto.push(tempValue);
    } else if (element.questionGroup === "DISPLAY SCHOOL-Name") {
      let questionId = questionaireOptionId(
        element.answer,
        element.questionaireOption
      );

      let tempValue: any = {
        otherText: element.answer,
        questionaireOptionId: questionId,
        ...(element.customerQuestionaireId && {
          customerQuestionaireId: element.customerQuestionaireId,
        }),
      };
      if (
        editCustomerAnswerObjectKeys.includes(
          element.questionaireOption[0].questionaireOptionId.toString()
        )
      ) {
        tempValue["customerQuestionaireId"] =
          editCustomerAnswerObject[
            element.questionaireOption[0].questionaireOptionId.toString()
          ];
      }
      customerQuestionaireDto.push(tempValue);
    } else if (element.questionGroup === "SCHOOL-School name") {
      let questionId =
        element.questionaireOption &&
        element.questionaireOption[0].questionaireOptionId;

      let tempValue: any = {
        otherText: element.answer,
        questionaireOptionId: questionId,
        ...(element.customerQuestionaireId && {
          customerQuestionaireId: element.customerQuestionaireId,
        }),
      };
      if (
        editCustomerAnswerObjectKeys.includes(
          element.questionaireOption[0].questionaireOptionId.toString()
        )
      ) {
        tempValue["customerQuestionaireId"] =
          editCustomerAnswerObject[
            element.questionaireOption[0].questionaireOptionId.toString()
          ];
      }
      customerQuestionaireDto.push(tempValue);
    } else if (
      element.questionGroup == "PRIORITIES-Areas of priority" ||
      element.questionGroup == "PRIORITIES-Description"
    ) {
      let tempValue: any = {
        otherText: [],
        questionaireOptionId: null,
        customerQuestionaireId: null,
      };
      // customerQuestionaireDto.push(tempValue);
    } else if (element.questionGroup == "SCHOOL-Students") {
      let checkValidator = element.moreThan10000
        ? "More than 10,000"
        : "Show Text ";
      let questionaireOptionIdValue: any = null;
      element.questionaireOption &&
        element.questionaireOption.forEach((opt: any) => {
          if (checkValidator === opt.option) {
            questionaireOptionIdValue = opt.questionaireOptionId;
          }
        });

      let tempValue: any = {
        otherText: element.answer,
        questionaireOptionId: questionaireOptionIdValue,
      };
      if (
        editCustomerAnswerObjectKeys.includes(
          element.questionaireOption[0].questionaireOptionId.toString()
        )
      ) {
        tempValue["customerQuestionaireId"] =
          editCustomerAnswerObject[
            element.questionaireOption[0].questionaireOptionId.toString()
          ];
      }
      customerQuestionaireDto.push(tempValue);
    } else {
      let tempValue: any = {
        otherText: element.answer,
        questionaireOptionId:
          element.questionaireOption[0].questionaireOptionId,
      };
      if (
        editCustomerAnswerObjectKeys.includes(
          element.questionaireOption[0].questionaireOptionId.toString()
        )
      ) {
        tempValue["customerQuestionaireId"] =
          editCustomerAnswerObject[
            element.questionaireOption[0].questionaireOptionId.toString()
          ];
      }
      customerQuestionaireDto.push(tempValue);
    }
  });

  let priorities: any = [];

  customerPrioritiesList &&
    customerPrioritiesList.forEach((item: any) => {
      item.answer &&
        item.answer.forEach((innerItem: any) => {
          let obj = { productCategoryId: innerItem.productCategoryId };
          priorities = [...priorities, obj];
        });
    });

  let userId = cypherService.getLocalStorage("userId");

  let payload: any = {
    customerCurriculumAgeCategoryMapDto: customerCurriculumAgeCategoryMapDto,
    customerQuestionaireDto: customerQuestionaireDto,
    ...(priorities.length > 0 && {
      customerPriorityDto: priorities,
    }),

    email: email,
    name: name,
    partyDiscriminator: "Customer",
    phoneNumber: phoneNumber,
    // ...(isEdit && { keyclockUserId: schoolDetailsObj.keyclockUserId }),
    // ...(isEdit && { isLoggedIn: schoolDetailsObj.isLoggedIn }),
    ...(isEdit && { customerApprovedStatusDto: [...approvedStatus] }),
    ...(isEdit && { signupStatus: schoolDetailsObj.signupStatus }),
    ...(descriptionStatus && { description: description }),
    userDetailId: userId,
    isLoggedIn: isLoggedIn,
    signupStatus: signupStatus,
    currentBalance: currentBalance,
    // currentBalance: currentBalances,
    keyclockUserId: keyclockUserId,
  };
  return payload;
};

export const statusValue = [
  {
    details: "general_detail",
    status: "Pending Approval",
  },
  {
    details: "priority_detail",
    status: "Pending Approval",
  },
];

export const schoolInitialValues = {
  personName: "",
  roles: "",
  emailId: "",
  curricula: "",
  grades: "",
  emirate: "",
  schoolCategory: "",
  studentCount: "",
  priority: null,
  priorityDescription: "",
  schoolName: "",
  name: "",
};
