import { PlusOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import AssessmentIcon from '../../assets/images/icons/AssessmentIcon'
import CurriculumIcon from '../../assets/images/icons/CurriculumIcon'
import LeadershipIcon from '../../assets/images/icons/LeadershipIcon'
import PersonalIcon from '../../assets/images/icons/PersonalIcon'
import PlanningIcon from '../../assets/images/icons/PlanningIcon'
import { cypherService } from "../../utilities/cypher/cypherService";
import EndpointService from "../../utilities/constants/endpointService";
import HTTPService from "../../service/HTTPService";
import { Spin } from 'antd'
export default function PrioritiesGrid(props: any) {
    const isEdit = cypherService.getLocalStorage("isProductsEdit");
    const { handleModalOpen = false, isActiveAdd = false, prioritiesCount = null, matchedProductCount = null, defaultList = null, isActivePriorities } = props
    const [priorityObject, setPriorityObject] = useState<any>({
        planningAndTeaching: [],
        assessment: [],
        curriculum: [],
        leadershipManagement: [],
        personalAndSocialDevelopment: []
    });
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {

        if (!isEdit) {

            if (!isActivePriorities) {
                isActiveAdd ? addPriorities() : getTabWithMultiSelectQuestionDetails()

            } else {
                let selectedPriorities = cypherService.getLocalStorage("selectedPriorities")
                setPriorityObject(selectedPriorities)
            }
        } else {

            if (!isActivePriorities) {

                getTabWithMultiSelectQuestionDetails();
            } else {
                let selectedPriorities = cypherService.getLocalStorage("selectedPriorities")
                setPriorityObject(selectedPriorities)
            }
        }


    }, [isActivePriorities]);

    const checkNull = (el: any) => {
        return el ? el : "0"
    }

    console.log("defaultList", defaultList);

    const handleAssignValue = (name: string, selectedPriorities: any, matchedPrioritiesList: any, element: any) => {
        if (matchedPrioritiesList) {

            let splitValue = matchedPrioritiesList.includes(",") ? matchedPrioritiesList.split(",") : matchedPrioritiesList.split(" ")
            splitValue && splitValue.forEach((el: any) => {
                element.subcategories && element.subcategories.forEach((list: any) => {
                    if (el == list.subProductCategoryId) {
                        selectedPriorities[name] = [...selectedPriorities[name], list.subProductCategoryName]
                    }
                })
            })
        }
    }

    const addPriorities = () => {
        let selectedPriorities: any = {
            planningAndTeaching: [],
            assessment: [],
            curriculum: [],
            leadershipManagement: [],
            personalAndSocialDevelopment: []
        }

        defaultList && defaultList.forEach((ele: any) => {

            ele && ele.subcategories && ele.subcategories.map((list: any) => {
                if (ele.parentProductCategoryId === 1) {
                    selectedPriorities["leadershipManagement"] = [...selectedPriorities.leadershipManagement, list.subProductCategoryName]
                } else if (ele.parentProductCategoryId === 2) {
                    selectedPriorities["assessment"] = [...selectedPriorities.assessment, list.subProductCategoryName]
                }
                else if (ele.parentProductCategoryId === 3) {
                    selectedPriorities["curriculum"] = [...selectedPriorities.curriculum, list.subProductCategoryName]
                }
                else if (ele.parentProductCategoryId === 4) {
                    selectedPriorities["personalAndSocialDevelopment"] = [...selectedPriorities.personalAndSocialDevelopment, list.subProductCategoryName]
                }
                else if (ele.parentProductCategoryId === 5) {
                    selectedPriorities["planningAndTeaching"] = [...selectedPriorities.planningAndTeaching, list.subProductCategoryName]
                }
            })

        })
        setPriorityObject(selectedPriorities)
    }

    const getTabWithMultiSelectQuestionDetails = (): any => {
        setLoader(true);
        let mapUrl = EndpointService.getProductCategoryOneOfTheAssessmentQuestionUrl;
        HTTPService.get(mapUrl).then((response: any) => {
            let tabWithMultiSelectQuestionData = response.data;
            let selectedPriorities: any = {
                planningAndTeaching: [],
                assessment: [],
                curriculum: [],
                leadershipManagement: [],
                personalAndSocialDevelopment: []
            }
            tabWithMultiSelectQuestionData.forEach((element: any, index: number) => {
                let tempCategory = element.subcategories;

                if (isActiveAdd) {
                    tempCategory.forEach((item: any) => {
                        defaultList.answer && defaultList.answer.forEach((ele: any) => {

                            ele && ele.subcategories && ele.subcategories.map((list: any) => {
                                if (item.subProductCategoryId === list.subProductCategoryId) {

                                    if (element.parentProductCategoryId === 1) {
                                        selectedPriorities["leadershipManagement"] = [...selectedPriorities.leadershipManagement, item.subProductCategoryName]
                                    } else if (element.parentProductCategoryId === 2) {
                                        selectedPriorities["assessment"] = [...selectedPriorities.assessment, list.subProductCategoryName]
                                    }
                                    else if (element.parentProductCategoryId === 3) {
                                        selectedPriorities["curriculum"] = [...selectedPriorities.curriculum, list.subProductCategoryName]
                                    }
                                    else if (element.parentProductCategoryId === 4) {
                                        selectedPriorities["personalAndSocialDevelopment"] = [...selectedPriorities.personalAndSocialDevelopment, list.subProductCategoryName]
                                    }
                                    else if (element.parentProductCategoryId === 5) {
                                        selectedPriorities["planningAndTeaching"] = [...selectedPriorities.planningAndTeaching, list.subProductCategoryName]
                                    }
                                }
                            })

                        })

                    })
                } else {

                    tempCategory.forEach((item: any) => {

                        defaultList.answer && defaultList.answer.forEach((ele: any) => {

                            if (ele.productCategoryId === item.subProductCategoryId) {

                                if (element.parentProductCategoryId === 1) {
                                    selectedPriorities["leadershipManagement"] = [...selectedPriorities.leadershipManagement, item.subProductCategoryName]
                                } else if (element.parentProductCategoryId === 2) {
                                    selectedPriorities["assessment"] = [...selectedPriorities.assessment, item.subProductCategoryName]
                                }
                                else if (element.parentProductCategoryId === 3) {
                                    selectedPriorities["curriculum"] = [...selectedPriorities.curriculum, item.subProductCategoryName]
                                }
                                else if (element.parentProductCategoryId === 4) {
                                    selectedPriorities["personalAndSocialDevelopment"] = [...selectedPriorities.personalAndSocialDevelopment, item.subProductCategoryName]
                                }
                                else if (element.parentProductCategoryId === 5) {
                                    selectedPriorities["planningAndTeaching"] = [...selectedPriorities.planningAndTeaching, item.subProductCategoryName]
                                }
                            }
                        })
                    })

                    // let matchedPriorities = cypherService.getLocalStorage("matchedPriorities")
                    // if (element.parentProductCategoryId === 1) {
                    //     let matchedPrioritiesList = matchedPriorities && matchedPriorities.length > 0 && matchedPriorities[0].leaderShipManagementPriorityId
                    //     handleAssignValue("leadershipManagement", selectedPriorities, matchedPrioritiesList, element)
                    // }
                    // if (element.parentProductCategoryId === 2) {
                    //     let matchedPrioritiesList = matchedPriorities && matchedPriorities.length > 0 && matchedPriorities[0].assessmentPriorityId
                    //     
                    //     handleAssignValue("assessment", selectedPriorities, matchedPrioritiesList, element)
                    // }
                    // if (element.parentProductCategoryId === 3) {
                    //     let matchedPrioritiesList = matchedPriorities && matchedPriorities.length > 0 && matchedPriorities[0].curriculumPriorityId
                    //     handleAssignValue("curriculum", selectedPriorities, matchedPrioritiesList, element)
                    // }

                    // if (element.parentProductCategoryId === 4) {
                    //     let matchedPrioritiesList = matchedPriorities && matchedPriorities.length > 0 && matchedPriorities[0].personalAndSocialDevelopmentPriorityId
                    //     handleAssignValue("personalAndSocialDevelopment", selectedPriorities, matchedPrioritiesList, element)
                    // }
                    // if (element.parentProductCategoryId === 5) {
                    //     let matchedPrioritiesList = matchedPriorities && matchedPriorities.length > 0 && matchedPriorities[0].planningAndTeachingPriorityId
                    //     handleAssignValue("planningAndTeaching", selectedPriorities, matchedPrioritiesList, element)

                    // }
                }

            });

            setPriorityObject(selectedPriorities)
            setLoader(false);
        });

    }
    return (
        <>
            {loader ? <Spin /> : null}

            <div className="grid">
                <div className="grid-row grid-row-header">
                    <div className="grid-item grid-item-1">
                        <div className="content"><h6>Pillars</h6></div>
                    </div>
                    <div className="grid-item grid-item-2">
                        <div className="content"><LeadershipIcon /> <h6>Leadership & management</h6> </div>
                    </div>
                    <div className="grid-item grid-item-3">
                        <div className="content">  <AssessmentIcon /> <h6>Assessment</h6></div>
                    </div>
                    <div className="grid-item grid-item-4">
                        <div className="content"> <CurriculumIcon /> <h6>Curriculum</h6></div>
                    </div>
                    <div className="grid-item grid-item-5">
                        <div className="content"> <PersonalIcon /> <h6>Personal & Social Development</h6></div>
                    </div>
                    <div className="grid-item grid-item-6">
                        <div className="content"> <PlanningIcon /> <h6>Planning & Teaching</h6></div>
                    </div>

                </div>
                <div className="grid-row">
                    <div className="grid-item grid-item-1">
                        <div className="content">
                            <h6>No.of priorities</h6>
                        </div>
                    </div>
                    <div className="grid-item grid-item-2">
                        <div className="content">
                            <h4>{prioritiesCount && checkNull(priorityObject && priorityObject.leadershipManagement.length)}</h4>
                        </div>
                    </div>
                    <div className="grid-item grid-item-3">
                        <div className="content">
                            <h4>{prioritiesCount && checkNull(priorityObject && priorityObject.assessment.length)}</h4>
                        </div>
                    </div>
                    <div className="grid-item grid-item-4">
                        <div className="content">
                            <h4>{prioritiesCount && checkNull(priorityObject && priorityObject.curriculum.length)}</h4>
                        </div>
                    </div>
                    <div className="grid-item grid-item-5">
                        <div className="content">
                            <h4>{prioritiesCount && checkNull(priorityObject && priorityObject.personalAndSocialDevelopment.length)}</h4>
                        </div>
                    </div>
                    <div className="grid-item grid-item-6">
                        <div className="content">
                            <h4>{prioritiesCount && checkNull(priorityObject && priorityObject.planningAndTeaching.length)}</h4>
                        </div>
                    </div>
                </div>
                <div className="grid-row grid-row-address">
                    <div className="grid-item grid-item-1">
                        <div className="content"><h6>Priorities addressed</h6></div>
                    </div>
                    <div className="grid-item grid-item-2">
                        <div className="content flex-wrap">
                            <ul>
                                {
                                    priorityObject && priorityObject.leadershipManagement.length > 0 ? (priorityObject.leadershipManagement.map((item: any, index: number) => (
                                        <li key={index}>{item}</li>
                                    ))) : <li>No Priorities found</li>
                                }
                            </ul>

                            <div className="add-button cursor-pointer" onClick={handleModalOpen}><PlusOutlined /> <span>Add new priorities</span></div>

                        </div>
                    </div>
                    <div className="grid-item grid-item-3">
                        <div className="content flex-wrap">
                            <ul>
                                {
                                    priorityObject && priorityObject.assessment.length > 0 ? (priorityObject.assessment.map((item: any, index: number) => (
                                        <li key={index}>{item}</li>
                                    ))) : <li>No Priorities found</li>
                                }

                            </ul>
                            <div className="add-button cursor-pointer" onClick={handleModalOpen}><PlusOutlined /> <span>Add new priorities</span></div>
                        </div>
                    </div>
                    <div className="grid-item grid-item-4">
                        <div className="content flex-wrap">
                            <ul>
                                {
                                    priorityObject && priorityObject.curriculum.length > 0 ? (priorityObject.curriculum.map((item: any, index: number) => (
                                        <li key={index}>{item}</li>
                                    ))) : <li>No Priorities found</li>
                                }

                            </ul>
                            <div className="add-button cursor-pointer" onClick={handleModalOpen}><PlusOutlined /> <span>Add new priorities</span></div>
                        </div>
                    </div>
                    <div className="grid-item grid-item-5">
                        <div className="content flex-wrap">
                            <ul>
                                {
                                    priorityObject && priorityObject.personalAndSocialDevelopment.length > 0 ? (priorityObject.personalAndSocialDevelopment.map((item: any, index: number) => (
                                        <li key={index}>{item}</li>
                                    ))) : <li>No Priorities found</li>
                                }

                            </ul>
                            <div className="add-button cursor-pointer" onClick={handleModalOpen}><PlusOutlined /> <span>Add new priorities</span></div>
                        </div>
                    </div>
                    <div className="grid-item grid-item-6">
                        <div className="content flex-wrap">
                            <ul>
                                {
                                    priorityObject && priorityObject.planningAndTeaching.length > 0 ? (priorityObject.planningAndTeaching.map((item: any, index: number) => (
                                        <li key={index}>{item}</li>
                                    ))) : <li>No Priorities found</li>
                                }

                            </ul>
                            <div className="add-button cursor-pointer" onClick={handleModalOpen}><PlusOutlined /> <span>Add new priorities</span></div>
                        </div>
                    </div>
                </div>
                <div className="grid-row">
                    <div className="grid-item grid-item-1">
                        <div className="content"><h6>No. of {isActiveAdd ? "schools" : "products"}  matches</h6></div>
                    </div>
                    <div className="grid-item grid-item-2">
                        <div className="content">
                            <h4>{matchedProductCount && checkNull(matchedProductCount.leadershipManagementProductCount)}</h4>
                        </div>
                    </div>
                    <div className="grid-item grid-item-3">
                        <div className="content">
                            <h4>{matchedProductCount && checkNull(matchedProductCount.assessmentProductCount)}</h4>
                        </div>
                    </div>
                    <div className="grid-item grid-item-4">
                        <div className="content">
                            <h4>{matchedProductCount && checkNull(matchedProductCount.curriculumProductCount)}</h4>
                        </div>
                    </div>
                    <div className="grid-item grid-item-5">
                        <div className="content">
                            <h4>{matchedProductCount && checkNull(matchedProductCount.personalAndSocialDevelopmentProductCount)}</h4>
                        </div>
                    </div>
                    <div className="grid-item grid-item-6">
                        <div className="content">
                            <h4>{matchedProductCount && checkNull(matchedProductCount.planningAndTeachingProductCount)}</h4>
                        </div>
                    </div>
                </div>
                <div className="grid-row grid-row-matches">
                    <div className="grid-item grid-item-1">
                        <div className="content"><h6>No. of recommended {isActiveAdd ? "schools" : "products"} (those which address most priorities)</h6></div>
                    </div>
                    <div className="grid-item grid-item-2">
                        <div className="content">
                            <h4>{matchedProductCount && checkNull(matchedProductCount.matched)}</h4>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
