import { GET_MESSAGE_SIDEBAR } from "./messageTypes"

import { GET_MESSAGE_LISTS } from "./messageTypes"


export const getMessageAction = (object: any) => {
    return {
        type: GET_MESSAGE_SIDEBAR,
        data: object
    }
}

export const getMessageListsAction = (object: any) => {
    return {
        type: GET_MESSAGE_LISTS,
        data: object
    }
}