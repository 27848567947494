import { Dropdown, Space } from "antd";
import { cypherService } from '../../utilities/cypher/cypherService';
const findPillarColorTheme = (item: any) => {
    if (item.productCategoryId === 1) {
        return <span className='priorities color-leader' key={item.edTechExpertProductCategoryId}>
            Leadership & management
        </span>
    } else if (item.productCategoryId === 2) {
        return <span className='priorities color-assessment' key={item.edTechExpertProductCategoryId}>
            Assessment
        </span>
    }
    else if (item.productCategoryId === 3) {
        return <span className='priorities color-curriculum' key={item.edTechExpertProductCategoryId}>
            Curriculum
        </span>
    }
    else if (item.productCategoryId === 4) {
        return <span className='priorities color-planing' key={item.edTechExpertProductCategoryId}>
            Personal & Social Development
        </span>
    }
    else if (item.productCategoryId === 5) {
        return <span className='priorities color-personal' key={item.edTechExpertProductCategoryId}>
            Planning & Teaching
        </span>
    }
}

const findMultiPillarColorTheme = (item: any) => {
    let cacheArray: any = []
    item.forEach((el: any) => {

        if (el.productCategoryId === 1) {
            let object = {
                label: <span className='priorities color-leader' key={el.edTechExpertProductCategoryId}>
                    Leadership & management
                </span>,
                key: "Leadership & management",
            }
            cacheArray.push(object)
        }
        else if (el.productCategoryId === 2) {
            let object = {
                label: <span className='priorities color-assessment' key={el.edTechExpertProductCategoryId}>
                    Assessment
                </span>,
                key: "Assessment",
            }
            cacheArray.push(object)
        }
        else if (el.productCategoryId === 3) {
            let object = {
                label: <span className='priorities color-curriculum' key={el.edTechExpertProductCategoryId}>
                    Curriculum
                </span>,
                key: "Curriculum",
            }
            cacheArray.push(object)
        }
        else if (el.productCategoryId === 4) {
            let object = {
                label: <span className='priorities color-personal' key={el.edTechExpertProductCategoryId}>
                    Personal & Social Development
                </span>,
                key: "Personal",
            }
            cacheArray.push(object)
        }
        else if (el.productCategoryId === 5) {
            let object = {
                label: <span className='priorities color-planing' key={el.edTechExpertProductCategoryId}>
                    Planning & Teaching
                </span>,
                key: " Planning & Teaching",
            }
            cacheArray.push(object)
        }


    })
    return cacheArray
}
export const prioritiesPillarName = (el: any) => {

    if (el && el.length === 1) {
        return el.map((item: any) => {
            return findPillarColorTheme(item)
        })
    } else {
        return <><Dropdown className='tbl-area' menu={{ items: [...findMultiPillarColorTheme(el)] }} trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
                <Space> Multi pillars</Space>
            </a>
        </Dropdown>  </>
    }
}

const multiLocation = (el: any) => {

    let cacheArray: any = []
    el && el.length > 0 && el.forEach((el: any) => {
        let object = {
            label: el.otherText,
            key: el.otherText,
        }
        cacheArray.push(object)
    })
    return cacheArray
}

const singleLocation = (el: any) => {
    ;

    let cacheText: string = ""
    el && el.length > 0 && el.forEach((el: any) => {
        cacheText = el.otherText
    })
    return cacheText
}
export const locationSplit = (e: any) => {
    ;
    if (e.length > 1) {
        return <><Dropdown className='tbl-location' menu={{ items: [...multiLocation(e)] }} trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
                <Space> Multi Location</Space>
            </a>
        </Dropdown>  </>
    } else {
        return <span className='tbl-location'>{singleLocation(e)}</span>
    }
}

export const handleRedirect = (type: string, el: any, navigate: any) => {
    cypherService.setLocalStorage("isEditEdTech", true);
    cypherService.setLocalStorage("edTechExpertId", el.edTechExpertId);
    navigate("/edtechExpertDetails");
}




export const media = (setErrorsMessage: React.Dispatch<React.SetStateAction<string>>, setProfileFiles: React.Dispatch<any>) => {
    return (event: any, handleChange: any) => {
        let errorMsg = "";
        let errorFlag: boolean = false;
        let filesObj = event.target.files[0];
        let format = "image";
        ;
        if (filesObj) {
            if (filesObj.type !== "image/png" && filesObj.type !== "image/jpg" && filesObj.type !== "image/jpeg") {
                errorFlag = true;
                errorMsg = "Please upload a image in the specified format (png, jpg, jpeg)";
            }
            let mb: any = filesObj.size / 1000000;
            let validSize = 3;
            let fixedMb: any = validSize;
            if (parseFloat(mb) > parseFloat(fixedMb)) {
                if (!errorFlag) {
                    errorFlag = true;
                    errorMsg = `Please upload a ${format} of size less than ${validSize}MB`;
                }
            }
        }
        if (!errorFlag) {
            setErrorsMessage("");
            setProfileFiles({
                files: filesObj,
                imagePath: URL.createObjectURL(filesObj),
                imageName: filesObj.name
            });
            handleChange(event);
        } else {
            setErrorsMessage(errorMsg);
        }
    };
}



export const daysList = [
    {
        "name": "Sun",
        "startTiming": "00:00",
        "endTiming": "24:00",
        "active": false,
        "isEdit": false,
        "error": "",
        "id": 1
    },
    {
        "name": "Mon",
        "startTiming": "00:00",
        "endTiming": "24:00",
        "active": false,
        "isEdit": false,
        "error": "",
        "id": 2
    },
    {
        "name": "Tue",
        "startTiming": "00:00",
        "endTiming": "24:00",
        "active": false,
        "isEdit": false,
        "error": "",
        "id": 3
    },
    {
        "name": "Wed",
        "startTiming": "00:00",
        "endTiming": "24:00",
        "active": false,
        "isEdit": false,
        "error": "",
        "id": 4
    },
    {
        "name": "Thu",
        "startTiming": "00:00",
        "endTiming": "24:00",
        "active": false,
        "isEdit": false,
        "error": "",
        "id": 5
    },
    {
        "name": "Fri",
        "startTiming": "00:00",
        "endTiming": "24:00",
        "active": false,
        "isEdit": false,
        "error": "",
        "id": 6
    }
    ,
    {
        "name": "Sat",
        "startTiming": "00:00",
        "endTiming": "24:00",
        "isEdit": false,
        "active": false,
        "error": "",
        "id": 7
    }
]