import { Button, Checkbox, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
export default function ConfirmModal(props: any) {
  const { rejectReasonList, setCheckedReason, checkedReason, rejectReason, cancelAction, setRejectReason, onSubmitReject } = props;
  const [fieldError, setFieldError] = useState<string>('');
  const [isActiveText, setIsActiveText] = useState<boolean>(false)

  const selectChange = (event: any) => {

    let currentValue: any = event.target.value;
    if (currentValue === "Others") {
      setCheckedReason("Others")
      setIsActiveText(true)
    } else {
      setCheckedReason(event.target.value)
      setIsActiveText(false)
    }
    setFieldError("")
  }
  const submitRejectReason = () => {
    if (checkedReason) {
      if (checkedReason === "Others") {
        if (rejectReason) {
          onSubmitReject()
        } else {
          setFieldError('Please select your reason for rejection');
        }
      } else {
        onSubmitReject()
      }
    }
    else {
      setFieldError('Please enter select reason for rejection');
    }
  }

  const onTextFieldChange = (event: any) => {

    let currentValue: any = event.target.value;
    if (currentValue) {
      setRejectReason(currentValue)
      setFieldError("")
    } else {
      setRejectReason(currentValue)
      setFieldError('Please enter your reason for rejection');
    }
  }


  return (
    <div>
      <p className="!text-center">Please select a reason from below</p>
      <div className="description">
        <Checkbox.Group className="checkbox-group" value={checkedReason} style={{ width: "100%" }}>
          {rejectReasonList.map((item: any, index: number) => (
            <Checkbox value={item.value} onChange={(e) => selectChange(e)}>
              {item.value}
            </Checkbox>
          ))}
        </Checkbox.Group>
        {
          isActiveText && <TextArea name="description" rows={3} value={rejectReason} onChange={(e) => onTextFieldChange(e)} placeholder="Enter your message here if you can't find an appropriate message in the above options" maxLength={1200} />
        }

        {fieldError ? <div className="error">{fieldError}</div> : null}
      </div>
      <div className="modal-footer">
        <div className="action-btns">
          <Button className="btn-secondary fixed-width" onClick={cancelAction}>
            Cancel
          </Button>
          <Button type="primary" className="fixed-width" loading={false} name="Submit" disabled={false} onClick={() => submitRejectReason()}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
