import React, { useEffect, useState } from "react";
import {
  FilePdfOutlined,
  DownloadOutlined,
  CloseOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import EndpointService from "../../../utilities/constants/endpointService";
import HTTPService from "../../../service/HTTPService";
import { Spin, message } from "antd";
import moment from 'moment';
import environment from "../../../environments/environment";
import { cypherService } from "../../../utilities/cypher/cypherService";
import MessageHeader from "./MessageHeader";
import MessageLoader from "../../loader/MessageLoader";
import Linkify from "./Linkify";
let imageBaseUrl = environment.imageBaseUrl;
export default function Message() {
  const jitsiUrl = environment.profileName === "Stage" ? "https://jitsi.dev.cloudnowtech.com/" : "https://jitsi.solved.ai/"
  const userId = cypherService.getLocalStorage("userId");
  const messageServiceProviderName = cypherService.getSessionStorage("messageServiceProviderName");
  const selectionPartyName = cypherService.getSessionStorage('SelectionPartyName');
  const { messageMasterId, messageNotificationId } = useParams()
  const [loader, setLoader] = useState(false);
  const [messageLoader, setMessageLoader] = useState<any>(false)
  const [inputValue, setInputValue] = useState("");
  const [contentValue, setContentValue] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [singleConversationData, setSingleConversationData] = useState<any>([]);
  useEffect(() => {
    getMessageDetails();
  }, [messageNotificationId]);

  const getMessageDetails = () => {
    setLoader(true);
    let mapUrl = EndpointService.getSingleConversationList + `?limit=10&messageNotificationId=${messageNotificationId}&start=0`;
    HTTPService.get(mapUrl)
      .then((response) => {
        if (response.status.success.toLowerCase() == 'success') {
          setSingleConversationData(response.data);
          setLoader(false);
        }
      })
      .catch((errorResponse) => {
        setLoader(false);
        console.log(errorResponse);
        throw errorResponse;
      });
  };

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
    setContentValue(event.target.value)
  };
  const handleFileSelected = (event: any) => {
    const file: any = Array.from(event.target.files);

    setSelectedFile(file);
    // setSelectedFileName(file.name);
  };
  const handleCancelSelection = () => {
    setSelectedFile([]);
  };

  const onSubmit = (event: any) => {
    let content = contentValue
    event.preventDefault();
    submitMessage(content)
  };

  const submitMessage = (content: any) => {
    let formData = new FormData();
    if (content) {
      let userString = localStorage.getItem('loggedInSession');
      let user = userString && JSON.parse(userString);
      let messageNotificationId = singleConversationData[0].messageNotificationId;
      let payload: any = { content: content, messageNotificationId: messageNotificationId, createdBy: userId };
      let filesObj: any = selectedFile[0] ? selectedFile[0] : {};
      formData.append('multipartFile', filesObj);
      setMessageLoader(true)
      let mapUrl: string = EndpointService.getConversationStart + encodeURIComponent(JSON.stringify(payload));

      HTTPService.post(mapUrl, formData)
        .then((response) => {
          const getStatus = response.status.success.toLowerCase();
          if (getStatus == 'success') {
            setMessageLoader(false)
            setInputValue('');
            getMessageDetails();
            setSelectedFile([]);
          }
        })
        .catch((err) => {
          setMessageLoader(false)
          message.error(err.response.data.status.message);
        });
    }
  }

  const getFileName = (el: any) => {
    const link = el;
    const fileName = link.substring(link.lastIndexOf('/') + 1);
    return fileName && fileName.split('.')[0];
  };
  const getFileExtensionName = (el: any) => {
    const link = el;
    const fileName = link.substring(link.lastIndexOf('/') + 1);
    return fileName && fileName.split('.')[1];
  };
  const downloadImage = (el: any) => {
    let url = el;
    window.open(url, '_blank');
  };



  const MessageSendName = (el: any) => {
    let name: string | null = '';
    if (el.createdBy == userId) {
      name = 'Admin';
    } else {
      name = messageServiceProviderName;
    }
    return name ? name : '-';
  };

  const senderName = (el: any) => {
    let name: string | null = '';
    if (el.createdBy == userId) {
      name = 'Ad';
    } else {
      name = messageServiceProviderName;
    }
    return (
      <div className="vendor-icon">
        <span className={name === 'Ad' ? 'yellow' : ''}>{name ? name.substring(0, 2).toUpperCase() : '-'}</span>
      </div>
    );
  };



  const handleMeetingGenerate = () => {
    setContentValue(jitsiUrl + userId)
    submitMessage(jitsiUrl + userId)
  }


  return (
    <>
      {loader ? <Spin /> : null}
      <MessageHeader redirect={true} />
      <div className="notification-block">
        {singleConversationData &&
          singleConversationData.map((item: any) => (
            <div className="notification-message">
              {senderName(item)}
              <div className="vendor-message">
                <div className="vendor-det">
                  <p className="capitalize">{MessageSendName(item)}</p>
                  <p>on {moment(item.createdOn).format('llll')}</p>
                </div>
                <div className="credit_details">
                  <div className="credits-det">
                    <p className="message"> <Linkify text={item.content} /></p>
                    {item.attachment && (
                      <div className="files-section">
                        <div className="file-details">
                          <div className="file-img">
                            <span>{getFileExtensionName(item.attachment) === 'pdf' ? <FilePdfOutlined /> : <FileImageOutlined />}</span>
                          </div>
                          <div className="file-name">
                            <p>{getFileName(item.attachment)}</p>
                            <p className="uppercase">{getFileExtensionName(item.attachment)}</p>
                          </div>
                          <div className="download-icon">
                            <button onClick={() => downloadImage(imageBaseUrl + item.attachment)}>
                              <DownloadOutlined />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="chat-box relative">
        {
          messageLoader && <div className="w-full absolute top-0">
            <MessageLoader />
          </div>
        }

        <div className="textfield rounded-lg">
          <form onSubmit={onSubmit} className="relative">
            <textarea value={inputValue} className="block  p-4  " placeholder="Send a message..." onChange={handleChange}>
            </textarea>
            <button type="submit">
              <span className="send">
                <span className="material-symbols-outlined">
                  send
                </span>
              </span>
            </button>
          </form>
          {selectedFile &&
            selectedFile.map((elm: any) => (
              <div className="img-sec">
                <div className="card ">
                  <p className="oneLine">{elm.name}</p>
                  <button onClick={handleCancelSelection} disabled={messageLoader}>
                    <CloseOutlined />
                  </button>
                </div>
              </div>
            ))}
          <span className="file">
            <label htmlFor="file-selector">
              <span className="material-symbols-outlined">
                attach_file
              </span>
            </label>
            <input id="file-selector" type="file" accept="image/*,.pdf" onChange={handleFileSelected} style={{ display: 'none' }} />
          </span>
        </div>
        {cypherService.getSessionStorage('notificationHeaderName') === "Customer Support" && <div className="link" onClick={handleMeetingGenerate}>link</div>}
      </div>
    </>
  );
}
