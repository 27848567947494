import { Button, Card } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ErrorMessage } from "formik";
import React from "react";
import QuestionHeader from "../../../schoolDetails/layout/QuestionHeader";
import { getPlaceHolder } from "../../helper";

export default function FormControlTextArea(props: any) {
    const { item, questionList, setQuestionList, index, handleChange, changeTextValue, value, visible, isEdit } = props;
    return (
        <>
            <Card className='mb-5' title={<>{QuestionHeader(item)}</>} bordered={false} style={{ width: "100%" }}>
                {/* Answer */}
                <div className='answer-field'>
                    <TextArea
                        placeholder={getPlaceHolder(item)}
                        id={item.code}
                        value={value}
                        name={item.code}
                        onChange={(e: any) => {
                            changeTextValue(e.target.value, questionList, setQuestionList, index);
                            handleChange(e);
                        }}
                        autoSize={{ minRows: 4 }}
                    />
                    <div className='error'>
                        <ErrorMessage name={item.code} />
                    </div>
                </div>
                {item.code === "fullDescription" && !visible && (
                    <Button className='btn-primary m-0' htmlType='submit'>
                        Update the Priority
                    </Button>
                )}
            </Card>
        </>
    );
}
