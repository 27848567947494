import { CloseOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, notification, Spin } from "antd";
import React, { useState } from "react";

import { assetsIcons } from "../../../../../assets/images/icons/index";
import QuestionHeader from "../../../../schoolDetails/layout/QuestionHeader";
import { handleMediaChange } from "../helper";
import user from "../../../../../assets/images/icons/user.svg";
import school from "../../../../../assets/images/icons/school.svg";
import EndpointService from "../../../../../utilities/constants/endpointService";
import HTTPService from "../../../../../service/HTTPService";
import infoGreen from "../../../../../assets/images/icons/info-green.svg";
import ReactPlayer from "react-player";
import ApprovedStatus from "./approvedIcons/ApprovedStatus";
import TestimonialFooter from "./TestimonialFooter";
export default function Testimonial(props: any) {
  let uploadObj = {
    name: "",
    designation: "",
    institutionName: "",
    email: "",
    uploadContent: "",
    type: "",
    files: null,
    filePath: null,
    fileName: "",
    changes: false,
    edit: false,
  };
  const { item, questionList, setQuestionList, index, setFieldValue, setErrors, errors, isEdit, editInit } = props;
  let errorObject = [
    {
      name: "name",
      validation: false,
      message: "",
    },
    {
      name: "designation",
      validation: false,
      message: "",
    },
    {
      name: "institutionName",
      validation: false,
      message: "",
    },
    {
      name: "email",
      validation: false,
      message: "",
    },
    {
      name: "video",
      validation: false,
      message: "",
    },
    {
      name: "uploadContent",
      validation: false,
      message: "",
    },
    {
      name: "pdf",
      validation: false,
      message: "",
    },
  ];
  const [activeUpload, setActiveUpload] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>("video");
  const [uploadPreview, setUploadPreview] = useState<any>({ ...uploadObj });
  const [formErrors, setFormErrors] = useState<any>([...errorObject]);
  const [loader, setLoader] = useState<boolean>(false);
  const [playVideo, setPlayVideo] = useState<number>();
  const [isValidVideo, setIsValidVideo] = useState<boolean>(true);
  const handleClickUpload = () => {
    setActiveUpload(true);
  };
  const handleSelectType = (type: string) => {
    setUploadPreview({ ...uploadPreview, uploadContent: "", type: "", files: null, filePath: null, fileName: "", changes: false, edit: false });
    setSelectedType(type);
    setFormErrors([...errorObject]);
  };

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const handleOnchange = (el: any) => {
    let elName: string = el.target.name;
    let elValue: string = el.target.value;

    setUploadPreview({
      ...uploadPreview,
      type: selectedType,
      ...(elName === "link" && { filePath: elValue }),
      [elName]: elValue,
    });
    if (elValue.length > 0) {
      let validationSchema: any = formErrors;
      validationSchema &&
        validationSchema.map((item: any, index: number) => {
          if (item.name === elName) {
            validationSchema[index].message = ``;
            validationSchema[index].validation = false;
          }
        });

      setFormErrors([...validationSchema]);
    }
  };

  const handlePreviewClose = () => {
    setIsValidVideo(true);
    setFormErrors([...errorObject]);
    let obj = {
      files: null,
      filePath: null,
      fileName: "",
      videoUrl: "",
    };
    setUploadPreview({ ...uploadPreview, ...obj });
  };

  const validationCheck = (validationSchema: any, name: any, validationName: any) => {
    return (
      validationSchema &&
      validationSchema.map((item: any, index: number) => {
        if (item.name === name) {
          validationSchema[index].validation = true;
          validationSchema[index].message = `${validationName}`;
        }
      })
    );
  };

  const handleAddUploadObj = () => {
    let validationSchema = formErrors;

    if (!uploadPreview.name) {
      validationCheck(validationSchema, "name", "Name is required");
    }

    if (!uploadPreview.designation) {
      validationCheck(validationSchema, "designation", "Designation is required");
    }
    if (!uploadPreview.institutionName) {
      validationCheck(validationSchema, "institutionName", "Institution Name is required");
    }

    if (!uploadPreview.email) {
      validationCheck(validationSchema, "email", "Email Id is required");
    } else {
      if (!validateEmail(uploadPreview.email)) {
        validationCheck(validationSchema, "email", "Email Id is invalid");
      }
    }

    if (!uploadPreview.filePath && selectedType === "video") {
      validationCheck(validationSchema, "video", "Video is required");
    }
    if (!uploadPreview.filePath && selectedType === "pdf") {
      validationCheck(validationSchema, "pdf", "Pdf is required");
    }
    if (!uploadPreview.uploadContent && selectedType === "text") {
      validationCheck(validationSchema, "uploadContent", " Text Content is required");
    }
    let isValid = validationSchema.some((e: any) => (e.validation === false ? false : true));

    if (!isValid) {
      let isActive = true;
      let tempQuestionList = questionList;
      let tempQuestionSelectedList = tempQuestionList[index].answer;

      tempQuestionSelectedList &&
        tempQuestionSelectedList.forEach((item: any, ind: number) => {
          if (item.edit) {
            if (ind === uploadPreview.index) {
              isActive = false;
              item.name = uploadPreview.name;
              item.designation = uploadPreview.designation;
              item.institutionName = uploadPreview.institutionName;
              item.email = uploadPreview.email;
              item.uploadContent = uploadPreview.uploadContent;
              item.type = uploadPreview.type;
              item.files = uploadPreview.files;
              item.filePath = uploadPreview.filePath;
              item.fileName = uploadPreview.fileName;
              item["changes"] = true;
            }
          }
        });

      console.log(tempQuestionSelectedList);

      if (tempQuestionSelectedList && tempQuestionSelectedList.length > 0) {
        if (isActive) {
          let createPayload = uploadPreview;
          createPayload.changes = true;
          tempQuestionList[index].answer = [...tempQuestionSelectedList, uploadPreview];
        } else {
          let createPayload = tempQuestionSelectedList;
          createPayload.changes = true;
          tempQuestionList[index].answer = [...createPayload];
        }
      } else {
        let createPayload = uploadPreview;
        createPayload.changes = true;
        tempQuestionList[index].answer = [{ ...createPayload }];
      }
      setQuestionList([...tempQuestionList]);
      setUploadPreview({ ...uploadObj });
      setActiveUpload(false);
    }

    setFormErrors([...validationSchema]);
  };

  const handleSelectedRemove = (el: any, elIndex: number) => {
    let tempQuestionList = questionList;
    let productTestimonialId: any = null;
    let result =
      el &&
      el.filter((list: any, ind: any) => {
        if (elIndex !== ind) {
          return list;
        }
        productTestimonialId = list.serviceProviderProductTestimonialId;
      });
    tempQuestionList[index].answer = [...result];
    setQuestionList([...tempQuestionList]);
    productTestimonialId && isEdit && deleteTestimonial(productTestimonialId);
  };

  const deleteTestimonial = (productTestimonialId: any) => {
    setLoader(true);
    const mapUrl = EndpointService.deleteTestimonialUrl + productTestimonialId;
    HTTPService.delete(mapUrl)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          setLoader(false);
          editInit();
          notification.success({
            message: "",
            description: response.status.message,
            placement: "top",
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        notification.error({
          message: "",
          description: err.response.data && err.response.data.status.message,
          placement: "top",
        });
      });
  };

  const handleEdit = (el: any, index: number) => {

    let save: any = null;
    el &&
      el.forEach((item: any, itemIndex: number) => {
        if (index === itemIndex) {
          if (!item.approved) {
            item["index"] = index;
            item["edit"] = true;
            save = item;
            handleClickUpload();
            setSelectedType(item.type);
          }
        }
      });
    setUploadPreview({ ...save });
  };
  const openPreview = (pdfUrl: any) => {
    let documentObject: any = {};
    if (pdfUrl && pdfUrl.substr(pdfUrl.lastIndexOf(".") + 1) == "pdf") {
      documentObject["type"] = "pdf";
      let url = pdfUrl;
      documentObject["url"] = `https://docs.google.com/gview?url=${encodeURI(url)}&embedded=true`;
    }

    return documentObject.url;
  };
  const updateIframeSrc = () => {
    let iframe: any = document.getElementById("iFrame");
    if (iframe != null) {
      iframe.src = iframe.src;
    }
  };
  const selectedOption = (el: any) => {
    return (
      el &&
      el.map((item: any, index: number) => {
        if (item.type === "video") {
          return (
            <div className="imageUpload-section testimonials">
              <div className="uploadedVideo preview my-4">
                <div className="overlay">
                  {/* <video src={item.filePath} width="200" controls /> */}
                  <ReactPlayer
                    className="reactPlayer"
                    url={item.filePath}
                    controls={true}
                    playing={playVideo === index ? true : false}
                    width="100%"
                    height="100%"
                    config={{
                      youtube: {
                        playerVars: { showinfo: 1 },
                      },
                    }}
                  />

                  <i className="play-button">
                    <img src={assetsIcons.playButton} alt="play button" />
                  </i>
                </div>
                <span onClick={() => handleSelectedRemove(el, index)}>
                  <CloseOutlined />
                </span>
                <TestimonialFooter user={user} school={school} item={item} />
              </div>
            </div>
          );
        } else if (item.type === "pdf") {
          return (
            <div className="uploadedVideo preview !contents  my-4">
              {/* <pre>{JSON.stringify(item, null, 1)}</pre> */}
              <div className="overlay">
                <div className="overlay-pdf">
                  <div className="pdf">
                    {isEdit && item && item.filePath && <iframe id="iFrame" title="DocumentPreview" onError={updateIframeSrc} className="document-preview-container" src={openPreview(item.filePath)} />}
                    <i className="play-button">
                      <img src={assetsIcons.pdf} alt="play button" />
                    </i>
                    <span className="close-file" onClick={() => handleSelectedRemove(el, index)}>
                      <CloseOutlined />
                    </span>
                    <TestimonialFooter user={user} school={school} item={item} />
                  </div>
                </div>
              </div>
            </div>
          );
        } else if (item.type === "text") {
          return (
            <div className="uploadedVideo preview  my-4">
              <div className="overlay" onClick={() => handleEdit(el, index)}>
                <div className="content-area text">
                  <p className="px-6 pt-3 pb-3 w-full">{item.uploadContent}</p>
                </div>
                <TestimonialFooter user={user} school={school} item={item} />
              </div>
              <span className="close-file" onClick={() => handleSelectedRemove(el, index)}>
                <CloseOutlined />
              </span>
            </div>
          );
        }
      })
    );
  };

  const formErrorMessage = (name: string, el: any) => {
    return (
      el &&
      el.map((item: any, index: number) => {
        if (item.name === name && item.validation) {
          return <div className="error  w-full">{item.message}</div>;
        }
      })
    );
  };

  const handleErrorCheck = (el: any) => {
    setIsValidVideo(false);
  };
  return (
    <>
      {loader ? <Spin /> : null}
      <Card
        className="testimonials-card p-12 mb-5"
        title={
          <>
            <QuestionHeader answer="" question="Testimonials" />
          </>
        }
        bordered={false}
        style={{ width: "100%" }}
      >
        <div>
          {!activeUpload ? (
            <>
              <div className="flex items-center flex-wrap ">
                {item.answer.length > 0 && selectedOption(item.answer)}
                <div className="imageUpload-section" onClick={handleClickUpload}>
                  <div className="uploadedImage-preview">
                    <div className="imageUpload-div">
                      <PlusOutlined className="text-green" />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="imageUpload-section testimonials">
              <div>
                <div className="TextInputDiv" data-swiper-parallax-y="-150" data-swiper-parallax-duration="1000">
                  <div className="flex flex-wrap justify-between -mx-8 w-10/12">
                    <div className="w-1/2 px-8">
                      <div className="textInput-container">
                        <input type="text" maxLength={50} className="TextInput  react-google-login " name="name" placeholder="Name" value={uploadPreview.name && uploadPreview.name} onChange={(e) => handleOnchange(e)} />

                        <span className="TextInputSpan"></span>
                        <span className="hoverStyle"></span>
                        {formErrorMessage("name", formErrors)}
                      </div>
                    </div>
                    <div className="w-1/2 px-8">
                      <div className="textInput-container">
                        <input maxLength={100} type="text" className="TextInput react-google-login " name="email" value={uploadPreview.email && uploadPreview.email} placeholder="Email ID" onChange={(e) => handleOnchange(e)} />
                        <span className="TextInputSpan"></span>
                        <span className="hoverStyle"></span>
                        {formErrorMessage("email", formErrors)}
                      </div>
                      <div className="info"><span><img src={infoGreen} alt="info green" /> </span>SolvEd reserves the right to independently verify this testimonial.</div>

                    </div>
                    <div className="w-1/2 px-8">
                      <div className="textInput-container">
                        <input maxLength={100} type="text" className="TextInput react-google-login " name="designation" value={uploadPreview.designation && uploadPreview.designation} placeholder="Designation" onChange={(e) => handleOnchange(e)} />
                        <span className="TextInputSpan"></span>
                        <span className="hoverStyle"></span>
                        {formErrorMessage("designation", formErrors)}
                      </div>
                    </div>
                    <div className="w-1/2 px-8">
                      <div className="textInput-container">
                        <input maxLength={100} type="text" className="TextInput react-google-login " name="institutionName" value={uploadPreview.institutionName && uploadPreview.institutionName} placeholder="Institution Name" onChange={(e) => handleOnchange(e)} />
                        <span className="TextInputSpan"></span>
                        <span className="hoverStyle"></span>
                        {formErrorMessage("institutionName", formErrors)}
                      </div>
                    </div>

                    <div className="w-full px-8 pt-10 mb-3">
                      <div className="groupcheckbox-main">
                        <div className="Text-checkboxGroup group-checkbox !pb-0" role="group" aria-labelledby="checkbox-group">
                          <label>
                            <input type="checkbox" checked={selectedType === "video" ? true : false} name="video" onChange={() => handleSelectType("video")} />
                            <span>Video</span>
                          </label>
                          <label>
                            <input type="checkbox" checked={selectedType === "pdf" ? true : false} name="pdf" onChange={() => handleSelectType("pdf")} />
                            <span>PDF</span>
                          </label>
                          <label>
                            <input type="checkbox" checked={selectedType === "text" ? true : false} name="text" onChange={() => handleSelectType("text")} />
                            <span>Text</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {selectedType === "video" && (
                <>
                  {!uploadPreview.filePath ? (
                    <>
                      <div className="upload-widgets !block relative">
                        <div className="imageUpload-div">
                          <UploadSection format="Video" size={20} />
                          <input
                            className="FileUpload1"
                            id={item.code}
                            type="file"
                            onChange={(value) => {
                              handleMediaChange(value, questionList, setQuestionList, index, setFieldValue, item.code, item, setErrors, errors, setUploadPreview, uploadPreview, selectedType, formErrors, setFormErrors);
                            }}
                          />
                        </div>
                        <div className="text-center block w-6/12 pt-5 pb-6">OR</div>
                        <div className="url-link-main relative">
                          <input name="link" className="url-link" placeholder="Url here" type="text" onChange={(e) => handleOnchange(e)} />
                          <span className="TextInputSpan"></span>
                          <i className="absolute top-4 left-4">
                            <img src={assetsIcons.linkOutline} alt="link" />
                          </i>
                        </div>
                        {formErrorMessage("video", formErrors)}
                      </div>
                    </>
                  ) : (
                    <div className="uploadedVideo preview mb-4">
                      <div className="overlay">
                        {uploadPreview.link ? (
                          <ReactPlayer
                            className="reactPlayer"
                            url={uploadPreview.filePath}
                            controls={true}
                            onError={(error) => {
                              handleErrorCheck(error);
                            }}
                            playing={playVideo === 10000 ? true : false}
                            width="100%"
                            height="100%"
                            config={{
                              youtube: {
                                playerVars: { showinfo: 1 },
                              },
                            }}
                          />
                        ) : (
                          <video src={uploadPreview.filePath} width="200" controls />
                        )}

                        <i className="play-button">
                          <img src={assetsIcons.playButton} alt="play button" />
                        </i>
                      </div>
                      <span onClick={handlePreviewClose}>
                        <CloseOutlined />
                      </span>
                      {!isValidVideo && <div className="error">Invalid Video</div>}
                    </div>
                  )}
                </>
              )}

              {selectedType === "pdf" && (
                <>
                  {uploadPreview.filePath ? (
                    <div className="uploadedVideo preview mb-4">
                      <div className="overlay">
                        <div className="pdf">
                          {/* <>
                                                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                                                                    <Viewer fileUrl={uploadPreview.filePath} />
                                                                </Worker>
                                                            </> */}
                        </div>
                        <i className="play-button">
                          <img src={assetsIcons.pdf} alt="play button" />
                        </i>
                      </div>
                      <span onClick={handlePreviewClose}>
                        <CloseOutlined />
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className="upload-widgets !block mb-4">
                        <div className="imageUpload-div">
                          <UploadSection format="PDF" size={3} />
                          <input
                            className="FileUpload1"
                            id={item.code}
                            type="file"
                            onChange={(value) => {
                              handleMediaChange(value, questionList, setQuestionList, index, setFieldValue, item.code, item, setErrors, errors, setUploadPreview, uploadPreview, selectedType);
                            }}
                          />
                        </div>
                        {formErrorMessage("pdf", formErrors)}
                      </div>
                    </>
                  )}
                </>
              )}

              {selectedType === "text" && (
                <>
                  <div className="registerInputSection textEditor" data-swiper-parallax-y="-150" data-swiper-parallax-duration="1000">
                    <div className="textareaInput" data-swiper-parallax-y="-150" data-swiper-parallax-duration="1000">
                      <textarea maxLength={2000} id="description" placeholder="Enter text here" className="ant-input mb-4" name="uploadContent" value={uploadPreview.uploadContent && uploadPreview.uploadContent} onChange={(e) => handleOnchange(e)} />
                    </div>
                    {formErrorMessage("uploadContent", formErrors)}
                  </div>
                </>
              )}
              <div className="block mt-8">
                {isValidVideo && (
                  <Button className="btn-primary m-0 !ml-0" onClick={handleAddUploadObj}>
                    {uploadPreview ? `Update & preview` : "Add & preview"}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>

        {/* testimonials End */}
      </Card>
    </>
  );
}

function UploadSection(props: any) {
  return (
    <>
      <div className="flex items-center p-3 pr-1">
        <CloudUploadOutlined className="text-green" />
        <div className="ml-4 upload-cont">
          <p className="browse font-sans">
            Click to <span className="text-green">browse</span>
          </p>
          <p className="file-size font-sans">
            {props.format} <span className="inline-block mx-2">|</span> max {props.size}MB{" "}
          </p>
        </div>
      </div>
    </>
  );
}
