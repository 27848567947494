export const handleCompanyDescription = (event: any, questionList: any, setQuestionList: any, index: number) => {
  let tempQuestionList = questionList;
  let tempAnswer = event;
  tempQuestionList[index].answer = tempAnswer;
  setQuestionList([...tempQuestionList]);
};
export const getPlaceHolder = (data: any) => {
  if (data.code === "companyName") {
    return "Enter company name";
  } else if (data.code === "companyLocation") {
    return "Select location";
  } else if (data.code === "emailId") {
    return "Enter email id";
  } else if (data.code === "personName") {
    return "Enter name";
  } else if (data.code === "emirate") {
    return "Select emirate";
  }
};
