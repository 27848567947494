import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { messageListsReducer, messageReducer } from './message/messageReducer';
import { notificationsReducer } from './notifications/notificationsReducer';

let allReducers = combineReducers({
  message: messageReducer,
  notifications: notificationsReducer,
  messageLists: messageListsReducer,
});

const store = createStore(allReducers, applyMiddleware(thunk, logger));
export default store;
