import { Card } from 'antd';
import { ErrorMessage } from 'formik';
import { Input } from 'formik-antd';
import React from 'react'
import { getPlaceHolder } from '../../../schoolDetails/healper';
import QuestionHeader from '../../../schoolDetails/layout/QuestionHeader';

export default function FormControlInput(props: any) {
    const { item, questionList, setQuestionList, index, handleChange, changeTextValue, values } = props
    return (
        <>
            <Card
                className="mb-5"
                title={
                    <>
                        {QuestionHeader(item)}
                    </>
                }
                bordered={false}
            >
                {/* Answer */}
                <div className="answer-field">
                    <Input placeholder={getPlaceHolder(item)} name={item.code} id={item.code} value={values} onChange={(e: any) => { changeTextValue(e.target.value, questionList, setQuestionList, index); handleChange(e) }} />
                    <div className="error">
                        <ErrorMessage name={item.code} />
                    </div>
                </div>

            </Card>
        </>
    )
}
