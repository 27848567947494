import { GET_MESSAGE_SIDEBAR, GET_MESSAGE_LISTS } from "./messageTypes";

export const initialState: any = [];
export const messageReducer = (state = initialState, action: any) => {

    const { type, data } = action;

    switch (type) {
        case GET_MESSAGE_SIDEBAR:
            return {
                data
            }
        default:
            return state;
    }
}

export const  messageListsReducer = (state = initialState, action: any) => {
    const { type, data } = action;
    switch (type) {
        case GET_MESSAGE_LISTS:
            return {
                data
            }
            
        default:
            return state;
    }
}