import { Button, Checkbox, Spin, message, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import EndpointService from "../../utilities/constants/endpointService";
import HTTPService from "../../service/HTTPService";
import { cypherService } from "../../utilities/cypher/cypherService";
export default function CancelConfirmModal(props: any) {
  let userId = cypherService.getLocalStorage("userId");
  const { cancelAction, cancelMeetingObj, getUpcomingMeetingList } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<string>('');
  const [isActiveText, setIsActiveText] = useState<string>("")


  const onTextFieldChange = (event: any) => {
    let currentValue: any = event.target.value;
    if (currentValue) {
      setFieldError("")
    } else {
      setFieldError('Please enter your reason');
    }
    setIsActiveText(currentValue)
  }

  const handleRequestDelete = () => {
    setFieldError("")
    let cancelMeeting = cancelMeetingObj
    debugger
    let payloadUrl = ""
    if (isActiveText) {

      if (cancelMeeting.variant === "customerMeeting") {
        payloadUrl = `canceledReason=${isActiveText}&customerMeetingScheduleId=${cancelMeeting.customerMeetingScheduleId}&userDetailId=${userId}`
      } else {
        payloadUrl = `canceledReason=${isActiveText}&edTechExpertMeetingScheduleId=${cancelMeeting.edTechExpertMeetingScheduleId}&userDetailId=${userId}`
      }
      cancelMeetingApiRequest(payloadUrl)
    } else {
      setFieldError('Please enter your reason');
    }

  }

  const cancelMeetingApiRequest = (payloadUrl: any) => {
    let mapUrl: string = EndpointService.getCancelMeeting + payloadUrl

    setLoader(true);
    HTTPService.put(mapUrl, {}).then(response => {
      if (response.status.success.toLowerCase() === 'success') {
        debugger
        message.success(response.status.message)
        setIsActiveText("")
        setLoader(false);
        getUpcomingMeetingList(0, 10, "")
        cancelAction()

      }
      if (response.status.success.toLowerCase() === 'fail') {
        setLoader(false);
        cancelAction()
        notification.error({
          message: '',
          description: response.status.message,
          placement: 'top'
        })
      }
    }).catch(err => {
      setLoader(false);
      cancelAction()
      // notification.error({
      //     message: '',
      //     description: err.response.data.status.message,
      //     placement: 'top'
      // })
    })

  }



  return (
    <div>
      {loader ? <Spin /> : null}
      <p className="!text-center">Please enter your reason below</p>
      <div className="description">
        <TextArea name="description" rows={3} value={isActiveText} onChange={(e) => onTextFieldChange(e)} placeholder="Enter text  " maxLength={1200} />

        {fieldError ? <div className="error">{fieldError}</div> : null}
      </div>
      <div className="modal-footer">
        <div className="action-btns">
          <Button className="btn-secondary fixed-width" onClick={cancelAction}>
            Cancel
          </Button>
          <Button type="primary" className="fixed-width" loading={false} name="Submit" disabled={false} onClick={() => handleRequestDelete()}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
