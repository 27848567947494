import { Spin } from 'antd';
import React, { useState } from 'react';
import Header from "../header/Header";
import SideBar from './layout/SideBar';
import Message from './layout/Message';
export default function SingleMessageConversation() {
  const [loader, setLoader] = useState<boolean>(false);
  return (
    <>
      <div className="new-dashboar">
        {loader ? <Spin /> : null}

        <div className="notification-vendor">
          <div className="notifcation-sidebar">
            <SideBar />
          </div>
          <div className="notification-menu ">
            {/* <ExpertConversationsLists /> */}
            <Message />
            {/* <NotificationsLists /> */}
          </div>
        </div>
      </div>
    </>
  );
}
