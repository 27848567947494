import * as Yup from 'yup';

let atleastOneSpecialCharRegex = new RegExp("^(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.{8,})");
let bussinessEmail = new RegExp("^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$")

const loginSchema = Yup.object({
    email: Yup.string().required('Work email address is required')
        .email('Invalid email address'),
    password: Yup.string().required('Password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40"),
    partyDiscriminator: Yup.string()
});

const passwordSchema = Yup.object({
    password: Yup.string().required('Password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Must be atleast 8 characters')
        .max(30, "Password should be max 30")
        .matches(atleastOneSpecialCharRegex, "Must contain atleast 1 special character"),
    confirmPassword: Yup.string().required('Confirm password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Must be atleast 8 characters')
        .max(20, "Password should be max 20")
        .oneOf([Yup.ref('password')], 'Passwords do not match')
})

const forgotPwdSchema = Yup.object({
    email: Yup.string().required('Work email address is required')
        .email('Invalid email address')
        .matches(bussinessEmail, "Please enter work email"),
})
export interface ILogin {
    email: string;
    password: string;
};
export interface IForgot {
    email: string;
};
export interface IOtp {
    otpNumber: string;
}
export interface IForgotOtpObj {
    email: string;
    secretKey: string;
}
export interface Ipassword {
    password: string;
    confirmPassword: string;
    email: string;
};
export const Schema = {
    loginSchema, passwordSchema, forgotPwdSchema
}