import { useEffect, useState } from 'react'
import Header from '../header/Header';
import { Input, Steps, Radio, Button, message, Spin } from 'antd';
import EndpointService from '../../utilities/constants/endpointService';
import HTTPService from '../../service/HTTPService';
import { sortBy } from 'lodash';
import EmiratesList from './EmiratesList';
import { useNavigate } from 'react-router-dom';
import { cypherService } from '../../utilities/cypher/cypherService';
import countryList from './CountryList';
import { DeleteOutlined } from '@ant-design/icons';
import UaeEmiratesList from './UaeEmiratesList';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { BsArrowRight } from 'react-icons/bs';
import { AddServiceProviderInitialValues, AddServiceProviderValidationSchema } from './AddServiceProviderSchema';
import { changeTextValue, changeUaeCountry, clearCountry, clearUaeCountry } from '../../hooks/country';
import { deletePartnerRow, gccNoOptionQuestionChange, partnerLocationChange, partnerLocationClear, partnerNameChange, radioChange } from '../../hooks/GCCToggle';

const { Step } = Steps;

const AddServiceProvider = () => {
    const userDetailID = cypherService.getSessionStorage('customerId');
    const navigate = useNavigate();
    const [loader, setLoader] = useState<boolean>(false);
    const [questionList, setQuestionList] = useState<any>([]);
    const [gccToggle, setGccToggle] = useState<boolean | null>(null);
    const [partnerField, setPartnerField] = useState<any>([{ "Location": '', "Partner": '' }]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [method, setMethod] = useState<string>('');
    const [customerId, setCustomerId] = useState<any>(null);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [editServiceProviderAnswerObject, setEditServiceProviderAnswerObject] = useState<any>(null);
    const [initAddServiceValue, setInitLoginFormValue] = useState<AddServiceProviderInitialValues>({
        personName: '',
        emailId: '',
        companyName: '',
        companyLocation: '',
        uaeEmirate: '',
        channelPartner: '',
        findingPartner: '',
        partnerInfo: [{
            "Location": '',
            "Partner": ''
        }]
    });

    useEffect(() => {
        getAddServiceProviderQuestions();
    }, [])

    const getAddServiceProviderQuestions = () => {
        setLoader(true);
        let isEditValue: any = cypherService.getSessionStorage('isServiceProviderEdit') == 'true' ? true : false;
        setIsEdit(isEditValue);
        let tempSelectedAnsers: any = cypherService.getSessionStorage('editServiceProviderObject');
        let finalSelectedAnsers: any = JSON.parse(tempSelectedAnsers);
        let mapUrl = EndpointService.getCustomerAssessmentUrl + 'ServiceProviderCompanyAssessment/requiredToDisplayQuestionsFor?requiredToDisplayQuestionsFor=Admin';
        HTTPService.get(mapUrl).then(response => {
            setLoader(false);
            let tempsortedResponse: any = sortBy(response.data, 'displaySequence');
            let sortedResponse = tempsortedResponse
            tempsortedResponse.forEach((element: any, index: number) => {
                if (element.componentType == 'Text') {
                    sortedResponse[index]['answer'] = '';
                }
                if (element.componentType == 'Countrychecking') {
                    sortedResponse[index]['isUae'] = false;
                    sortedResponse[index]['isUaeAnswer'] = '';
                    sortedResponse[index]['answer'] = null;
                }
                if (element.componentType == 'GCC') {
                    sortedResponse[index]['answer'] = ''
                    tempsortedResponse.forEach((data: any) => {
                        if (data.componentType == "YesNoRelatedQuestion") {
                            sortedResponse[index]["selectNoOption"] = data;
                            sortedResponse[index]["selectNoAnswer"] = '';
                        }
                    })
                }
            });

            let finalSortedResponse: any = [];
            sortedResponse.forEach((data: any) => {
                if (data.componentType !== "YesNoRelatedQuestion") {
                    finalSortedResponse.push(data);
                }
            });
            let finalResponse: any = [];
            console.log(finalSelectedAnsers)
            if (isEditValue) {
                setIsActive(finalSelectedAnsers.isActive);
                setCustomerId(finalSelectedAnsers.serviceProviderId);
                setAnswerObjectPayloadId(finalSelectedAnsers);
                let tempEditAnswerObjectResponse = setEditAnswerObject(finalSortedResponse, finalSelectedAnsers);
                finalResponse = tempEditAnswerObjectResponse;
            }
            else {
                finalResponse = finalSortedResponse;
            }

            setQuestionList(finalResponse);


            console.log(finalResponse)
        })
    }

    const setAnswerObjectPayloadId = (finalSelectedAnsers: any) => {
        let tempServiceProviderPayloadId: any = {};
        finalSelectedAnsers.serviceProviderQuestionaireList.forEach((item: any) => {
            if (item.questionaireOptionId !== null) {
                tempServiceProviderPayloadId[item.questionaireOptionId.toString()] = item.serviceProviderQuestionaireId;
            }
        }
        );
        setEditServiceProviderAnswerObject(tempServiceProviderPayloadId);
    };

    const setEditAnswerObject = (questionObject: any, answerObject: any) => {
        let tempQuestionObject: any = questionObject;
        let tempInitialValues: any = initAddServiceValue;
        questionObject.forEach((item: any, index: number) => {
            if (tempQuestionObject[index].code === "personName") {
                tempQuestionObject[index].answer = answerObject.name;
                tempInitialValues.personName = answerObject.name;
            }
            else if (tempQuestionObject[index].code === "emailId") {
                tempQuestionObject[index].answer = answerObject.email;
                tempInitialValues.emailId = answerObject.email;
            }
            else if (tempQuestionObject[index].code === "companyName") {
                tempQuestionObject[index].answer = answerObject.companyName;
                tempInitialValues.companyName = answerObject.companyName;
            }
            else if (tempQuestionObject[index].code === "companyLocation") {
                answerObject.serviceProviderQuestionaireList.forEach((data: any) => {
                    if (data.questionaireOption && data.questionaireOption.optionGroup == "Location") {
                        if (data.questionaireOption.option == "OtherText") {
                            tempInitialValues.companyLocation = data.otherText
                            tempQuestionObject[index].answer = data.otherText;
                            tempQuestionObject[index].isUae = false;
                            tempQuestionObject[index].isUaeAnswer = '';
                        }
                        else {
                            tempInitialValues.companyLocation = 'United Arab Emirates';
                            tempInitialValues.uaeEmirate = data.otherText;
                            tempQuestionObject[index].answer = 'United Arab Emirates';
                            tempQuestionObject[index].isUae = true;
                            tempQuestionObject[index].isUaeAnswer = data.otherText;
                        }
                    }
                })
            }
            else if (tempQuestionObject[index].code === "channelPartner") {
                answerObject.serviceProviderQuestionaireList.forEach((data: any) => {
                    if (data.questionaireOption && data.questionaireOption.optionGroup == "GCC") {
                        tempInitialValues.channelPartner = data.questionaireOption.option
                        tempQuestionObject[index].answer = data.questionaireOption.option;
                        if (data.questionaireOption.option == "No") {
                            tempQuestionObject[index].selectNoAnswer = data.otherText;
                            tempInitialValues.findingPartner = data.otherText;
                            setGccToggle(false);
                        }
                        else {
                            setGccToggle(true);
                            setPartnerField(JSON.parse(data.otherText));
                            tempInitialValues.partnerInfo = JSON.parse(data.otherText);
                        }
                    }
                })
            }
        });
        setInitLoginFormValue(tempInitialValues);
        return tempQuestionObject;
    }

    const addNewPartnerField = (arrayHelpers: any) => {
        arrayHelpers.push({ 'Location': '', 'Partner': '' });
        let tempPartnerFields = partnerField;
        let newPartnerField: any = {}
        newPartnerField['Location'] = '';
        newPartnerField['Partner'] = '';
        tempPartnerFields.push(newPartnerField);
        setPartnerField([...tempPartnerFields]);
    }


    const onSubmit = (resetForm: any) => {
        setLoader(true);
        let serviceProviderAssessmentDto: any = {
            "serviceProviderCompanyInfoDto": {},
            "serviceProviderQuestionaireDto": []
        };
        let name: string = '';
        let email: string = '';
        let editServiceProviderObjectKeys: any = editServiceProviderAnswerObject ? Object.keys(editServiceProviderAnswerObject) : [];
        questionList.forEach((element: any) => {
            if (element.question == "Contact person name") {
                name = element.answer
            }
            else if (element.question == "Email ID") {
                email = element.answer
            }
            else if (element.questionGroup == "INFO-Location") {
                let temp: any = {
                    "questionaireOptionId": 0,
                    "otherText": ""
                };
                if (element.isUae) {
                    element.questionaireOption.forEach((data: any) => {
                        if (data.option == 'UAE') {
                            temp = {
                                "questionaireOptionId": data.questionaireOptionId,
                                "otherText": element.isUaeAnswer
                            }
                        }
                    })
                }
                else {
                    element.questionaireOption.forEach((data: any) => {
                        if (data.option !== 'UAE') {
                            temp = {
                                "questionaireOptionId": data.questionaireOptionId,
                                "otherText": element.answer
                            }
                        }
                    })
                }
                if (editServiceProviderObjectKeys.includes(temp.questionaireOptionId.toString())) {
                    temp['serviceProviderQuestionaireId'] = editServiceProviderAnswerObject[temp.questionaireOptionId];
                }
                serviceProviderAssessmentDto.serviceProviderQuestionaireDto.push(temp);
            }
            else if (element.questionGroup == 'INFO-Name') {
                serviceProviderAssessmentDto.serviceProviderCompanyInfoDto = {
                    "companyName": element.answer
                }
            }
            else if (element.questionGroup == 'INFO-Partners') {
                let temp: any = {
                    "questionaireOptionId": 0,
                    "otherText": ''
                }
                if (!gccToggle) {
                    element.questionaireOption.forEach((data: any) => {
                        if (data.option == 'No') {
                            temp.questionaireOptionId = data.questionaireOptionId;
                            temp.otherText = element.selectNoAnswer;
                        }
                    })
                }
                else {
                    element.questionaireOption.forEach((data: any) => {
                        if (data.option == 'Yes') {
                            temp.questionaireOptionId = data.questionaireOptionId;
                            temp.otherText = JSON.stringify(partnerField);
                        }
                    })
                }
                if (editServiceProviderObjectKeys.includes(temp.questionaireOptionId.toString())) {
                    temp['serviceProviderQuestionaireId'] = editServiceProviderAnswerObject[temp.questionaireOptionId];
                }
                serviceProviderAssessmentDto.serviceProviderQuestionaireDto.push(temp);
            }
        })
        let payload: any = {
            "serviceProviderAssessmentDto": serviceProviderAssessmentDto,
            "email": email,
            "name": name,
            "partyDiscriminator": "ServiceProvider",
        }
        let mapUrl = EndpointService.createCustomerUrl;
        let apiMethod: string = 'post';
        if (isEdit) {
            payload['serviceProviderId'] = customerId;
            if (method == 'submit') {
                mapUrl = EndpointService.editCustomerUrl + '?partyDiscriminator=ServiceProvider';
                apiMethod = 'put';
            }
            else {
                mapUrl = mapUrl + '?isSubmit=false&partyDiscriminator=ServiceProvider';
            }
        }
        else {
            payload["userDetailId"] = userDetailID;
            if (method === 'submit') {
                mapUrl = mapUrl + '?isSubmit=true&partyDiscriminator=ServiceProvider';
            }
            else {
                mapUrl = mapUrl + '?isSubmit=false&partyDiscriminator=ServiceProvider';
            }
        }
        HTTPService.customApiMethod(mapUrl, payload, apiMethod).then(response => {
            if (response.status.success === 'Success') {
                message.success(response.status.message);
                cypherService.setSessionStorage('editServiceProviderObject', null);
                cypherService.setSessionStorage('isServiceProviderEdit', false);
                let tempData: any = {
                    personName: '',
                    emailId: '',
                    companyName: '',
                    companyLocation: '',
                    channelPartner: '',
                    uaeEmirate: '',
                    findingPartner: '',
                    partnerInfo: [{
                        "Location": '',
                        "Partner": ''
                    }]
                }
                setInitLoginFormValue({ ...tempData });
                setPartnerField([{ "Location": '', "Partner": '' }]);
                setGccToggle(null);
                window.scrollTo(0, 0)
                resetForm({ values: '' });
                setLoader(false);
                getAddServiceProviderQuestions();
            }
            else if (response.status.success === 'Fail') {
                message.error(response.status.message);
            }
        })
            .catch((error) => {
                setLoader(false);
                if (error.response.data.status.success === 'Fail') {
                    message.error(error.response.data.status.message);
                }
            })
    }

    const redirectToServiceProvider = () => {
        cypherService.setSessionStorage('editServiceProviderObject', null);
        cypherService.setSessionStorage('isServiceProviderEdit', false);
        cypherService.setSessionStorage('detailsTab', '2');
        navigate('/viewdata')
    }

    const getPlaceHolder = (data: any) => {
        if (data.code == 'companyName') {
            return 'Enter company name'
        }
        else if (data.code == 'companyLocation') {
            return 'Select location'
        }
        else if (data.code == 'emailId') {
            return 'Enter email id'
        }
        else if (data.code == 'personName') {
            return 'Enter name'
        }
        else if (data.code == 'emirate') {
            return 'Select emirate'
        }
    }

    const getErrorEvaluator = (errorValues: any) => {
        let errorKeys: any = Object.keys(errorValues);
        let doc: any = document.getElementById(errorKeys[0]);
        doc.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    return (
        <div className="dashboard">

            {loader ? <Spin /> : null}
            <div className="add-new">
                <div className="add-new-school">
                    <div className="view-service">
                        <h1>{isEdit ? 'Update Service Provider' : 'Add Service Provider'}<button className="btn-service" onClick={() => redirectToServiceProvider()}>View Service Providers<BsArrowRight /></button></h1>
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={initAddServiceValue}
                        validationSchema={AddServiceProviderValidationSchema}
                        onSubmit={(values, { resetForm }) => onSubmit(resetForm)}
                    >
                        {({ errors, handleChange, isSubmitting }) => {

                            if (isSubmitting && Object.keys(errors).length > 0) {
                                getErrorEvaluator(errors);
                            }
                            return (
                                <Form>


                                    <Steps direction="vertical" current={1}>
                                        {
                                            questionList && questionList.map((item: any, index: number) => {
                                                if (item.componentType === 'Text') {
                                                    console.log('Text', item.answer)
                                                    return (
                                                        <Step title={item.question} description={
                                                            <>
                                                                <div>
                                                                    <Input placeholder={getPlaceHolder(item)} name={item.code} value={item.answer} id={item.code}
                                                                        onChange={(e) => { changeTextValue(e.target.value, questionList, setQuestionList, index); handleChange(e) }}
                                                                    />
                                                                </div>
                                                                <div className="error">
                                                                    <ErrorMessage name={item.code} />
                                                                </div>
                                                            </>
                                                        } />
                                                    )
                                                }
                                                else if (item.componentType === "Countrychecking") {
                                                    return (
                                                        <Step title={item.question} description={
                                                            <div>
                                                                <Select
                                                                    id={item.code}
                                                                    name={item.code}
                                                                    showSearch
                                                                    placeholder={getPlaceHolder(item)}
                                                                    allowClear
                                                                    // value={item.answer}
                                                                    filterOption
                                                                    className="textInputSelect"
                                                                    optionFilterProp="children"
                                                                    onClear={() => { clearCountry(questionList, setQuestionList, index) }}
                                                                    onSelect={(e: any) => { changeUaeCountry(e, questionList, setQuestionList, index) }}
                                                                >
                                                                    {
                                                                        countryList.countries.map((country: any) => {
                                                                            return (
                                                                                <Select.Option value={country.name}>{country.name}</Select.Option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                                <>
                                                                    <div className="error">
                                                                        <ErrorMessage name={item.code} />
                                                                    </div>
                                                                </>
                                                                {
                                                                    item.isUae ? (
                                                                        <div>
                                                                            <Select
                                                                                id="uaeEmirate"
                                                                                name="uaeEmirate"
                                                                                showSearch
                                                                                placeholder="Select emirate"
                                                                                filterOption
                                                                                allowClear
                                                                                defaultValue={item.isUaeAnswer == '' ? null : item.isUaeAnswer}
                                                                                className="textInputSelect"
                                                                                optionFilterProp="children"
                                                                                onClear={() => { clearUaeCountry(questionList, setQuestionList, index) }}
                                                                                onSelect={(e: any) => { changeUaeCountry(e, questionList, setQuestionList, index) }}
                                                                            >
                                                                                {
                                                                                    UaeEmiratesList.emirates && UaeEmiratesList.emirates.map((item: any) => {
                                                                                        return (
                                                                                            <Select.Option value={item.name}>{item.name}</Select.Option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                            <>
                                                                                <div className="error">
                                                                                    <ErrorMessage name="uaeEmirate" />
                                                                                </div>
                                                                            </>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        } />

                                                    )
                                                }
                                                else if (item.componentType === "GCC") {
                                                    return (
                                                        <>
                                                            <Step title={item.question} description={
                                                                <div>
                                                                    <div>
                                                                        <Radio.Group name={item.code} value={item.answer} id={item.code}
                                                                            onChange={(e) => { radioChange(e.target.value, questionList, setQuestionList, index, gccToggle, setGccToggle); handleChange(e) }}
                                                                        >
                                                                            <Radio value="Yes" >Yes</Radio>
                                                                            <Radio value="No" >No</Radio>
                                                                        </Radio.Group>
                                                                        <>
                                                                            <div className="error">
                                                                                <ErrorMessage name={item.code} />
                                                                            </div>
                                                                        </>
                                                                    </div>
                                                                    {
                                                                        gccToggle ? (
                                                                            <div className="add-Partner" id="partnerInfo">
                                                                                <FieldArray name="partnerInfo" render={arrayHelpers => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                partnerField && partnerField.map((element: any, rowIndex: number) => (
                                                                                                    <div className="add-Partner-main" key={rowIndex}>
                                                                                                        <div className="add-Partner-main-input">
                                                                                                            <Input placeholder="Partner's name" name={`partnerInfo[${rowIndex}].Partner`} value={element['Partner']}
                                                                                                                onChange={(e) => { partnerNameChange(e.target.value, rowIndex, partnerField, setPartnerField, 'Partner'); handleChange(e) }}
                                                                                                            />
                                                                                                            <div className="error">
                                                                                                                <ErrorMessage name={`partnerInfo[${rowIndex}].Partner`} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="add-Partner-main-select">
                                                                                                            <Select
                                                                                                                name={`partnerInfo[${rowIndex}].Location`}
                                                                                                                showSearch
                                                                                                                placeholder="Select emirate"
                                                                                                                filterOption
                                                                                                                allowClear
                                                                                                                className="textInputSelect"
                                                                                                                optionFilterProp="children"
                                                                                                                value={element['Location'] == '' ? null : element['Location']}
                                                                                                                onClear={() => { partnerLocationClear(rowIndex, partnerField, setPartnerField, 'Location') }}
                                                                                                                onSelect={(e: any) => { partnerLocationChange(e, rowIndex, partnerField, setPartnerField, 'Location'); handleChange(e) }}
                                                                                                            >
                                                                                                                {
                                                                                                                    EmiratesList.emirates && EmiratesList.emirates.map((item: any, index: number) => {
                                                                                                                        return (
                                                                                                                            <Select.Option value={item.name}>{item.name}</Select.Option>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }

                                                                                                            </Select>
                                                                                                            <div className="error">
                                                                                                                <ErrorMessage name={`partnerInfo[${rowIndex}].Location`} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            rowIndex == 0 ? (
                                                                                                                <></>
                                                                                                            ) : (
                                                                                                                <Button type="text" className="btn-text" onClick={() => deletePartnerRow(rowIndex, partnerField, setPartnerField, arrayHelpers)}><DeleteOutlined /></Button>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <Button type="text" className="btn-text" onClick={() => addNewPartnerField(arrayHelpers)}>+ Add another partner </Button>
                                                                                        </>
                                                                                    );
                                                                                }} />
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    gccToggle == false && (
                                                                                        <div className="add-Partner-new" >
                                                                                            <p className='step-header'>{item.selectNoOption.question}</p>
                                                                                            <div>
                                                                                                <Radio.Group name={item.selectNoOption.code} value={item.selectNoAnswer} id={item.selectNoOption.code}
                                                                                                    onChange={(e) => { gccNoOptionQuestionChange(e.target.value, questionList, setQuestionList, index, gccToggle, setGccToggle); handleChange(e) }}
                                                                                                >
                                                                                                    <Radio value="Yes" >Yes</Radio>
                                                                                                    <Radio value="No" >No</Radio>
                                                                                                </Radio.Group>
                                                                                            </div>
                                                                                            <div className="error">
                                                                                                <ErrorMessage name={item.selectNoOption.code} />
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            } />

                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </Steps>

                                    <div className="btn-group pb-12">
                                        <Button className="btn-primary " htmlType='submit' onClick={() => setMethod('submit')}>{isEdit ? 'Update' : 'Save'}</Button>
                                        <Button className="btn-secondary" disabled={isActive} htmlType='submit' onClick={() => setMethod('create')}>Send Mail</Button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AddServiceProvider