export const radioChange = (event: any, questionList: any, setQuestionList: any, index: number, gccToggle: boolean | null, setGccToggle: any) => {
    if (event === 'Yes') {
        setGccToggle(true);
    }
    else {
        setGccToggle(false);
    }
    let tempQuestionList = questionList;
    let tempAnswer = event;
    tempQuestionList[index].answer = tempAnswer;
    setQuestionList([...tempQuestionList]);
}

export const gccNoOptionQuestionChange = (event: any, questionList: any, setQuestionList: any, index: number, gccToggle: boolean | null, setGccToggle: any) => {
    let tempQuestionList = questionList;
    let tempAnswer = event;
    tempQuestionList[index].selectNoAnswer = tempAnswer;
    setQuestionList([...tempQuestionList]);
}
export const partnerNameChange = (event: any, index: number, partnerField: any, setPartnerField: any, name: any) => {
    let tempPartnerFields = partnerField;
    tempPartnerFields[index][name] = event;
    setPartnerField([...tempPartnerFields]);
}
export const partnerLocationChange = (event: any, index: number, partnerField: any, setPartnerField: any, name: any) => {
    let tempPartnerFields = partnerField;
    tempPartnerFields[index][name] = event;
    setPartnerField([...tempPartnerFields]);
}
export const partnerLocationClear = (index: number, partnerField: any, setPartnerField: any, name: any) => {
    let tempPartnerFields = partnerField;
    tempPartnerFields[index][name] = '';
    setPartnerField([...tempPartnerFields]);
}

export const deletePartnerRow = (rowIndex: number, partnerField: any, setPartnerField: any, arrayHelpers: any) => {
    let tempPartnerFields = partnerField;
    tempPartnerFields.splice(rowIndex, 1);
    setPartnerField([...partnerField]);
    arrayHelpers.remove(rowIndex);
}