import { Card } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ErrorMessage } from 'formik';
import React from 'react'
import { changeTextAreaValue } from '../../../hooks/country';
import QuestionHeader from '../layout/QuestionHeader';
import SchoolStatus from '../layout/SchoolStatus';

export default function SchoolDetailsTextarea(props: any) {
    const { item, questionList, setQuestionList, index, handleChange } = props
    return (
        <>
            <Card
                className="mb-5 card-textarea"
                title={
                    <>
                        <QuestionHeader answer={item.answer} question={item.question} />
                    </>
                }
                bordered={false}
                style={{ width: "100%" }}
            >
                {/* Answer */}
                <div className="answer-field">
                    <div className="w-full">
                        <TextArea
                            placeholder="Enter your text here"
                            name={item.code}
                            value={item.answer}
                            id={item.code}
                            onChange={(e) => {
                                changeTextAreaValue(e.target.value, questionList, setQuestionList, index);
                                handleChange(e);
                            }}
                            autoSize={{ minRows: 4 }}
                        />

                        <div className="error">
                            <ErrorMessage name={item.code} />
                        </div>
                    </div>
                </div>
                <SchoolStatus item={item} />
            </Card></>
    )
}
