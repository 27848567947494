import info from './info.svg';
import linkOutline from './link-outline.svg';
import playButton from './play-button.svg'
import pdf from './pdf.svg'
import school from './school.svg'
import user from './user.svg'
import fullscreenExit from './fullscreen-exit.svg'
import plusIcons from './plusIcons.svg'
import download from "./plusIcons.svg";
import institution from './institution.svg';
import leadership from "./../assessment/tab-1.svg";
import assessment from "./../assessment/tab-2.svg";
import curriculum from "./../assessment/tab-3.svg";
import personal from "./../assessment/tab-4.svg";
import planning from "./../assessment/tab-5.svg";
import smallLeadership from "./p1.svg";
import smallAssessment from "./p2.svg";
import smallCurriculum from "./p3.svg";
import smallPersonal from "./p4.svg";
import smallPlanning from "./p5.svg";
export const assetsIcons = { info, linkOutline, playButton, pdf, school, user, institution, fullscreenExit, plusIcons, download, leadership, assessment, curriculum, personal, planning, smallLeadership, smallAssessment, smallCurriculum, smallPersonal, smallPlanning };