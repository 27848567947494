import { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { cypherService } from "../utilities/cypher/cypherService";

function ProtectedRoute(props: any) {
  // Reference : https://www.robinwieruch.de/react-router-private-routes/
  const redirectPath = "/login";
  let isLoggedIn = cypherService.getLocalStorage("isLoggedIn");
  let user: string | null = "";
  if (isLoggedIn) {
    user = cypherService.getLocalStorage("appToken");
    cypherService.setSessionStorage("appToken", user);
  } else {
    user = cypherService.getSessionStorage("appToken");
  }

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
}
export default ProtectedRoute;
