import { Button, Modal } from "antd";

export default function ConfirmMessageModal(props: any) {
  const { modalShow, handleSuccess, handleModalClose } = props;
  return (
    <>
      <Modal footer={null} className="remove-subcategory-modal" visible={modalShow} centered>
        <div className="prod-details">
          <p className="customer-name text-white">Would you like to delete this School details information completely?</p>
        </div>
        <div className="remove-confirmation-button">
          <Button className="btn-primary" type="primary" onClick={handleSuccess}>
            Yes
          </Button>
          <Button className="btn-primary" type="primary" onClick={handleModalClose}>
            No
          </Button>
        </div>
      </Modal>
    </>
  );
}
