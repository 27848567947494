import * as Yup from "yup";

let bussinessEmail = new RegExp("^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$");

// const getErrorMessage = () => {
//     if (props.fieldObject.questionGroup === "DETAILS-Age groups") {
//       return 'Please select atleast one age group';
//     }
//     else if (props.fieldObject.questionGroup === "OTHERS-Languages") {
//       return 'Please select atleast one language';
//     }
//     else {
//       return 'Please select atleast one option';
//     }
//   }

export const infoValidation = Yup.object({
  name: Yup.string().required("Please enter your product name").nullable().strict().min(1, "This field is required"),
  shortDescription: Yup.string().required("Please enter your short description").nullable().strict().min(1, "This field is required"),
  fullDescription: Yup.string().required("Please enter your full description").nullable().strict().min(1, "This field is required"),
});

export const othersValidation = Yup.object({
  curricula: Yup.array().required("Please enter your curricula").nullable().strict().min(1, "Please select at least one curricula"),
  subject: Yup.string().required("Please select one subject").nullable(),
  otherSubject: Yup.array().when("subject", {
    is: "Selected subjects",
    then: Yup.array().required("Please select one subject").nullable(),
  }),

  // yearLaunched: Yup.number().required('fields required').typeError('Please enter a number') otherSubject
  //     .min(500, "You must be at least 500 users")
  //     .max(1000000, "You must be at most 1000000 users"),
  userBase: Yup.number().typeError("Please enter a number").min(500, "You must be at least 500 users").required("Please select your usercount").max(1000000, "You must be at most 1000000 users"),
  ageGroups: Yup.array().required("Please enter your age groups ").nullable().strict().min(1, "Please select at least one age group"),
  languages: Yup.array().required("Please select your languages").nullable().strict().min(1, "Please select at least one language"),
  yearLaunched: Yup.string().required("Please select a year").nullable(),
  supportedPlatform: Yup.array().required("Please select your supported Platform").nullable().strict().min(1, "Please select at least one platform"),
});

// curricula: "",
// yearLaunched: "",
// userBase: "",
// UserCount: "",
// ageGroups: "",
// logo: "",
// images: "",
// video: "",
// languages: "",
// supportedPlatform: "",
// caseStudy: "",
// testimonials: ""
