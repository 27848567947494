import { Button } from "antd";
import Header from "../../components/header/Header";
import errorImage from "./../../assets/images/error.svg";

function ErrorFallback(props: any) {
  const { error, resetErrorBoundary } = props;
  return (
    <>
      <Header />
      <div className="error-fallback-ui">
        <div className="errorBlock">
          <div>
            <img src={errorImage} alt="error image" />
          </div>
          {/* <pre>{error.message}</pre> */}
          <div className="mt-5 flex justify-center">
            <Button
              type="primary"
              className="btn-dashed"
              onClick={() => {
                resetErrorBoundary();
              }}
            >
              Try again
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorFallback;
