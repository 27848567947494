import React from "react";
import Institution from './../../../../../assets/images/icons/institution.svg';
import ApprovedStatus from "./approvedIcons/ApprovedStatus";

export default function TestimonialFooter(props: any) {
  const { user, school, item } = props;
  return (
    <>
      <div className="name-details">
        <p>
          <img src={user} alt="user" /> {item.name}
        </p>
        <p>
          <img src={school} alt="school" /> {item.designation}
        </p>
        <p>
          <img src={Institution} alt="school" /> {item.institutionName}
        </p>
        <ApprovedStatus approvedStatus={item.approved} />
      </div>
    </>
  );
}
