import { CloudUploadOutlined, DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, notification, Spin } from "antd";
import QuestionHeader from "../../../schoolDetails/layout/QuestionHeader";
import { handleMediaChange } from "./helper";
import { Viewer } from "@react-pdf-viewer/core"; // install this library
import { Worker } from "@react-pdf-viewer/core"; // install this library
import environment from "../../../../environments/environment";
import { useState } from "react";
import EndpointService from "../../../../utilities/constants/endpointService";
import HTTPService from "../../../../service/HTTPService";
let imageBaseUrl = environment.imageBaseUrl;
export default function FormControlPdf(props: any) {
  const { item, questionList, setQuestionList, index, setFieldValue, setErrors, errors, isEdit, editInit } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const updateIframeSrc = () => {
    let iframe: any = document.getElementById("iFrame");
    if (iframe != null) {
      iframe.src = iframe.src;
    }
  };

  const openPreview = (pdfUrl: any) => {
    let documentObject: any = {};
    if (pdfUrl && pdfUrl.substr(pdfUrl.lastIndexOf(".") + 1) == "pdf") {
      documentObject["type"] = "pdf";
      let url = pdfUrl;
      documentObject["url"] = `https://docs.google.com/gview?url=${encodeURI(url)}&embedded=true`;
    }

    return documentObject.url;
  };

  const handlePdfView = (e: any) => {
    const fileURL: any = e;
    const pdfWindow: any = window.open();
    pdfWindow.location.href = imageBaseUrl + fileURL;
  };

  const handleDownloadPdf = (filePath: string) => {
    if (filePath) {
      let nameArray = filePath && filePath.split("/");
      let name = nameArray[nameArray.length - 1];
      return (
        <div className="cursor-pointer flex items-center mt-2">
          <span className="mr-1" onClick={() => handlePdfView(filePath)}>
            <DownloadOutlined /> {name}
          </span>
        </div>
      );
    }
  };

  const handleRemoveOne = (el: any) => {
    if (el && el.answer && el.answer.create && el.answer.create) {
      let tempQuestionList = questionList;
      tempQuestionList[index].answer = null;
      setQuestionList([...tempQuestionList]);
    } else {
      deleteSingleFile(el);
    }
  };

  const deleteSingleFile = (item: any) => {
    setLoader(true);
    let mapUrl: string = EndpointService.deleteMultiPartFileUrl + item.editQuestionId;

    HTTPService.delete(mapUrl)
      .then((response) => {
        const getStatus = response.status.success.toLowerCase();
        if (getStatus === "success") {
          setLoader(false);
          editInit();
          notification.success({
            message: "",
            description: response.status.message,
            placement: "top",
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: "",
          description: error,
          placement: "top",
        });
      });
  };

  return (
    <>
      {loader ? <Spin /> : null}
      <Card
        className="mb-5"
        title={
          <>
            <QuestionHeader answer={item.answer} question={item.question} />
          </>
        }
        bordered={false}
        style={{ width: "100%" }}
      >
        {/* Answer */}
        <div className="answer-field ">
          <div className="dropFile">
            {item.answer && item.answer.length !== 0 ? (
              <>
                {/* <pre>{JSON.stringify(item.answer, null, 1)}</pre> */}
                {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                                            <Viewer fileUrl={item.answer.files} />
                                        </Worker> */}

                <div className="overlay-pdf">
                  <div className="pdf">
                    {isEdit && item.answer && item.answer.filePath && <iframe id="iFrame" title="DocumentPreview" onError={updateIframeSrc} className="document-preview-container" src={openPreview(imageBaseUrl + item.answer.filePath)} />}

                    <i className="play-button">
                      <img src="/static/media/pdf.fc4fdf86b4e8eab365b162bdec6f53f3.svg" alt="play button" />
                    </i>
                    <span role="img" aria-label="close" className="anticon anticon-close" onClick={() => handleRemoveOne(item)}>
                      <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                        <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                      </svg>
                    </span>
                  </div>
                </div>

                {item.answer && item.answer.files && item.answer.files.name}
                {isEdit && handleDownloadPdf(item.answer.filePath)}
              </>
            ) : (
              <div>
                <label className="filelabel">
                  <div className="flex">
                    <div className="upload-icon">
                      <CloudUploadOutlined className="text-green" />
                    </div>
                    <div className="ml-10 upload-cont">
                      <p className="browse font-sans">
                        Click to <span className="text-green">browse</span>
                      </p>
                      <p className="file-size font-sans">
                        PDF<span className="inline-block mx-2">|</span> max 3MB{" "}
                      </p>
                    </div>
                  </div>
                  <input
                    className="FileUpload1"
                    id="FileInput1"
                    type="file"
                    value=""
                    onChange={(value) => {
                      handleMediaChange(value, questionList, setQuestionList, index, setFieldValue, item.code, item, setErrors, errors);
                    }}
                  />
                </label>
              </div>
            )}
          </div>
          {errors.caseStudy && item.code === "caseStudy" && <div className="error">{errors.caseStudy} </div>}
          {errors.productCertificate && item.code === "productCertificate" && <div className="error">{errors.productCertificate} </div>}
        </div>
      </Card>
    </>
  );
}

{
  /* <div className="show-uploaded-pdf">

<div className="file-view">
    <div className="close-pdf"><CloseOutlined /></div> <i className='play-button'><img src={assetsIcons.pdf} alt="play button" /></i></div>
<div className="file-name"> {item.answer && getFileURLFileName(environment.imageBaseUrl + item.answer)}</div>
</div>

<iframe id="iFrame" title="DocumentPreview" onError={updateIframeSrc}
className="document-preview-container" src={openPreview(item.filePath)} /> */
}
