import Header from '../header/Header';
import { Tabs } from 'antd';
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons'
import RequestsTable from './CampaignsTable'

const { TabPane } = Tabs;

const Campaigns = () => {

    return (
        <div className="dashboard viewData-main">
            <div className="viewData request-tab">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Requests" key="1">
                        <RequestsTable />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}
export default Campaigns
