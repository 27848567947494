import { Card, Radio } from "antd";
import { ErrorMessage } from "formik";
import { cypherService } from "../../../../utilities/cypher/cypherService";

export default function FormRadioButton(props: any) {
  const { item, questionList, setQuestionList, index, handleChange } = props;
  let isEditValue: any = cypherService.getLocalStorage("isServiceProviderEdit");
  let serviceProviderId = cypherService.getLocalStorage("serviceProviderId");
  const optionQuestionChange = (event: any, questionList: any, setQuestionList: any, index: number) => {
    let tempQuestionList = questionList;
    let element = tempQuestionList[index];
    let tempAnswer = event;
    element.selectNoAnswer = tempAnswer;
    let payloadAnswerList: any = isEditValue && element.payloadAnswers;
    element.questionaireOption &&
      element.questionaireOption.forEach((el: any) => {
        if (el.option === tempAnswer) {
          let payloadAnswers = {
            ...(payloadAnswerList && { ...payloadAnswerList }),
            otherText: tempAnswer,
            questionaireOptionId: el.questionaireOptionId,
            serviceProviderId: serviceProviderId,
          };
          element["payloadAnswers"] = payloadAnswers;
        }
      });

    setQuestionList([...tempQuestionList]);
  };
  return (
    <div>
      <div className="answer-field">
        <Radio.Group
          name={item.code}
          value={item.selectNoAnswer}
          id={item.code}
          onChange={(e) => {
            optionQuestionChange(e.target.value, questionList, setQuestionList, index);
            handleChange(e);
          }}
        >
          <Radio value="Yes">Yes</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>
        <div className="error">
          <ErrorMessage name={item.code} />
        </div>
      </div>
    </div>
  );
}
