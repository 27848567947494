import React, { useEffect, useState } from "react";
import { Avatar, Menu, Dropdown, message } from "antd";
import { UserOutlined, BellFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import solvEd from "../../assets/images/SolvEd.png";
import { MdLogout } from "react-icons/md";
import { cypherService } from "../../utilities/cypher/cypherService";
import NotificationsWigets from "../notifications/layout/NotificationsWigets";
import EndpointService from "../../utilities/constants/endpointService";
import HTTPService from "../../service/HTTPService";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../store/notifications/notificationsAction";
import { BiMessageDetail } from 'react-icons/bi';
import { debug } from "console";
import { getMessageAction } from "../../store/message/messageAction";


const Header = () => {
  const [totalNumber, setTotalNumber] = useState(0)
  let reduxStore = useSelector((state: RootStateOrAny) => state);

  const dispatch = useDispatch();
  const [totalNotification, setTotalNotification] = useState(0)
  useEffect(() => {
    getNotification();
    getMaster()

  }, []);

  useEffect(() => {
    let notifications = reduxStore.notifications && reduxStore.notifications.data && reduxStore.notifications.data;
    let messageData = reduxStore.message && reduxStore.message.data && reduxStore.message.data;
    let unreadMessage: any = 0;
    messageData &&
      messageData.forEach((item: any) => {
        unreadMessage = unreadMessage + item.countOfUnReadMessagesOfCustomerForAdmin;
        unreadMessage = unreadMessage + item.countOfUnReadMessagesOfServiceProviderForAdmin;
      });
    setTotalNumber(unreadMessage)
  }, [reduxStore]);

  const getNotification = () => {
    let partyDiscriminator = "Admin";
    let mapUrl = EndpointService.getNotificationList + `?limit=10&requiredToDisplayFor=${partyDiscriminator}&start=0`;
    HTTPService.get(mapUrl)
      .then((response) => {
        let data = response.data
        debugger
        dispatch(getNotifications(data))
        setTotalNotification(response.totalResults)
      })
      .catch((err) => {

        let errorMessage = err.response.data ? err.response.data.status.message : "Something went wrong please try again later"
        message.error(errorMessage);
      });
  }

  const getMaster = () => {
    let mapUrl = EndpointService.getMasterRecords;
    HTTPService.get(mapUrl)
      .then((response) => {
        if (response.status.success.toLowerCase() == 'success') {

          dispatch(getMessageAction(response.data));
        }
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
        throw errorResponse;
      });
  };



  const navigate = useNavigate();
  const notificationDropdown: any = (
    <NotificationsWigets />
  )

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  let userName: any = cypherService.getSessionStorage('userName');
  let userNameAvatar: any = cypherService.getLocalStorage('userNameAvatar')

  const menu: any = (
    <Menu>
      <Menu.Item>
        <a className="name">
          {userName}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a className="add-admin" onClick={() => navigate("/addadmin")}>
          <UserOutlined />
          Add Admin
        </a>
      </Menu.Item>
      <Menu.Item>
        <a className="logout" onClick={() => logout()}>
          <span>
            <MdLogout />
          </span>
          Log Out
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className="header fixed top-0 left-0 w-full">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <div className="flex items-center">
          <span>
            <img className="solved-logo" src={solvEd} alt="solveEd-logo" />
          </span>
        </div>
        <div className="flex">
          <Menu className="flex items-center mx-auto">
            <Menu.Item key="one" className={(window.location.pathname).includes('viewdata') ? "active" : ''} onClick={() => navigate('/viewdata')}>
              Database
            </Menu.Item>
            <Menu.Item key="two" className={(window.location.pathname).includes('campaigns') ? "active" : ''} onClick={() => navigate('/campaigns')}>
              Campaigns
            </Menu.Item>
            <Menu.Item key="three" className={(window.location.pathname).includes('meetings') ? "active" : ''} onClick={() => navigate('/meetings')}>
              Meetings
            </Menu.Item>
          </Menu>
          <div className="notification-main msg-icon" onClick={() => navigate("/message-conversation")}>
            <div className="count-badge">{totalNumber}</div>
            <BiMessageDetail />
          </div>
          <Dropdown overlay={notificationDropdown} placement="bottomRight" className="profile">
            <div className="notification-main">
              <div className="count-badge">{totalNotification > 99 ? "99+" : totalNotification}</div>
              <BellFilled />
            </div>
          </Dropdown>
          <Dropdown overlay={menu} placement="bottomRight">
            <div className="avathar-main">
              <Avatar className="cursor-pointer bg-white text-darkGray font-bold font-sans">
                {userNameAvatar}
                {/* <UserOutlined /> */}
              </Avatar>
            </div>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Header;
