import React, { useEffect, useState } from 'react'
import Header from '../header/Header';
import { Tabs } from 'antd';
import ViewSchool from '../viewSchool/ViewSchool';
import ViewServiceProvider from '../viewServiceProvider/ViewServiceProvider';
import { cypherService } from '../../utilities/cypher/cypherService';
import EdtechExpert from '../EDTechExpert/EdtechExpert';
const { TabPane } = Tabs;

function ViewData() {

    const [currentTab, setCurrentTab] = useState<string>(cypherService.getSessionStorage('detailsTab') || '1');

    useEffect(() => {
        setCurrentTab(cypherService.getSessionStorage('detailsTab') || '1')
    }, [])

    const onChangeTab = (key: any) => {
        setCurrentTab(key.toString());
    }

    return (
        <div className="dashboard viewData-main">

            <div className="viewData">
                <Tabs defaultActiveKey={currentTab} onChange={onChangeTab}>
                    <TabPane tab="Schools" key="1">
                        <ViewSchool />
                    </TabPane>
                    <TabPane tab="Products/ EdTech Providers" key="2">
                        <ViewServiceProvider />
                    </TabPane>
                    <TabPane tab="EdTech Experts" key="3">
                        <EdtechExpert />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default ViewData 