

const Linkify = (props: any) => {
    const { text } = props
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return (
        <div>
            {text.split(urlRegex).map((word: any, i: any) => (
                word.match(urlRegex) ? <a href={word} target="_blank" key={i}>{word}</a> : <span key={i}>{word}</span>
            ))}
        </div>
    );
}

export default Linkify;