import { Card, DatePicker } from 'antd'
import { ErrorMessage } from 'formik'
import moment from 'moment';
import React from 'react'
import QuestionHeader from '../../../schoolDetails/layout/QuestionHeader'

export default function FormControlDatePicker(props: any) {


    const { item, questionList, setQuestionList, index, setFieldValue, isEdit } = props

    const changeDate = (date: any, dateString: any, questionList: any, setQuestionList: any, index: number, name: string, setFieldValue: any) => {

        let tempQuestionList = questionList;
        if (name === "yearLaunched") {
            setFieldValue("yearLaunched", dateString)
        }
        tempQuestionList[index].answer = {
            year: dateString,
            yearMoment: date
        }
        setQuestionList([...tempQuestionList]);
    }

    console.log(item)
    return (
        <>

            <Card className="mb-5" title={
                <>
                    <QuestionHeader answer={item.answer} question={item.question} />
                </>
            } bordered={false} style={{ width: '100%' }}>
                {/* Answer */}
                <div className="answer-field">

                    <DatePicker className="yearPicker mb-6" value={item.answer.yearMoment} placeholder='Please select a year' picker="year" onChange={(date, dateString) => changeDate(date, dateString, questionList, setQuestionList, index, item.code, setFieldValue)} />
                    <div className="error">
                        <ErrorMessage name="yearLaunched" />
                    </div>
                </div>

            </Card></>
    )
}
