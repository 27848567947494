import React, { useEffect, useState } from "react";
import star from "../../../assets/icons/star_filled.svg";
import staroutline from "../../../assets/icons/star_outline.svg";
import EndpointService from "../../../utilities/constants/endpointService";
import HTTPService from "../../../service/HTTPService";
import { Spin, message } from "antd";
import { useParams } from "react-router-dom";
import { cypherService } from "../../../utilities/cypher/cypherService";

export default function NotificationsLists() {
  let userId = cypherService.getLocalStorage("userId");
  const { paramName } = useParams()
  const [data, setData] = useState<any>([]);
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    getNotification()
  }, [paramName]);


  const getNotification = () => {
    let partyDiscriminator = "Admin";
    let mapUrl = ""
    mapUrl = paramName === "all" ? EndpointService.getNotificationList + `?limit=20&requiredToDisplayFor=${partyDiscriminator}&start=0` : EndpointService.getNotificationList + `?limit=20&requiredToDisplayFor=${partyDiscriminator}&start=0&notificationRequiredToDisplayFor=${paramName}`;
    setLoader(true)
    HTTPService.get(mapUrl)
      .then((response) => {
        let data = response.data
        setData(data)
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        message.error(err.response.data.status.message);
      });
  }
  const checkedBookmarked = (el: any) => {
    debugger
    setLoader(true);
    let mapUrl = EndpointService.getStarredNotification + `/${el.notificationId}/starred?userId=${userId}`;

    HTTPService.put(mapUrl, {})
      .then((response) => {
        if (response.status.success.toLowerCase() == 'success') {
          message.success(response.status.message);
          getNotification();
        } else {
          setLoader(false);
          message.error(response.status.message);
        }
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
        message.error('failed');
        setLoader(false);
      });
  };
  return <div>
    {loader ? <Spin /> : null}
    {data && data.map((item: any) => (
      <div className="notification-list ">
        <div className="meeting-description text-style">

          <div className="reader-icon" onClick={() => checkedBookmarked(item)}>
            <img src={item.starred ? star : staroutline} alt="" />
          </div>

          <div className="school-name">
            <p className={item.isViewed ? "read" : ""}>
              {item.description}
            </p>
          </div>
        </div>
        <div className="meeting-time text-style">
          <p>-</p>
        </div>
      </div>

    ))}
  </div>;
}
