import { CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import Card from "antd/lib/card/Card";
import { ErrorMessage } from "formik";
import React, { useEffect } from "react";
import QuestionHeader from "../../../schoolDetails/layout/QuestionHeader";
import ImagePreview from "../ImagePreview";
import { handleMediaChange } from "./helper";

export default function FormControlImage(props: any) {
  const { item, questionList, setQuestionList, index, setFieldValue, setErrors, errors, editInit, handleLogoError, logoError } = props;


  const resetError = () => {

    handleLogoError(false);
  };
  return (
    <>
      <div className="card-inner mb-4">
        <Card
          className="mb-5"
          title={
            <>
              <QuestionHeader answer={item.answer} question={item.question} />
            </>
          }
          bordered={false}
          style={{ width: "100%" }}
        >
          {/* Answer */}
          <div className="answer-field">
            <div className="dropFile img-drop-file">
              {item.answer && item.answer.length !== 0 ? (
                <>
                  <ImagePreview item={item} questionList={questionList} setQuestionList={setQuestionList} index={index} editInit={editInit} />

                  {item.answer.length > 1 && item.code === "logo" ? (
                    <div>
                      <label>
                        <div className="imageUpload-section">
                          <div className="uploadedImage-preview">
                            <div className="imageUpload-div">
                              <PlusOutlined className="text-green" />
                            </div>
                          </div>
                          <input
                            className="FileUpload1"
                            id="FileInput"
                            type="file"
                            onChange={(value) => {
                              handleMediaChange(value, questionList, setQuestionList, index, setFieldValue, item.code, item, setErrors, errors);
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  ) : null}

                  {item.answer.length < 4 && item.code === "images" ? (
                    <div>
                      <label>
                        <div className="imageUpload-section">
                          <div className="uploadedImage-preview">
                            <div className="imageUpload-div">
                              <PlusOutlined className="text-green" />
                            </div>
                          </div>
                          <input
                            className="FileUpload1"
                            id="FileInput"
                            type="file"
                            onChange={(value) => {
                              handleMediaChange(value, questionList, setQuestionList, index, setFieldValue, item.code, item, setErrors, errors);
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  ) : null}
                </>
              ) : (
                <div>
                  <label className="filelabel">
                    <div className="flex">
                      <div className="upload-icon">
                        <CloudUploadOutlined className="text-green" />
                      </div>
                      <div className="ml-10 upload-cont">
                        <p className="browse font-sans">
                          Click to <span className="text-green">browse</span>
                        </p>
                        <p className="file-size font-sans">
                            PNG | JPG | JPEG <span className="inline-block mx-2">|</span> max 3MB
                        </p>
                      </div>
                    </div>
                    <input
                      className="FileUpload1"
                      id={item.code}
                      type="file"
                      value=""
                      onChange={(value) => {
                        handleMediaChange(value, questionList, setQuestionList, index, setFieldValue, item.code, item, setErrors, errors);

                        resetError();
                      }}
                    />
                  </label>
                </div>
              )}
            </div>
            {item.code === "logo" && logoError ? <div className="error">Please select logo</div> : null}
            {errors.logo && item.code === "logo" && <div className="error img-error">{errors.logo} </div>}
            {errors.images && item.code === "images" && <div className="error img-error">{errors.images} </div>}
          </div>
        </Card>
      </div>
    </>
  );
}
