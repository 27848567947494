
import { EncryptStorage } from 'encrypt-storage';
import { AES, enc } from 'crypto-js';
const SECRET_KEY = "SolvedAppKey@1234"

// const SECRET_KEY = 'SECRET-KEY123';
// Passing the key and value



const setSessionStorage = (key: string, value: any) => {
    // if (window.location.hostname === 'localhost') {
    //     sessionStorage.setItem(key, value);
    // }
    // else {
    //     encryptSessionStorageValue.setItem(key, value);
    // }
    sessionStorage.setItem(key, value);
}
const getSessionStorage = (key: string) => {
    // if (window.location.hostname === 'localhost') {
    //     return sessionStorage.getItem(key);
    // }
    // else {
    //     return encryptSessionStorageValue.getItem(key);
    // }
    return sessionStorage.getItem(key);
}


const getLocalStorage = (key: string) => {
    let value: any = localStorage.getItem(key)
    if (value) {
        const decryptSting = AES.decrypt(value, SECRET_KEY);
        const decryptedObject = decryptSting.toString(enc.Utf8);
        const parseValue = decryptedObject && JSON.parse(decryptedObject)
        return parseValue
    } else {
        return null
    }
}

const setLocalStorage = (key: string, value: any) => {

    const encryptObject = AES.encrypt(JSON.stringify(value && value), SECRET_KEY).toString();
    localStorage.setItem(key, encryptObject);

}

export const cypherService = {
    setSessionStorage, getSessionStorage, setLocalStorage, getLocalStorage
}