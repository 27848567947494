import { ErrorBoundary } from "react-error-boundary";
import "antd/dist/antd.min.css";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Routing from "./routes/Routing";
import { useState } from "react";
import ErrorFallback from "./hooks/errorBoundaries/ErrorFallback";

function App() {
  const [explode, setExplode] = useState(false);
  return (
    <div className="App">
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => setExplode(false)} resetKeys={[explode]}>
          {explode ? <p>{explode}</p> : <Routing />}
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
}

export default App;
