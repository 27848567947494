import axios from 'axios';
import instance from './AxiosConfig';

export default class HTTPService {
    static post = (apiUrl: string, payload: any) => {
        return instance.post(apiUrl, payload)
            .then(response => response.data)
            .catch(error => {
                throw error;
            });
    };
    static delete = (apiUrl: string) => {
        return instance.delete(apiUrl)
            .then(response => response.data)
            .catch(error => {
                throw error;
            });
    };
    static put = (apiUrl: string, payload: any) => {
        return instance.put(apiUrl, payload)
            .then(response => response.data)
            .catch(error => {
                throw error;
            });
    };
    //config.headers['content-type'] = 'application/json';
    static get = (apiUrl: string, payload?: any) => {
        return instance.get(apiUrl, {})
            .then(response => response.data)
            .catch(error => {
                throw error;
            });
    }
    static imageWithContentRequest = (apiUrl: string, payload: any) => {
        return instance.post(apiUrl, {            
            data: payload
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
    }
    static customApiMethod = (apiUrl: string, payload: any, method: string) => {
        if (method == 'put') {
            return instance.put(apiUrl, payload)
                .then(response => response.data)
                .catch(error => {
                    throw error;
                });
        }
        else if (method == 'delete') {
            return instance.delete(apiUrl)
                .then(response => response.data)
                .catch(error => {
                    throw error;
                });
        }
        else if (method == 'post') {
            return instance.post(apiUrl, payload)
                .then(response => response.data)
                .catch(error => {
                    throw error;
                });
        }
        else  {
            return instance.get(apiUrl, {})
                .then(response => response.data)
                .catch(error => {
                    throw error;
                });
        }
    };
}
