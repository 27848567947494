import { Card, Input } from "antd";
import { ErrorMessage } from "formik";
import { Select } from "formik-antd";
import React, { useEffect, useState } from "react";
import { cypherService } from "../../../../utilities/cypher/cypherService";
import QuestionHeader from "../../../schoolDetails/layout/QuestionHeader";
import { productsPlaceHolder } from "../../helper";

export default function FormControlSelect(props: any) {
  const { item, questionList, setQuestionList, index, setFieldValue, handleChange, otherError, handleOtherError } = props;
  const [otherTextValue, setOtherTextValue] = useState<string>("");
  const [othersActive, setOthersActive] = useState<boolean>(false);

  useEffect(() => {
    findOthersValue();
  }, [othersActive]);

  const findOthersValue = async () => {
    let tempQuestionList = questionList;
    let value = "";
    if (tempQuestionList[index].code === "languages") {
      let answerPayload = tempQuestionList[index].answerPayload && tempQuestionList[index].answerPayload;
      (await answerPayload) &&
        answerPayload.forEach((el: any) => {
          if (el.isActiveOther && el.answerValue === "Other") {
            cypherService.setLocalStorage("otherValidation", true);
            cypherService.setLocalStorage("otherValue", el.otherText);
            value = el.otherText;
          }
        });
    }

    if (value) {
      setOtherTextValue(value);
      setOthersActive(true);
    }
  };
  const selectValueClear = (event: any, questionList: any, setQuestionList: any, index: number, name: string) => {

    if (name === "languages") {
      if (event === "Other") {
        cypherService.setLocalStorage("otherValidation", false);
        cypherService.setLocalStorage("otherValue", "");
        setOthersActive(false);
        setOtherTextValue("");
      }
    }
    let tempQuestionList = questionList;
    let answer = tempQuestionList[index].answer;
    let answerPayload = tempQuestionList[index].answerPayload && tempQuestionList[index].answerPayload;
    if (questionList instanceof Array) {
      let resultAnswer =
        answer &&
        answer.filter((item: any) => {
          if (item !== event) return item;
        });
      tempQuestionList[index].answer = resultAnswer;

      let resultAnswerPayload =
        answerPayload &&
        answerPayload.filter((item: any) => {
          if (item.answerValue !== event) return item;
        });

      tempQuestionList[index].answerPayload = resultAnswerPayload;

      setQuestionList([...tempQuestionList]);
    } else {
      tempQuestionList[index].answer = [];
      setQuestionList([...tempQuestionList]);
    }
  };
  const handleOtherTextValue = (event: any, questionList: any, setQuestionList: any, index: number, name: string) => {
    let tempQuestionList = questionList;
    let answerPayload = tempQuestionList[index].answerPayload;

    if (name === "languages") {
      setQuestionList([...tempQuestionList]);
      answerPayload &&
        answerPayload.map((el: any) => {
          if (el.answerValue === "Other") {
            el["otherText"] = event;
            handleOtherError(false);
          }
        });


      cypherService.setLocalStorage("otherValue", event);
      setOtherTextValue(event);
      tempQuestionList[index]["answerPayload"] = [...answerPayload];
    }
  };
  const multipleSelectValue = (event: any, questionList: any, setQuestionList: any, index: number, item: any, name: string, dumm: any) => {

    let tempQuestionList = questionList;
    let answer = [];

    if (event === "All Curricula") {
      answer = ["All Curricula"];
      tempQuestionList[index].answer = answer;
    } else {
      answer.push(event);

      let result =
        tempQuestionList[index].answer &&
        tempQuestionList[index].answer.filter((el: any) => {
          if (el !== "All Curricula") return el;
        });

      tempQuestionList[index].answer = [...result, ...answer];
    }

    let answerQuestionaireOptionId: any = [];

    let defaultAnswerPayload = tempQuestionList[index].answerPayload;

    if (name === "languages") {
      if (event === "Other") {
        cypherService.setLocalStorage("otherValidation", true);
        setOthersActive(true);
      }
    }

    if (name === "languages" || name === "supportedPlatform" || name === "ageGroups") {
      item.questionaireOption &&
        item.questionaireOption.forEach((item: any) => {
          if (item.option === event) {
            let obj = {
              questionaireOptionId: name === "ageGroups" ? item.ageGroupId : item.questionaireOptionId,
              answerValue: event,
              ...(event === "Other" && { otherText: otherTextValue }),
            };
            if (defaultAnswerPayload) {
              answerQuestionaireOptionId = [...defaultAnswerPayload, obj];
            } else {
              answerQuestionaireOptionId.push(obj);
            }
          }
        });

      const filteredArr = answerQuestionaireOptionId.reduce((acc: any, current: any) => {
        const x = acc.find((itemInner: any) => itemInner.questionaireOptionId === current.questionaireOptionId);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      tempQuestionList[index]["answerPayload"] = filteredArr;
    }

    setQuestionList([...tempQuestionList]);
  };
  return (
    <>
      <Card
        className="mb-5"
        title={
          <>
            <QuestionHeader answer={item.answer} question={item.question} />
          </>
        }
        bordered={false}

      >
        {/* Answer */}
        {/* <div className="h-60 overflow-hidden overflow-y-auto">
          <pre className="text-xs">{JSON.stringify(item, null, 1)}</pre>
          <pre className="text-xs">{JSON.stringify(questionList[index].answer, null, 1)}</pre>
        </div> */}
        <div className="answer-field">
          <Select
            id={item.code}
            name={item.code}
            showSearch
            mode="multiple"
            defaultValue={item.answer}
            onDeselect={(e: any) => selectValueClear(e, questionList, setQuestionList, index, item.code)}
            filterOption
            placeholder={productsPlaceHolder(item.code)}
            className="textInputSelect"
            optionFilterProp="children"
            value={questionList[index].answer}
            onSelect={(e: any, dumm) => {
              multipleSelectValue(e, questionList, setQuestionList, index, item, item.code, dumm);
            }}
          >
            {item &&
              item.questionaireOption.map((dataValue: any) => {
                return <Select.Option value={dataValue.option}>{dataValue.option}</Select.Option>;
              })}
          </Select>
          {othersActive && (
            <div className="answer-field">
              <Input
                placeholder="Enter other language"
                name="otherLanguage"
                id="otherLanguage"
                value={otherTextValue}
                onChange={(e) => {
                  handleOtherTextValue(e.target.value, questionList, setQuestionList, index, item.code);
                  handleChange(e);
                }}
              />
            </div>
          )}
          {item.code === "languages" && otherError && <div className="error"> Other language is required</div>}
          <div className="error">
            <ErrorMessage name={item.code} />
          </div>
        </div>
      </Card>
    </>
  );
}
