import { CloudUploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { notification, Spin } from "antd";
import { Input } from "formik-antd";
import { useState, useEffect } from "react";
import { imageBaseUrl } from "../../../../environments/environment";
import HTTPService from "../../../../service/HTTPService";
import EndpointService from "../../../../utilities/constants/endpointService";


export default function FormControlPdf(props: any) {
  const { item, questionList, setQuestionList, index, isEditValue, serviceProviderDetails, certificateError, setCertificateError } = props;

  let pathUrl = item.answer && item.answer.filePath;
  const [loader, setLoader] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<any>(pathUrl);
  const [license, setLicense] = useState<string | number>("")

  useEffect(() => {

    if (item.componentValied && !item.isActiveFilePath) {

      setLicense(item.payloadAnswers.otherText)
    }
    pathUrl && setFilePath(pathUrl);

  }, [props.item]);

  const updateIframeSrc = () => {
    let iframe: any = document.getElementById("iFrame");
    if (iframe != null) {
      iframe.src = iframe.src;
    }
  };

  const openPreview = (pdfUrl: any) => {
    let documentObject: any = {};
    if (pdfUrl && pdfUrl.substr(pdfUrl.lastIndexOf(".") + 1) == "pdf") {
      documentObject["type"] = "pdf";
      let url = pdfUrl;
      documentObject["url"] = `https://docs.google.com/gview?url=${encodeURI(url)}&embedded=true`;
    }

    return documentObject.url;
  };
  const handleRemove = (el: any) => {
    let isValid = item.answer && item.answer.files && item.answer.files.name ? false : true;
    if (isValid) {
      setLoader(true);
      let serviceProviderQuestionaireId = el.answer && el.answer.serviceProviderQuestionaireId;
      let mapUrl = EndpointService.deleteServiceProviderCertificate + serviceProviderQuestionaireId;
      HTTPService.delete(mapUrl)
        .then((response) => {
          const getStatus = response.status.success.toLowerCase();
          if (getStatus === "success") {
            notification.success({
              message: "",
              description: response.status.message,
              placement: "top",
            });
            serviceProviderDetails();
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          notification.error({
            message: "",
            description: error,
            placement: "top",
          });
        });
    } else {
      let tempQuestionList = questionList;
      tempQuestionList[index].answer = "";
      setQuestionList([...tempQuestionList]);
    }

  };

  const handlePdfView = (e: any) => {
    const fileURL: any = e;
    const pdfWindow: any = window;
    pdfWindow.location.href = imageBaseUrl + fileURL;
  };

  const handleDownloadPdf = (filePath: string) => {
    if (filePath) {
      let nameArray = filePath && filePath.split("/");
      let name = nameArray[nameArray.length - 1];
      return (
        <div className="cursor-pointer flex items-center mt-2">
          <span className="mr-1" onClick={() => handlePdfView(filePath)}>
            {isEditValue && <DownloadOutlined />} {name}
          </span>
        </div>
      );
    }
  };
  const handleMediaPdf = (event: any) => {
    let filesObj = event.target.files[0];
    let format = "pdf";
    let errorMsg = "";
    let errorFlag: boolean = false;
    if (filesObj) {
      if (filesObj.type !== "application/pdf") {
        errorFlag = true;
        errorMsg = "Please upload a pdf in the specified format (pdf)";
      }
      let mb: any = filesObj.size / 1000000;
      let validSize = 3;
      let fixedMb: any = validSize;
      if (parseFloat(mb) > parseFloat(fixedMb)) {
        if (!errorFlag) {
          errorFlag = true;
          errorMsg = `Please upload a ${format} of size less than ${validSize}MB`;
        }
      }
    }

    if (!errorFlag) {
      let tempQuestionList = questionList;
      tempQuestionList[index].answer = {
        files: filesObj,
        filePath: URL.createObjectURL(filesObj),
        create: true,
      };
      tempQuestionList[index]["isActiveFilePath"] = true
      errorMsg = "";
      setQuestionList([...tempQuestionList]);
      setLicense("")
    }
    setCertificateError(errorMsg)
  }
  const handleChange = (e: any, itemValue: any) => {
    let tempQuestionList = questionList;
    let val = e.target.value
    if (val) {
      setCertificateError("")
      tempQuestionList[index].answer = val;
      setQuestionList([...tempQuestionList]);
      tempQuestionList[index]["isActiveFilePath"] = false
    } else {
      setCertificateError("Certificate is required")
      tempQuestionList[index].answer = "";
    }
    setLicense(val)

  }
  return (
    <>
      {loader ? <Spin /> : null}
      <div className="answer-field ">
        <div className="dropFile">

          {item.answer && item.answer.length !== 0 && item.isActiveFilePath ? (
            <>
              <div className="overlay-pdf">
                <div className="pdf">
                  {isEditValue && item.answer && filePath && <iframe id="iFrame" title="DocumentPreview" onError={updateIframeSrc} className="document-preview-container" src={openPreview(imageBaseUrl + filePath)} />}
                  <i className="play-button">
                    <img src="/static/media/pdf.fc4fdf86b4e8eab365b162bdec6f53f3.svg" alt="play button" />
                  </i>
                  <span role="img" aria-label="close" className="anticon anticon-close" onClick={() => handleRemove(item)}>
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                    </svg>
                  </span>
                </div>
              </div>

              {item.answer && item.answer.files && item.answer.files.name ? item.answer.files.name : handleDownloadPdf(filePath)}
            </>
          ) : (
            <div>
              <label className="filelabel">
                <div className="flex">
                  <div className="upload-icon">
                    <CloudUploadOutlined className="text-green" />
                  </div>
                  <div className="ml-10 upload-cont">
                    <p className="browse font-sans">
                      Click to <span className="text-green">browse</span>
                    </p>
                    <p className="file-size font-sans">
                      PDF<span className="inline-block mx-2">|</span> max 3MB
                    </p>
                  </div>
                </div>

                <input
                  className="FileUpload1"
                  id="FileInput1"
                  type="file"
                  value=""
                  onChange={(value) => {
                    handleMediaPdf(value);
                  }}
                />
              </label>
            </div>
          )}
        </div>
        <div className="text-center text-xl my-4">or</div>
        <Input id={item.code} name={item.name} value={license} placeholder="Enter your license / company number" onChange={(e) => { handleChange(e, item) }} />

        {certificateError ? <div className="error">{certificateError}</div> : null}
      </div>
    </>
  );
}
