import { Card, Input, Radio } from "antd";
import { ErrorMessage } from "formik";
import { Select } from "formik-antd";
import React, { useEffect, useState } from "react";
import { cypherService } from "../../../../utilities/cypher/cypherService";
import QuestionHeader from "../../../schoolDetails/layout/QuestionHeader";
import { productsPlaceHolder } from "../../helper";

export default function FormControlSelectWithTag(props: any) {
  const { item, questionList, setQuestionList, index, setFieldValue, handleChange } = props;

  const [isActiveSearch, setIsActiveSearch] = useState<boolean>(false);
  const [selectionOptionList, setSelectionOptionList] = useState<any>([]);
  const [selectionValue, setSelectionValue] = useState<string>("");

  let searchOptionsLists: any = [];
  let otherOptionSelection: any = [];
  let allSubject: any = [];
  let questionaireOptionList = item.questionaireOption;

  questionaireOptionList &&
    questionaireOptionList.forEach((item: any) => {
      if (item.option === "Other") {
        let obj = {
          value: item.option,
          label: item.option,
          optionValue: item.questionaireOptionId,
        };
        otherOptionSelection.push(obj);
      }
      if (item.option === "All subjects") {
        let obj = {
          value: item.option,
          label: item.option,
          optionValue: item.questionaireOptionId,
          othersField: false,
        };
        allSubject.push(obj);
      } else {
        let obj = {
          value: item.option,
          label: item.option,
          optionValue: item.questionaireOptionId,
        };
        searchOptionsLists.push(obj);
      }
    });

  useEffect(() => {
    onInit();
  }, []);

  const onInit = () => {

    let getSelectionList = questionList[index].answerPayload;
    if (getSelectionList && getSelectionList.length > 0) {
      getSelectionList.forEach((el: any) => {
        if (el.label === "All subjects") {
          setIsActiveSearch(false);
          setSelectionValue("All subjects");
        } else {
          setIsActiveSearch(true);
          setSelectionValue("Selected subjects");
        }
      });
    }
  };

  const multipleSelectValue = (event: any, questionList: any, setQuestionList: any, index: number) => {


    let tempQuestionList = questionList;
    let answer: any = [];
    let result = tempQuestionList[index].answer;
    let isValid: boolean = true;

    searchOptionsLists.forEach((el: any) => {
      if (el.label === event) {
        isValid = false;
        let obj = {
          label: event,
          value: event,
          optionValue: el.optionValue,
          othersField: false,
        };
        answer.push(obj);
      }
    });

    if (isValid) {

      let otherSelectionValue = otherOptionSelection && otherOptionSelection[0];
      let obj = {
        label: event,
        value: event,
        optionValue: otherSelectionValue.optionValue,
        othersField: true,
      };
      answer.push(obj);
    }
    setSelectionOptionList([...selectionOptionList, ...answer]);
    tempQuestionList[index].answer = [...result, ...answer];
    setQuestionList([...tempQuestionList]);
  };

  const radioChange = (event: any, questionList: any, setQuestionList: any, index: number) => {

    let tempQuestionList = questionList;
    if (event == "All subjects") {
      tempQuestionList[index].answer = [...allSubject];
      setQuestionList([...tempQuestionList]);
      setIsActiveSearch(false);
      setSelectionValue("All subjects");
    } else {
      tempQuestionList[index].answer = [...selectionOptionList];
      setQuestionList([...tempQuestionList]);
      setIsActiveSearch(true);
      setSelectionValue("Selected subjects");
    }
    // let tempQuestionList = questionList;
    // 
    // setFieldValue("subject", event);
    // let tempAnswer = event;
    // tempQuestionList[index].answer = tempAnswer;
    // setQuestionList([...tempQuestionList]);
  };

  const selectValueClear = (el: any) => {

    let tempQuestionList = questionList;

    let result = tempQuestionList[index].answer;

    let filterArrayValue: any = result.filter((item: any) => {
      if (item.label !== el) return item;
    });

    tempQuestionList[index].answer = [...filterArrayValue];

    setQuestionList([...tempQuestionList]);
  };
  return (
    <>
      <Card
        className="mb-5"
        title={
          <>
            <QuestionHeader answer={item.answer} question={item.question} />
          </>
        }
        bordered={false}
        style={{ width: "100%" }}
      >
        {/* Answer */}
        {/* <div className="h-60 overflow-hidden overflow-y-auto">
          <pre className="text-xs">{JSON.stringify(questionList[index].answer, null, 1)}</pre>
          <pre className="text-xs">{JSON.stringify(questionList[index].answerPayload, null, 1)}</pre>
        </div> */}
        <div className="answer-field">
          <Radio.Group
            id={item.code}
            name={item.code}
            value={selectionValue}
            onChange={(e) => {
              radioChange(e.target.value, questionList, setQuestionList, index);
              handleChange(e);
            }}
          >
            <Radio value="All subjects">All subjects</Radio>
            <Radio value="Selected subjects">Selected subjects</Radio>
          </Radio.Group>

          {isActiveSearch && (
            <Select
              className="w-full textInputSelect custom-search"
              mode="tags"
              id="otherSubject"
              name="otherSubject"
              placeholder={productsPlaceHolder(item.code)}
              options={searchOptionsLists}
              onDeselect={(e: any) => selectValueClear(e)}
              onSelect={(e: any) => {
                multipleSelectValue(e, questionList, setQuestionList, index);
              }}
            />
          )}

          <div className="error">
            <ErrorMessage name={item.code} />
            <ErrorMessage name={"otherSubject"} />
          </div>
        </div>
      </Card>
    </>
  );
}
