import { Card, Checkbox } from 'antd';
import { ErrorMessage } from 'formik';
import { Input } from 'formik-antd';
import React from 'react'
import { changeTextSetFieldValue } from '../../../hooks/country';
import QuestionHeader from '../layout/QuestionHeader';

export default function SchoolDetailsTextFields(props: any) {
    const { item, inputDisabled, getPlaceHolder, questionList, setQuestionList, index, setFieldValue, setUserCountError } = props
    return (
        <>
            <Card
                className="mb-5"
                title={
                    <>
                        <QuestionHeader answer={item.answer} question={item.question} />
                    </>
                }
                bordered={false}
                style={{ width: "100%" }}
            >
                {/* Answer */}
                <div className="answer-field">
                    <Input
                        disabled={inputDisabled(item.code)}
                        placeholder={getPlaceHolder(item)}
                        id={item.code}
                        name={item.code}
                        value={item.answer}
                        onChange={(e) => {
                            changeTextSetFieldValue(e.target.value, questionList, setQuestionList, index, setFieldValue, setUserCountError);
                        }}
                    />

                    <div className="error">
                        <ErrorMessage name={item.code} />
                    </div>
                </div>
            </Card>
        </>
    )
}
